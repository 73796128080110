import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  class: "MapDropDownMenu",
  ref: "element"
}
const _hoisted_2 = ["onClick", "data-idxItem", "onMouseenter"]
const _hoisted_3 = { class: "MapDropDownMenu_menu-item-alias" }
const _hoisted_4 = { class: "MapDropDownMenu_menu-item-right" }
const _hoisted_5 = { class: "MapDropDownMenu_menu-item-right-icon" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["data-idx", "onDragstart"]
const _hoisted_8 = ["onMouseenter"]
const _hoisted_9 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_Check = _resolveComponent("Check")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_ArrowRight = _resolveComponent("ArrowRight")
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_UiIcon = _resolveComponent("UiIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["MapDropDownMenu_menu", { active: true }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentMenu, (item, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (!item.parentId)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["MapDropDownMenu_menu-item", { selected: item.active ,draggable: _ctx.draggingItemOrder === idx, hover:_ctx.hoverItemId === item.id }]),
                onClick: $event => (_ctx.clickItem(item)),
                "data-idxItem": idx,
                onMouseenter: $event => {_ctx.overMove($event,idx); _ctx.hoveredItem(item.id)},
                onMouseleave: _cache[3] || (_cache[3] = $event => (_ctx.hoverItemId = 0))
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (item.active)
                      ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Check)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.subMenu(item.id).length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          _createVNode(_component_ArrowRight)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.dragOn)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "MapDropDownMenu_menu-item-right-drag",
                        draggable: true,
                        "data-idx": idx,
                        onDragstart: $event => (_ctx.dragStart(idx)),
                        onDragend: _cache[0] || (_cache[0] = (...args) => (_ctx.dragEnd && _ctx.dragEnd(...args))),
                        onDragover: _cache[1] || (_cache[1] = (...args) => (_ctx.dragOver && _ctx.dragOver(...args)))
                      }, [
                        _createVNode(_component_ui_icon, { name: "mdiDragVertical" })
                      ], 40, _hoisted_7))
                    : _createCommentVNode("", true)
                ]),
                (_openBlock(), _createBlock(_Teleport, { to: _ctx.teleportToEl }, [
                  (_ctx.subMenu(item.id).length!==0 && _ctx.activeMenu)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["MapDropDownMenu_submenu", {hover: _ctx.hoverItemId === item.id}]),
                        onMouseenter: $event => {_ctx.enterPopup();_ctx.hoveredItem(item.id)},
                        onMouseleave: _cache[2] || (_cache[2] = $event => (_ctx.leavePopup())),
                        style: _normalizeStyle({left: 250 + _ctx.position + 'px',top:_ctx.submenuCordY+'px'})
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subMenu(item.id), (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(["MapDropDownMenu_submenu-item", { selected: item.active }]),
                            onClick: $event => (_ctx.clickSubItem(item))
                          }, [
                            _createTextVNode(_toDisplayString(item.title), 1),
                            (item.postfixIcon)
                              ? (_openBlock(), _createBlock(_component_UiIcon, {
                                  key: 0,
                                  name: item.postfixIcon,
                                  "data-name": item.postfixIcon,
                                  size: 16
                                }, null, 8, ["name", "data-name"]))
                              : _createCommentVNode("", true)
                          ], 10, _hoisted_9))
                        }), 256))
                      ], 46, _hoisted_8))
                    : _createCommentVNode("", true)
                ], 8, ["to"]))
              ], 42, _hoisted_2))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ])
  ], 512))
}