import { Bus } from '@/lib/classes/Bus';
import {
  LngLat, LngLatBounds,
} from 'mapbox-gl';

enum Events {
  SHOW_INDEX_ON_MAP,
  MAP_ROTATE,
  MAP_MOVE,
  MAP_ZOOM,
  ZONE_CHANGED,
  ZONE_FOCUS_CHANGE,
  PREVIEW_FIT_BOUNDS,
  PREVIEW_CLICK_POI,
  CANDIDATE_ACTIVATE,
  CANDIDATE_MODE_CHANGE,
  CANDIDATE_RESET,
  CREATE_TASK_MAP,
  EDIT_SAVE,
  EDIT_SAVED,
  GENERALIZATION,

}

class BaseMapEvents extends Bus {
  onMapRotate(callback: (bearing: number, from: string) => void, once = false) {
    this.registerListener(Events.MAP_ROTATE, callback, once);
  }

  emitMapRotate(bearing: number, from: string) {
    this.emitListeners(Events.MAP_ROTATE, bearing, from);
  }

  onMapMove(callback: (center: LngLat, from: string) => void, once = false) {
    this.registerListener(Events.MAP_MOVE, callback, once);
  }

  emitMapMove(center: LngLat, from: string) {
    this.emitListeners(Events.MAP_MOVE, center, from);
  }

  onMapZoom(callback: (zoom: number, from: string) => void, once = false) {
    this.registerListener(Events.MAP_ZOOM, callback, once);
  }

  emitMapZoom(zoom: number, from: string) {
    this.emitListeners(Events.MAP_ZOOM, zoom, from);
  }

  onZoneChanged(callback: () => void, once = false) {
    this.registerListener(Events.ZONE_CHANGED, callback, once);
  }

  emitZoneChanged() {
    this.emitListeners(Events.ZONE_CHANGED);
  }

  onFocusChange(callback: () => void, once = false) {
    this.registerListener(Events.ZONE_FOCUS_CHANGE, callback, once);
  }

  emitFocusChange() {
    this.emitListeners(Events.ZONE_FOCUS_CHANGE);
  }

  emitPreviewFitBounds(bounds: LngLatBounds) {
    this.emitListeners(Events.PREVIEW_FIT_BOUNDS, bounds);
  }

  onActivateCandidate(callback: (fieldId: number, candidateId: number) => void, once = false) {
    this.registerListener(Events.CANDIDATE_ACTIVATE, callback, once);
  }

  emitActivateCandidate(fieldId: number, candidateId: number) {
    this.emitListeners(Events.CANDIDATE_ACTIVATE, fieldId, candidateId);
  }

  onCreateTaskMap(callback: (fieldId: number) => void, once = false) {
    this.registerListener(Events.CREATE_TASK_MAP, callback, once);
  }

  emitCreateTaskMap(fieldId: number) {
    this.emitListeners(Events.CREATE_TASK_MAP, fieldId);
  }

  onEditSave(callback: () => void, once = false) {
    this.registerListener(Events.EDIT_SAVE, callback, once);
  }

  emitEditSave() { this.emitListeners(Events.EDIT_SAVE); }

  onEditSaved(callback: () => void, once = false) {
    this.registerListener(Events.EDIT_SAVED, callback, once);
  }

  emitEditSaved() {
    this.emitListeners(Events.EDIT_SAVED);
  }

  onGeneralization(callback: () => void, once = false) {
    this.registerListener(Events.GENERALIZATION, callback, once);
  }

  emitGeneralization() {
    this.emitListeners(Events.GENERALIZATION);
  }

  onCandidateModeChange(callback: () => void, once = false) {
    this.registerListener(Events.CANDIDATE_MODE_CHANGE, callback, once);
  }

  emitCandidateModeChange() {
    this.emitListeners(Events.CANDIDATE_MODE_CHANGE);
  }

  onCandidateReset(callback: (fieldId: number) => void, once = false) {
    this.registerListener(Events.CANDIDATE_RESET, callback, once);
  }

  emitCandidateReset(fieldId: number) {
    this.emitListeners(Events.CANDIDATE_RESET, fieldId);
  }
}

export default new BaseMapEvents();
