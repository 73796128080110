export const FIELDS_LAYER_ID = 'fields-layer';
export const FIELDS_CONTOUR_ID = 'fields-contour';
export const FIELDS_LABEL_ID = 'fields-label';
export const POI_SOURCE = 'poi-source';
export const POI_LAYER_ID = 'pois-layer';
export const POI_LABEL_LAYER_ID = 'pois-label-layer';

export const FIELDS_SOURCE = 'fields-source';
export const FIELDS_LABEL_SOURCE = 'fields-label-source';
export const POI_LABEL_SOURCE = 'pois-label-source';

export const SATELLITE_RGB_SOURCE = 'satellite-rgb-source';
export const SATELLITE_RGB_LAYER = 'satellite-rgb-layer';
export const SATELLITE_INDEX_SOURCE = 'satellite-index-source';
export const SATELLITE_INDEX_LAYER = 'satellite-index-layer';

export const COMPARE_MAP_INDEX_LAYER = 'compare-map-index-layer';
export const COMPARE_MAP_INDEX_SOURCE = 'compare-map-index-source';

export const COMPARE_MAP_MONITORING_INDEX_LAYER = 'compare-map-monitoring-index-layer';
export const COMPARE_MAP_MONITORING_INDEX_SOURCE = 'compare-map-monitoring-index-source';

export const COMPARE_MAP_RGB_LAYER = 'compare-map-rgb-layer';
export const COMPARE_MAP_RGB_SOURCE = 'compare-map-rgb-source';

export const COMPARE_MAP_FIELD_SOURCE = 'compare-map-field-source';
export const COMPARE_MAP_FIELD_LAYER = 'compare-map-field-layer';
export const COMPARE_MAP_FIELD_CONTOUR_LAYER = 'compare-map-field-countor-layer';

export const CROSSHAIR_SOURCE = 'crosshair-source';
export const CROSSHAIR_LAYER = 'crosshair-layer';

export const VECTOR_LAYER = 'vector-layer';
export const VECTOR_SOURCE = 'vector-source';

export const MAP_DRAWER_EDGES_SOURCE = 'map-drawer-edges-source';
export const MAP_DRAWER_EDGES_LAYER = 'map-drawer-edges-layer';
export const MAP_DRAWER_BACKGROUND_LAYER = 'map-drawer-background-layer';
export const MAP_DRAWER_BACKGROUND_SOURCE = 'map-drawer-background-source';
export const MAP_DRAWER_LABEL_LAYER = 'map-drawer-label-layer';
export const MAP_DRAWER_LABEL_SOURCE = 'map-drawer-label-source';
export const MAP_DRAWER_LINES_SOURCE = 'map-drawer-lines-source';
export const MAP_DRAWER_LINES_LAYER = 'map-drawer-lines-layer';

export const TOOL_RULER_SOURCE = 'tool-ruler-source';
export const TOOL_RULER_LAYER_POINTS = 'tool-ruler-layer-points';
export const TOOL_RULER_LAYER_LINES = 'tool-ruler-layer-lines';

export const MAP_CROP_RATE_LAYER = 'map-crop-rate-layer';
export const MAP_CROP_RATE_SOURCE = 'map-crop-rate-source';

export const EXPERIMENT_SOURCE = 'experiment-source';
export const EXPERIMENT_SOURCE_ADD = 'experiment-source-add';

export const EXPERIMENT_POLYGON = 'experiment-polygon';
export const EXPERIMENT_LINE = 'experiment-line';
export const EXPERIMENT_POINT_ADD = 'experiment-points-add';
export const EXPERIMENT_POINT = 'experiment-points';

export const EXPERIMENT_POINT_VISIBLE = 'experiment-points-visible';
export const EXPERIMENT_POINT_ADD_VISIBLE = 'experiment-points-add-visible';
export const EXPERIMENT_LINE_VISIBLE = 'experiment-line-visible';

export const EXPERIMENT_POLYGON_ADD = 'experiment-polygon-add';
export const EXPERIMENT_LINE_ADD = 'experiment-line-add';
