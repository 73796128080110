export enum MapLayerTypeEnum {
  CURSOR,
  FIELD_LOADING,
  DRAWER,
  AREA,
  RULER,
  POI,
  FIELDS,
  TASK_MAP_BASE,
  TASK_MAP_WORK,
  TASK_MAP_HARVEST,
  TASK_MAP_FACT,
  TASK_MAP_COLLECTION,
  TASK_MAP_WORK_KONTUR,
  TASK_MAP_CONTOUR,
  TASK_MAP_EXPERIMENT,
  CANVAS,
  AVERAGE_INDEX,
  MONITORING_INDEX,
  VECTOR_FILE,
  NIR_FILE,
  RASTER_FILE,
  CROP_RATE,
  HISTORY_INDEX,
  MASK_FILE,
  ROSREESTR,
  STRUCT_VIEW,
  UNFIED_VECTOR
}
