<template lang="pug" src="./SatelliteContent.pug"/>
<style lang="scss" src="./SatelliteContent.scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, onUnmounted, ref,
} from 'vue';
import {
  CircleClose,
  Download,
  Refresh,
  Search,
  Select,
  SemiSelect,
  VideoPlay,
  Warning,
} from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';
import LeftPanel from '@/components/shared/LeftPanel/LeftPanel.vue';
import SatellitesService from '@/modules/satellites/SatellitesService';
import { useSatellites } from '@/composables/useSatellites';
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import FieldsSelector from '@/components/shared/FieldsSelector/FieldsSelector.vue';
import { SatelliteCounterStatusEnums } from '@/constants/enums/SatelliteCounterStatusEnums';
import { useMapLayout } from '@/composables/useMapLayout';
import { formatSecondsToTime, timerLeft, timerPercentage } from '@/utils/timerFunctions';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import type { FieldModel } from '@/models/field/FieldModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';

export default defineComponent({
  name: 'SatelliteContent',
  props: {},
  components: {
    LeftPanel,
    FieldView,
    FieldsSelector,
    Warning,
  },
  setup() {
    const {
      showingBlock,
      isActive,
    } = useSatellites();

    const {
      mapModel,
      selectedFields,
      activeField,
      cleanSelectedFields,
      fields,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const selectMode = ref(0);
    const loading = ref(true);
    const searchField = ref('');

    const searchRow = ref('');

    const isInProgress = computed(() => (field: FieldModel | undefined) => (field?.counter?.status?.progress || 0) > 0 && (field?.counter?.status?.progress || 0) < 20);

    const computedFields = computed(() => fields.value.filter((fieldModel) => !!fieldModel?.counter));

    const computedDisabledFields = computed(() => computedFields.value.filter((field) => {
      if (selectMode.value === 1) {
        if (field?.counter?.known_amount && field?.counter?.completed_amount === field?.counter?.known_amount) {
          return true;
        }
        if (isInProgress.value(field as FieldModel)) {
          return true;
        }
      } else if (selectMode.value === 2) {
        return field?.counter?.status.progress !== 20;
      }

      return false;
    }));

    const counterClass = computed(() => (field: FieldModel | undefined) => ({
      [`--${(field && field.counter?.amount_color) || 'red'}`]: true,
    }));

    let fetchTaskInterval: ReturnType<typeof setInterval>;

    onMounted(async () => {
      loading.value = true;
      // await SatellitesService.fetchCounter();
      isActive.value = true;
      loading.value = false;
      await useMapLayout().showBlock('ToolAutoIndexBlock');
    });

    onUnmounted(() => {
      isActive.value = false;
      clearInterval(fetchTaskInterval);
      useMapLayout().hideBlock('SatelliteEstimationBlock');
      useMapLayout().hideBlock('SatelliteProgressBlock');
      useMapLayout().hideBlock('SatelliteSearchBlock');
      useMapLayout().hideBlock('ToolAutoIndexBlock');
    });

    const searchMode = () => {
      if (selectMode.value !== 1) {
        cleanSelectedFields();
        selectMode.value = 1;
      } else {
        const ids = selectedFields.value.map((f) => f.id);
        SatellitesService.startDownloadImages(ids);
        cleanSelectedFields();
        selectMode.value = 0;
      }
    };

    const indexesMode = () => {
      if (selectMode.value !== 2) {
        cleanSelectedFields();
        selectMode.value = 2;
      } else {
        const ids = selectedFields.value.map((f) => f.id);
        SatellitesService.calculateIndexes(ids);
        cleanSelectedFields();
        selectMode.value = 0;
        ElNotification(
          {
            message: 'Запрос на формирования MSF отправлен. По завершении формирования MSF вы увидите сообщение об успешном формировании MSF.',
            type: 'success',
            position: 'bottom-right',
          },
        );
      }
    };

    const unsetMode = () => {
      selectMode.value = 0;
      cleanSelectedFields();
    };

    const unselectFeature = () => {
      showingBlock.value = undefined;
    };

    FieldsEvents.onChangeActiveField((fieldId: number | undefined) => {
      mapModel.value?.removeLayer(MapLayerTypeEnum.NIR_FILE);

      if (fieldId) {
        if (activeField.value?.counter) {
          switch (activeField.value?.counter?.status.progress) {
          case SatelliteCounterStatusEnums.QUEUE:
          case SatelliteCounterStatusEnums.PROGRESS:
          case SatelliteCounterStatusEnums.ERROR:
            showingBlock.value = 'progress';
            break;
          case SatelliteCounterStatusEnums.DONE:
            showingBlock.value = 'estimation';
            break;
          default: showingBlock.value = 'search';
          }
        } else {
          unselectFeature();
        }
      } else {
        unselectFeature();
      }
    });

    onUnmounted(() => {
      unselectFeature();
    });

    const removeFields = () => {
      cleanSelectedFields();
    };

    const selectAllFields = () => {
      selectAllFields();
    };

    const computedProgressTitle = computed(() => (field: FieldModel) => {
      if (field.counter?.status.progress === 1) {
        return 'Ожидание в очереди';
      } if (field.counter?.status.progress === 10) {
        if (field.counter?.status.search === 10 || field.counter?.status.search === 1) return 'Поиск снимков';
        if (field.counter?.status.download === 10 || field.counter?.status.download === 1) return 'Загрузка снимков';
        if (field.counter?.status.rgb === 10 || field.counter?.status.rgb === 1) return 'Обработка снимков';
        if (field.counter?.status.estimation === 10 || field.counter?.status.estimation === 1) return 'Анализ снимков';
      }

      return 'Обработка задачи';
    });

    return {
      fields,
      selectedFields,
      selectMode,
      loading,
      Download,
      Refresh,
      CircleClose,
      VideoPlay,
      Search,
      Select,
      SemiSelect,
      computedFields,
      computedDisabledFields,
      searchField,
      counterClass,
      isInProgress,
      removeFields,
      selectAllFields,
      searchRow,
      searchMode,
      indexesMode,
      unsetMode,
      timerPercentage,
      timerLeft,
      formatSecondsToTime,
      computedProgressTitle,
      SatelliteCounterStatusEnums,
    };
  },
});
</script>
