import { SimpleType } from '@/constants/types/BasedTypes';
import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { ScoutingPoiDto } from '@/services/api/dto/scouting/ScoutingPoiDto';
import { ScoutingPoigroupDto } from '@/services/api/dto/scouting/ScoutingPoigroupDto';
import { ScoutingPoiPhotoDto } from '@/services/api/dto/scouting/ScoutingPoiPhotoDto';

export const endpoints = {
  addPoi: (): string => '/api/v1/scouting/poi/',
  getUnitPois: (): string => '/api/v1/scouting/poi/',
  getPoigroup: (): string => '/api/v1/scouting/poigroup/',
  getPoiPhotos: (poiId: number): string => `/api/v1/scouting/poi/${poiId}/photos/`,
  putPoigroupEdit: (poigroupId: number): string => `/api/v1/scouting/poigroup/${poigroupId}/`,
  getPoi: (poiId: number) => `/api/v1/scouting/poi/${poiId}/`,
  putPoiEdit: (poiId: number): string => `/api/v1/scouting/poi/${poiId}/`,
  deletePoi: (poiId: number): string => `/api/v1/scouting/poi/${poiId}/`,
  addPoigroup: (): string => '/api/v1/scouting/poigroup/',
  deletePoigroup: (poigroupId: number): string => `/api/v1/scouting/poigroup/${poigroupId}/`,
  deletePhoto: (id: number) => `/api/v1/scouting/photo/${id}/`,
  patchPoiGroupParam: (id: number) => `/api/v1/scouting/poigroup/${id}/edit-params/`,
  patchPoiParam: (id: number) => `/api/v1/scouting/poi/${id}/edit-params/`,
};

export class ApiScouting extends ApiHandler {
  public async addPoi(data: FormData): Promise<ApiResponse<ScoutingPoiDto>> {
    return await this.request({ auth: true }).post<ScoutingPoiDto>(endpoints.addPoi(), data);
  }

  public async getUnitPois(page = 0): Promise<ApiResponse<ScoutingPoiDto[]>> {
    const unit = Number(window.localStorage.getItem('structId') || 0).toString();
    return await this.request({ auth: true, pagination: 9000, freeze: false }).get<ScoutingPoiDto[]>(endpoints.getUnitPois(), {
      farmunit: unit,
    }, page);
  }

  public async getUnitPoiGroups(): Promise<ApiResponse<ScoutingPoigroupDto[]>> {
    const unit = Number(window.localStorage.getItem('structId') || 0).toString();
    return await this.request({ auth: true }).get<ScoutingPoigroupDto[]>(endpoints.getPoigroup(), { farmunit: unit });
  }

  public async getPois(data: { farmunit: string, poigroup: string }, page = 0): Promise<ApiResponse<ScoutingPoiDto[]>> {
    return await this.request({ auth: true, pagination: 3000, freeze: false }).get<ScoutingPoiDto[]>(endpoints.getUnitPois(), data, page);
  }

  public async getPoi(poiId: number): Promise<ApiResponse<ScoutingPoiDto>> {
    return await
    this.request({ auth: true }).get<ScoutingPoiDto>(endpoints.getPoi(poiId));
  }

  public async getFieldPois(data: { farmunit: string, field: number }, page = 0): Promise<ApiResponse<ScoutingPoiDto[]>> {
    return await this.request({ auth: true, pagination: 3000, freeze: false }).get<ScoutingPoiDto[]>(endpoints.getUnitPois(), data, page);
  }

  public async getPoigroup(data: { farmunit: string | undefined }): Promise<ApiResponse<ScoutingPoigroupDto[]>> {
    if (!data.farmunit) {
      data.farmunit = Number(window.localStorage.getItem('structId') || 0).toString();
    }
    return await this.request({ auth: true }).get<ScoutingPoigroupDto[]>(endpoints.getPoigroup(), data);
  }

  public async getPoiPhotos(poiId: number): Promise<ApiResponse<ScoutingPoiPhotoDto[]>> {
    return await this.request({ auth: true }).get<ScoutingPoiPhotoDto[]>(endpoints.getPoiPhotos(poiId));
  }

  public async putPoigroupEdit(poigroupId: number, data: FormData): Promise<ApiResponse<ScoutingPoigroupDto>> {
    return await this.request({ auth: true }).put<ScoutingPoigroupDto>(endpoints.putPoigroupEdit(poigroupId), data);
  }

  public async putPoiEdit(poiId: number, data: FormData, ignoreLog = false): Promise<ApiResponse<ScoutingPoiDto>> {
    return await this.request({ auth: true, ignoreLog }).put<ScoutingPoiDto>(endpoints.putPoiEdit(poiId), data);
  }

  public async deletePoi(poiId: number): Promise<ApiResponse<void>> {
    return await
    this.request({ auth: true }).delete<void>(endpoints.deletePoi(poiId));
  }

  public async deletePhoto(id: number): Promise<ApiResponse<void>> {
    return await
    this.request({ auth: true }).delete<void>(endpoints.deletePhoto(id));
  }

  public async addPoigroup(data: FormData): Promise<ApiResponse<ScoutingPoigroupDto>> {
    return await this.request({ auth: true }).post<ScoutingPoigroupDto>(endpoints.addPoigroup(), data);
  }

  public async deletePoigroup(poigroupId: number): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).delete<void>(endpoints.deletePoigroup(poigroupId));
  }

  // eslint-disable-next-line camelcase
  public async patchPoiParamDeleteColumn(id: number, delete_column: string[]): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiParam(id), { delete_column });
  }

  // eslint-disable-next-line camelcase
  public async patchPoiParamRenameColumn(id: number, rename_column: {
      'old': string,
      'new': string,
      'default': SimpleType
    }[]): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiParam(id), { rename_column });
  }

  // eslint-disable-next-line camelcase
  public async patchPoiParamEditColumn(id: number, edit_column?: {
      'new': string,
      'default': SimpleType
    } []): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiParam(id), { edit_column });
  }

  // eslint-disable-next-line camelcase
  public async patchPoiParamAddColumn(id: number, add_column: {
      'new': string,
      'default': SimpleType
    }[]): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiParam(id), { add_column });
  }

  // eslint-disable-next-line camelcase
  public async patchPoiGroupParamDeleteColumn(id: number, delete_column: string[]): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiGroupParam(id), { delete_column });
  }

  // eslint-disable-next-line camelcase
  public async patchPoiGroupParamRenameColumn(id: number, rename_column: {
    'old': string,
    'new': string,
    'default'?: SimpleType
  }[]): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiGroupParam(id), { rename_column });
  }

  // eslint-disable-next-line camelcase
  public async patchPoiGroupParamEditColumn(id: number, edit_column?: {
    'new': string,
    'default': SimpleType
  } []): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiGroupParam(id), { edit_column });
  }

  // eslint-disable-next-line camelcase
  public async patchPoiGroupParamAddColumn(id: number, add_column: {
    'new': string,
    'default': SimpleType
  }[]): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).patch<void>(endpoints.patchPoiGroupParam(id), { add_column });
  }
}
