/**
 * Используем только как parent класс для индексов
 */
import Coordinates from '@/constants/types/mapbox/Coordinates';
import { getCorrectCoordinates } from '@/lib/convertors/getCorrectCoordinates';
import { Model } from '@/models/Model';
import { AverageIndexDto } from '@/services/api/dto/monitoring/AverageIndexDto';

export class FieldAverageIndexModel extends Model {
  get imgContrastFile(): string | undefined {
    return this._imgContrastFile;
  }

  get imgColoredFile(): string | undefined {
    return this._imgColoredFile;
  }

  get avg(): number {
    return this._avg;
  }

  get estimationAuto(): 0 | 1 {
    return this._estimationAuto;
  }

  get estimationUser(): -1 | 0 | 1 {
    return this._estimationUser;
  }

  set estimationUser(value: -1 | 0 | 1) {
    this._estimationUser = value;
  }

  get dto(): AverageIndexDto {
    return this._dto;
  }

  get dataDate(): Date | undefined {
    return this._dataDate;
  }

  get geomCoordinates(): Coordinates | undefined {
    return this._geomCoordinates;
  }

  get kind(): number {
    return this._kind;
  }

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }

  protected _id = 0;

  protected _name = '';

  protected _kind = 0;

  protected _geomCoordinates: Coordinates | undefined;

  /** webp индекса в раскраске */
  private _imgColoredFile: string | undefined;

  /** webp индекса в контрастной раскраске */
  private _imgContrastFile: string | undefined;

  private _dataDate: Date | undefined

  private _dto: AverageIndexDto;

  private _estimationUser: -1 | 0 | 1

  private _estimationAuto: 0 | 1

  private _avg: number

  public showContrast = false;

  constructor(dto: AverageIndexDto) {
    super();
    this._id = Number(dto.id);
    this._name = dto.index_name;
    this._estimationUser = dto.estimation.estimation_user;
    this._estimationAuto = dto.estimation.estimation_auto;
    this._avg = Number(dto.avg.toFixed(2));
    this._dto = dto;
    if (dto.data_date) {
      this._dataDate = new Date(dto.data_date);
    }
    this._geomCoordinates = getCorrectCoordinates(dto.geom_envelope.coordinates[0]);

    this._imgColoredFile = dto.file || undefined;

    this._imgContrastFile = dto.file_contrast || undefined;
  }
}
