import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import {
  taskMapContourLabelsLayerDef,
  taskMapContourLayerDef,
  taskMapContourLinesLayerDef,
} from '@/models/map/Layers/defs/TaskMapContourDef';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MonitoringTaskMapContourModel } from '@/models/monitoring/MonitoringTaskMapContourModel';
import { FeatureIdentifier, GeoJSONSource } from 'mapbox-gl';

export class MapLayerTaskMapContourModel extends MapLayerModel implements IMapLayerModel {
  get opacity(): number {
    return this._opacity;
  }

  set opacity(value: number) {
    this._opacity = value;
    this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-opacity', value / 100);
  }

  readonly data: MonitoringTaskMapContourModel;

  private _opacity = 100;

  private hoveredPolygonId: number | undefined;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'task-map-contour', input.uuid);
    this.data = input as MonitoringTaskMapContourModel;
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this.data.featuresCollection(),
    });
    this._mapModel?.map?.addLayer(taskMapContourLayerDef(this.sourceId, this.layerId));
    this._mapModel?.map?.addLayer(taskMapContourLinesLayerDef(this.sourceId, `contour-${this.layerId}`));
    this._mapModel?.map?.addLayer(taskMapContourLabelsLayerDef(this.sourceId, `labels-${this.layerId}`));

    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);

    this._mapModel?.map?.on('mousemove', this.layerId, (e) => {
      if ((e.features || []).length > 0) {
        if (this.hoveredPolygonId) {
          this._mapModel?.map?.setFeatureState(
            { source: this.sourceId, id: this.hoveredPolygonId },
            { hover: false },
          );
        }
        // @ts-ignore
        this.hoveredPolygonId = Number(e.features[0].id);
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: this.hoveredPolygonId } as FeatureIdentifier,
          { hover: true },
        );
      }
    });

    this._mapModel?.map?.on('mouseleave', this.layerId, () => {
      if (this.hoveredPolygonId !== null) {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: this.hoveredPolygonId } as FeatureIdentifier,
          { hover: false },
        );
      }
      this.hoveredPolygonId = undefined;
    });
    this.sourceIds.push(this.sourceId);
    this.layerIds.push(`contour-${this.layerId}`, this.layerId, `labels-${this.layerId}`);
    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.TASK_MAP);
    this._mapModel?.map?.moveLayer(`contour-${this.layerId}`, MapAnchorEnum.TASK_MAP);
    this._mapModel?.map?.moveLayer(`labels-${this.layerId}`, MapAnchorEnum.TASK_MAP);
  }

  setSettings = (settings: Record<string, any>): void => {
    if (this.data.taskMap?.geojson) {
      this.data.taskMap.geojson.features.forEach((f) => {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: Number(f.properties?.id) },
          {
            inactive: settings.fill === 0,
            active: settings.fill === 2,
          },
        );
      });
    }

    this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-opacity', ['case',
      ['boolean', ['feature-state', 'inactive'], false], 0,
      ['boolean', ['feature-state', 'active'], false], settings.opacity / 100,
      ['boolean', ['feature-state', 'hover'], false], settings.opacity / 100,
      0,
    ]);
    this.update();
  }

  update(): void {
    const source = this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource;
    if (source) {
      source.setData(this.data.featuresCollection());
    }
  }
}
