import { useMapContainers } from '@/composables/useMapContainers';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { TaskImageModel } from '@/models/taskMap/TaskImageModel';
import ApiService from '@/services/api/ApiService';
import LoggerService from '@/services/logger/LoggerService';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';
import { TaskCandidateDto } from '@/services/api/dto/taskMap/TaskCandidateDto';

/**
 * Модель кандидата индекса на создание шаблона карты.
 * На беке один кандидат может использоваться на несколько полей.
 * Здесь для каждого боля создается отдельный кандидат.
 * Уникальность создается связкой id + fieldId;
 */
export class TaskCandidateModel {
  get isEdited(): boolean {
    return this._isEdited;
  }

  set isEdited(value: boolean) {
    this._isEdited = value;
  }

  get taskImage(): TaskImageModel | undefined {
    return this._taskImage;
  }

  get preview(): string | undefined {
    return this._preview;
  }

  set isActive(value: boolean) {
    this._isActive = value;
    if (value) {
      BaseMapEvents.emitActivateCandidate(this.fieldId, this.id);
    }
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get isCreated(): boolean {
    return this._isCreated;
  }

  set isCreated(value: boolean) {
    this._isCreated = value;
    if (value) {
      BaseMapEvents.emitActivateCandidate(this.fieldId, this.id);
    }
  }

  get fieldId(): number {
    return this._fieldId;
  }

  get updateDate(): Date {
    return this._updateDate;
  }

  get createDate(): Date {
    return this._createDate;
  }

  get farmunit(): number {
    return this._farmunit;
  }

  get name(): string {
    return this._name;
  }

  /** Не уникальный. Одинаковый id может быть среди кандидатов других полей. */
  get id(): number {
    return this._id;
  }

  get inputName(): string {
    return this._inputName;
  }

  set inputName(value: string) {
    this._inputName = value;
  }

  private readonly _id: number;

  private readonly _name: string;

  private readonly _farmunit: number;

  private readonly _createDate: Date;

  private readonly _updateDate: Date;

  private readonly _preview: string | undefined;

  private readonly _fieldId: number;

  // Флаг активного кандидата для поля к которому принадлежит кандидат
  private _isActive = false;

  // Флаг скачивания карты задания
  private _isFetching = false;

  // Флаг что карта задания по этому полю уже создано
  private _isCreated = false;

  // Флаг что taskImage был отредактирован в редакторе
  private _isEdited = false;

  private _taskImage: TaskImageModel | undefined = undefined;

  private _inputName = '';

  constructor(dto: TaskCandidateDto) {
    this._id = dto.id;
    this._name = dto.name;
    this._farmunit = dto.farmunit;
    this._preview = dto.preview;
    this._createDate = new Date(dto.create_date);
    this._updateDate = new Date(dto.update_date);
    this._fieldId = useMapContainers(MapContainerEnum.MAIN_MAP).activeField.value?.id || 0;
    this.log('Task candidate model created.', [this]);
    BaseMapEvents.onActivateCandidate((fieldId, candidateId) => {
      if (fieldId === this._fieldId && candidateId !== this._id) {
        this._isActive = false;
      }
    });
  }

  private log(message: string, args: any[] = []) {
    LoggerService.from(LoggerGroupsEnum.CREATE_BASE_MAP).group(`Candidate ${this._id} for field ${this._fieldId}. ${message}`, ...args);
  }

  async reset() {
    this.log('Reset candidate.');
    this._taskImage = undefined;
    this._isEdited = false;
    this._isActive = false;
    this._isCreated = false;
    this._isFetching = false;
    this._inputName = '';
    BaseMapEvents.emitCandidateReset(this.fieldId);
  }

  async fetchTaskBase() {
    this.log('Fetch task base called.');
    if (!this._taskImage) {
      this.log('Fetch task base begin.');
      this._isFetching = true;
      const { data } = await ApiService.taskMap.getTaskBase(this.farmunit, this.id, this.fieldId);
      this.log('Task base received.', [data]);
      this._taskImage = new TaskImageModel(data);
      this.log('Task image model created.', [this._taskImage]);
      this._isFetching = false;
      if (this._taskImage) {
        BaseMapEvents.emitPreviewFitBounds(this._taskImage.bounds);
      }
    }
  }
}
