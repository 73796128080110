<template lang="pug" src="./FieldsPanel.pug"/>
<style lang="scss" src="./FieldsPanel.scss"/>

<script lang="ts">
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { FieldModel } from '@/models/field/FieldModel';
import { Search, Select } from '@element-plus/icons-vue';
import {
  computed, defineComponent, PropType, ref, Slots, useSlots,
} from 'vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import FieldsEvents from '@/modules/fields/FieldsEvents';

export default defineComponent({
  name: 'MapFieldsSelector',
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
  },
  components: {
    UiIcon,
    SidePanelContent,
    FieldView,
    Select,
  },
  emits: ['close', 'selected', 'select', 'notfound'],
  setup(props) {
    const slots = useSlots();

    const {
      fields,
      activeField,
      selectedFields,
      hasSelectedField,
      isFieldActive,
      isDisabledField,
      hoverField,
      selectState,
      setHoverField,
      fitField,
      informationMode,
      maxSelectFields,
    } = useMapContainers(props.mapContainer);

    const top = ref(0);

    const searchRow = ref('');

    const fieldsListRef = ref();

    const topIndex = ref(0);

    const rowsCount = 50;

    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: Slots[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type?.toString() !== 'Symbol(Comment)').length > 0;
    };

    const hasNotice = computed(() => hasSlot('notice'));

    const hasIcon = computed(() => hasSlot('icon'));

    const onMouseEnter = (fieldId: number) => {
      setHoverField(fieldId);
    };

    const onMouseLeave = () => { setHoverField(undefined); };

    const computedShortFields = computed<{id: number, name: string, sq: number}[]>(() => fields.value.map((f: FieldModel) => ({ id: f.id, name: f.name, sq: f.sq })));

    const computedFields = computed(() => (searchRow.value.length === 0 ? computedShortFields.value : computedShortFields.value.filter((f) => f.name.indexOf(searchRow.value) !== -1)));

    const slicedFields = computed(() => computedFields.value.slice(topIndex.value, topIndex.value + rowsCount));

    const calculateTopIndex = () => {
      topIndex.value = Math.min(
        Math.floor((fieldsListRef.value as HTMLElement).scrollTop / 40),
        (computedFields.value.length - rowsCount - 1),
      );
    };

    const scrollTop = () => { (fieldsListRef.value as HTMLElement).scrollTop = 0; };

    const emitSelectField = (fieldId: number) => {
      FieldsEvents.emitClick(fieldId, props.mapContainer);
      if (selectState.value === 'single') {
        const field = fields.value.find((a) => a.id === fieldId);
        fitField(field, false, 'main');
      }
    };

    const featureClassList = computed(() => (field: FieldModel) => ({
      selected: hasSelectedField(field?.id),
      active: isFieldActive(field?.id),
      selectActive: selectedFields.value.some((a) => a.id === field.id),
      hover: field.id === hoverField.value?.id,
    }));

    return {
      fieldsListRef,
      searchRow,
      activeField,
      selectedFields,
      hasSelectedField,
      featureClassList,
      onMouseEnter,
      onMouseLeave,
      hasNotice,
      hasIcon,
      top,
      Search,
      hoverField,
      emitSelectField,
      selectState,
      isDisabledField,
      fields,
      calculateTopIndex,
      topIndex,
      rowsCount,
      computedFields,
      slicedFields,
      scrollTop,
      informationMode,
      maxSelectFields,
    };
  },
});
</script>
