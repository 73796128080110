/**
 * @template EventName
 */
import { EventNameType } from '@/constants/types/EventBusTypes';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { Bus } from '@/lib/classes/Bus';

class EventBus extends Bus {
  constructor() {
    super();

    window.document.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.$emit(EventsEnum.OnKeyPressedEscape);
      }
      if (e.key === 'Enter') {
        this.$emit(EventsEnum.OnKeyPressedEnter);
      }
      this.$emit(EventsEnum.OnKeyPressed, e);
    });
  }

  clearMapLayoutListeners() {
    const exclude = [
      'UserLogout',
      'UserLogin',
      'UserDoLogout',
      'SocketMessage',
      'TokenRenewRequire',
      'TokenRenewComplete',
      'MapboxElementReady',
    ];
    const arrEnum = Object.values(EventsEnum);
    const constructObj: {[key: string]: any} = {};

    for (let i = 0; i < arrEnum.length / 2; i++) {
      constructObj[arrEnum[i]] = arrEnum[i + (arrEnum.length / 2)];
    }

    const events = Object.keys(constructObj).filter((v) => Number.isNaN(Number(v)) && !exclude.includes(v));
    events.forEach((event) => {
      this.eventListeners.delete(constructObj[event]);
    });

    this.eventListeners.forEach((v, k) => {
      if (typeof k === 'string') {
        this.eventListeners.delete(k);
      }
    });
  }

  /**
   * @param {string} eventName
   * @param {Function} callback
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  $on(eventName: EventNameType, callback: Function) {
    this.registerListener(eventName, callback);
  }

  /**
   * @param {string | number} eventName
   * @param {Function} callback
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  $once(eventName: EventNameType, callback: Function) {
    const once = true;
    this.registerListener(eventName, callback, once);
  }

  /**
   * Removes all event listeners for the given event name or names.
   * When provided with a callback function, removes only event listeners matching the provided
   * function.
   * @param {EventNameType | EventNameType[]} eventNameOrNames
   * @param {Function} [callback]
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  $off(eventNameOrNames: EventNameType | EventNameType[], callback: Function | undefined = undefined) {
    this.unregisterListener(eventNameOrNames, callback);
  }

  /**
   * @param {string} eventName
   * @param {any} args
   */
  $emit(eventName: EventNameType, ...args: any[]) {
    this.emitListeners(eventName, ...args);
  }
}
export default new EventBus();
