import { TaskMapWorkDto } from '@/services/api/dto/taskMap/TaskMapWorkDto';
import { TaskMapFactDto } from '@/services/api/dto/taskMap/TaskMapFactDto';
import { TaskMapBaseDto } from '@/services/api/dto/taskMap/TaskMapBaseDto';
import { FeatureCollection } from 'geojson';
import { TaskMapHarvestDto } from '@/services/api/dto/taskMap/TaskMapHarvestDto';
import { Model } from '@/models/Model';
import { TaskMapMaterialDto } from '@/services/api/dto/taskMap/TaskMapMaterialDto';

export class FieldTaskMapModel extends Model {
  get area(): number {
    return this._area;
  }

  get material(): TaskMapMaterialDto[] | undefined {
    return this._material;
  }

  get geojson(): FeatureCollection | undefined {
    return this._geojson;
  }

  get field() {
    return this._field;
  }

  get selected(): boolean {
    return this._selected;
  }

  get preview(): string {
    return this._preview;
  }

  get createAt(): Date {
    return this._createAt;
  }

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }

  private _id: number;

  private _name: string;

  private _createAt: Date;

  private _preview: string;

  private _selected = false;

  private _field;

  private _geojson: FeatureCollection | undefined;

  private _material: TaskMapMaterialDto[]| undefined

  private _area: number

  constructor(dto: TaskMapWorkDto | TaskMapBaseDto | TaskMapFactDto | TaskMapHarvestDto) {
    super();
    this._id = dto.id;
    this._name = dto.name;
    this._createAt = new Date(dto.create_date);
    this._preview = dto.preview;
    this._field = dto.field;
    this._area = dto.area;
    if ((dto as TaskMapWorkDto).materials !== undefined) {
      this._material = (dto as TaskMapWorkDto).materials;
    }
  }

  setGeoJson(geojson: FeatureCollection): void {
    this._geojson = geojson;
  }

  setSelected(value: boolean): void {
    this._selected = value;
  }
}
