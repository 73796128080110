import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "BaseMapContent" }
const _hoisted_2 = { class: "BaseMapContent-select-img" }
const _hoisted_3 = { class: "BaseMapContent-form-create" }
const _hoisted_4 = {
  key: 0,
  class: "BaseMapContent-form-create"
}
const _hoisted_5 = {
  key: 0,
  class: "pl-8"
}
const _hoisted_6 = {
  key: 1,
  class: "pl-8"
}

export function render(_ctx, _cache) {
  const _component_CandidateSelector = _resolveComponent("CandidateSelector")
  const _component_BaseMapEdit = _resolveComponent("BaseMapEdit")
  const _component_BaseMapPreview = _resolveComponent("BaseMapPreview")
  const _component_BaseMapEditor = _resolveComponent("BaseMapEditor")
  const _component_el_button = _resolveComponent("el-button")
  const _component_Content = _resolveComponent("Content")

  return (_openBlock(), _createBlock(_component_Content, { title: "Шаблон карты" }, {
    footer: _withCtx(() => [
      (_ctx.activeMode === 'form')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.create()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Создать")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeMode === 'edit')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.canselPaint()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Назад")
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              onClick: _cache[2] || (_cache[2] = $event => (_ctx.savePaint()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Сохранить")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CandidateSelector)
        ], 512), [
          [_vShow, _ctx.activeMode === 'selector']
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseMapEdit),
          _createVNode(_component_BaseMapPreview)
        ], 512), [
          [_vShow, _ctx.activeMode === 'form']
        ]),
        (_ctx.activeMode === 'edit')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_BaseMapEditor)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}