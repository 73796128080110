import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';

export class MapLayerTaskMapWorkModel extends MapLayerModel implements IMapLayerModel {
  readonly data: FieldTaskMapWorkModel;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, indexFile: MapInputType) {
    super(mapModel, type, 'task-map-work', indexFile.uuid);
    this.data = indexFile as FieldTaskMapWorkModel;
    this.createSourceLayer();
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  createSourceLayer = async () => {
    if (!this.data.geojson?.features) {
      await this.data.fetchData();
    }
    if (this.data.geojson?.features) {
      this._mapModel?.map?.addSource(this.sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: this.data.geojson?.features,
        },
      });
      this._mapModel?.map?.addLayer({
        id: this.layerId,
        type: 'fill',
        source: this.sourceId,
        layout: {},
        metadata: { type: 'task-map-work' },
        paint: {
          'fill-color':
            ['case',
              ['==', ['get', 'zone'], '1.1'], '#BE0000',
              ['==', ['get', 'zone'], '1.2'], '#E51F02',
              ['==', ['get', 'zone'], '1.3'], '#FD5502',
              ['==', ['get', 'zone'], '2.1'], '#FCE502',
              ['==', ['get', 'zone'], '2.2'], '#FBFC02',
              ['==', ['get', 'zone'], '2.3'], '#E2FB03',
              ['==', ['get', 'zone'], '3.1'], '#65B424',
              ['==', ['get', 'zone'], '3.2'], '#2A7F01',
              ['==', ['get', 'zone'], '3.3'], '#236400',
              ['==', ['get', 'zone'], 1.1], '#BE0000',
              ['==', ['get', 'zone'], 1.2], '#E51F02',
              ['==', ['get', 'zone'], 1.3], '#FD5502',
              ['==', ['get', 'zone'], 2.1], '#FCE502',
              ['==', ['get', 'zone'], 2.2], '#FBFC02',
              ['==', ['get', 'zone'], 2.3], '#E2FB03',
              ['==', ['get', 'zone'], 3.1], '#65B424',
              ['==', ['get', 'zone'], 3.2], '#2A7F01',
              ['==', ['get', 'zone'], 3.3], '#236400',
              ['get', 'color'],
            ],
          'fill-opacity': 1,
        },
      });
      this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.TASK_MAP);
    }
  }
}
