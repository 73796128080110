import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { YieldUnitNameType } from '@/constants/types/YieldUnitNameType';
import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { NotificationDto } from '@/services/api/dto/struct/NotificationDto';
import { ReadStruct } from '@/services/api/dto/struct/ReadStruct';
import { StructDetailsDto } from '@/services/api/dto/struct/StructDetailsDto';
import { StructPaletteDto } from '@/services/api/dto/struct/StructPaletteDto';
import { StructUnitDto } from '@/services/api/dto/struct/StructUnitDto';
import { StructUnitGroupDto } from '@/services/api/dto/struct/StructUnitGroupDto';
import { StorageDto } from '@/services/api/dto/user/StorageDto';

export const endpoints = {
  getStructUnits: (): string => '/api/v1/struct/units/',
  getStructDetails: (structId: number): string => `/api/v1/struct/unit/${structId}/`,
  readStructUnits: (Id: number): string => `/api/v1/struct/unit/${Id}/`,
  deleteStruct: (id: number): string => `/api/v1/struct/unit/${id}/`,
  getGroups: (farmunit: number): string => `/api/v1/struct/unit/${farmunit}/groups/`,
  getField: (id: number): string => `/api/v1/struct/field/${id}/`,
  putFields: (id: number): string => `/api/v1/struct/field/${id}/`,
  deleteField: (id: number): string => `/api/v1/struct/field/${id}/`,
  postStruct: (): string => '/api/v1/struct/unit/add/',
  notifications: (farmunit: number): string => `/api/v1/struct/notifications/${farmunit}/`,
  notification: (structId: number, id: number): string => `/api/v1/struct/notifications/${structId}/${id}/`,
  getStorage: (id: number): string => `/api/v1/struct/storage/${id}/`,
  getStorageKey: (key: StorageKeyEnum, id: number): string => `/api/v1/struct/storage/${id}/${key}/`,
  postStorageKey: (id: number): string => `/api/v1/struct/storage/${id}/`,
  patchStorage: (key: StorageKeyEnum, id: number): string => `/api/v1/struct/storage/${id}/${key}/`,
  deleteStorage: (id: number): string => `/api/v1/struct/storage/${id}/-/`,
  deleteStorageKey: (key: StorageKeyEnum, id: number): string => `/api/v1/struct/storage/${id}/${key}/`,
  unit: (Id: number): string => `/api/v1/struct/unit/${Id}/`,
  palette: (farmunit: number): string => `/api/v1/struct/configuration/${farmunit}/palette/`,
};

export class ApiStruct extends ApiHandler {
  public async getStructDetails(structId: number): Promise<ApiResponse<StructDetailsDto>> {
    return await this.request({ auth: true }).get<StructDetailsDto>(endpoints.getStructDetails(structId));
  }

  public async structUnits(): Promise<ApiResponse<StructUnitDto[]>> {
    return await this.request({ auth: true, freeze: false }).get<StructUnitDto[]>(endpoints.getStructUnits());
  }

  public async readStructUnits(fieldId: number): Promise<ApiResponse<ReadStruct>> {
    return await this.request({ auth: true }).get<ReadStruct>(endpoints.readStructUnits(fieldId));
  }

  public async editStructUnits(fieldId: number, FormData: FormData): Promise<ApiResponse<ReadStruct>> {
    return await this.request({ auth: true }).patch<ReadStruct>(endpoints.readStructUnits(fieldId), FormData);
  }

  public async deleteStruct(farmunit: {id: number}): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).delete(endpoints.deleteStruct(farmunit.id));
  }

  public async getGroups(farmunit: number): Promise<ApiResponse<StructUnitGroupDto[]>> {
    return await this.request({ auth: true }).get<StructUnitGroupDto[]>(endpoints.getGroups(farmunit));
  }

  public async putFields(id: number, data: FormData): Promise<ApiResponse<any>> {
    return await this.request({ auth: true }).put<any>(endpoints.putFields(id), data);
  }

  public async getField(id: number): Promise<ApiResponse<any>> {
    return await this.request({ auth: true }).put<any>(endpoints.getField(id));
  }

  public async deleteField(id: number): Promise<ApiResponse<any>> {
    return await this.request({ auth: true }).delete<any>(endpoints.deleteField(id));
  }

  public async postStruct(data: FormData): Promise<ApiResponse<any>> {
    return await this.request({ auth: true }).post<any>(endpoints.postStruct(), data);
  }

  public async getNotifications(farmunit: number): Promise<ApiResponse<NotificationDto[]>> {
    return await this.request({ auth: true }).get<NotificationDto[]>(endpoints.notifications(farmunit));
  }

  public async getNotification(id: number): Promise<ApiResponse<NotificationDto>> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return await this.request({ auth: true }).get<NotificationDto>(endpoints.notification(unit, id));
  }

  public async getStorage(id: number): Promise<ApiResponse<StorageDto[]>> {
    return await this.request({ auth: true }).get<StorageDto[]>(endpoints.getStorage(id));
  }

  public async getStorageKey(key: StorageKeyEnum, id: number): Promise<ApiResponse<StorageDto>> {
    return await this.request({ auth: true }).get<StorageDto>(endpoints.getStorageKey(key, id));
  }

  public async postStorage(data: {key: string, body: {[key: string]: any} | string}, id: number): Promise<ApiResponse<StorageDto>> {
    return await this.request({ auth: true }).post<StorageDto>(endpoints.postStorageKey(id), data);
  }

  public async patchStorage(key: StorageKeyEnum, data: {[key: string]: any} | string, id: number): Promise<ApiResponse<StorageDto>> {
    return await this.request({ auth: true }).patch<StorageDto>(endpoints.patchStorage(key, id), { body: data });
  }

  public async deleteStorage(id: number): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).delete<void>(endpoints.deleteStorage(id));
  }

  public async deleteStorageKey(key: StorageKeyEnum, id: number): Promise<ApiResponse<void>> {
    return await this.request({ auth: true }).delete<void>(endpoints.deleteStorageKey(key, id));
  }

  public async updateYieldUnit(yieldUnitName: YieldUnitNameType): Promise<ApiResponse<void>> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return await this.request({ auth: true, ignoreLog: true }).patch<void>(endpoints.unit(unit), {
      conf: {
        yield_unit_name: yieldUnitName,
      },
    });
  }

  public async getPalette(): Promise<ApiResponse<StructPaletteDto[]>> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return await this.request({ auth: true }).get<StructPaletteDto[]>(endpoints.palette(unit));
  }
}
