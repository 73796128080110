import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/icons/fill.svg'
import _imports_1 from '@/assets/icons/undo.svg'
import _imports_2 from '@/assets/icons/redo.svg'
import _imports_3 from '@/assets/icons/reset.svg'


const _hoisted_1 = {
  key: 0,
  class: "BaseMapEditor-brush-tools"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "BaseMapEditor-tools" }
const _hoisted_4 = { src: _imports_0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["data-zone"]
const _hoisted_7 = { src: _imports_1 }
const _hoisted_8 = { src: _imports_2 }
const _hoisted_9 = { src: _imports_3 }

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_Pointer = _resolveComponent("Pointer")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_BrushFilled = _resolveComponent("BrushFilled")
  const _directive_svg_inline = _resolveDirective("svg-inline")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["BaseMapEditor", {'cursor-fill': !_ctx.busy && _ctx.activeTool === 'fill', 'cursor-brush': !_ctx.busy && _ctx.activeTool === 'brush', 'cursor-wait': _ctx.busy}])
  }, [
    (_ctx.activeTool === 'brush')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_tooltip, {
            content: `Режим рисования по сетке.<br/>`,
            "raw-content": "",
            placement: "bottom",
            "show-after": 300
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["BaseMapEditor-button", { active:  _ctx.activeGrid }]),
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.activeGrid = !_ctx.activeGrid))
              }, [
                _createVNode(_component_ui_icon, {
                  name: "mdiGridLarge",
                  size: 24,
                  color: _ctx.activeGrid ? '#FFF' : '#5a5f65'
                }, null, 8, ["color"])
              ], 2)
            ]),
            _: 1
          }),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([10, 20, 30, 40, 50], (i) => {
            return _createVNode(_component_el_tooltip, {
              content: `Размер курсора ${i} м`,
              "raw-content": "",
              placement: "bottom",
              "show-after": 300
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["BaseMapEditor-button", { active:  _ctx.brushing.size === i }]),
                  onClick: $event => (_ctx.brushing.size = i)
                }, [
                  _createElementVNode("span", null, _toDisplayString(i), 1)
                ], 10, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["content"])
          }), 64))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_tooltip, {
        content: `Режим курсора.<br/>Быстрый доступ: <strong>C</strong>`,
        "raw-content": "",
        placement: "left",
        "show-after": 300
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button", { active:  _ctx.activeTool === 'pointer' }]),
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.activeTool = 'pointer'))
          }, [
            _createVNode(_component_el_icon, { size: 28 }, {
              default: _withCtx(() => [
                _createVNode(_component_Pointer)
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tooltip, {
        content: `Карандаш для рисования.<br/>Быстрый доступ: <strong>B</strong>`,
        "raw-content": "",
        placement: "left",
        "show-after": 300
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button", { active:  _ctx.activeTool === 'brush' }]),
            onClick: _cache[2] || (_cache[2] = $event => (_ctx.activeTool = 'brush'))
          }, [
            _createVNode(_component_el_icon, { size: 28 }, {
              default: _withCtx(() => [
                _createVNode(_component_BrushFilled)
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tooltip, {
        content: `Заливка полигонов.<br/>Быстрый доступ: <strong>F</strong>`,
        "raw-content": "",
        placement: "left",
        "show-after": 300
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button", { active: _ctx.activeTool === 'fill' }]),
            onClick: _cache[3] || (_cache[3] = $event => (_ctx.activeTool = 'fill'))
          }, [
            _createVNode(_component_el_icon, { size: 28 }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
                  [_directive_svg_inline]
                ])
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeCandidate?.taskImage?.zones || [], (zone, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["BaseMapEditor-button zone", { active: _ctx.activeZone === zone.indexColor }]),
          onClick: $event => (_ctx.activeZone = zone.indexColor)
        }, [
          _createVNode(_component_el_tooltip, {
            content: `Установить цвет заливки и кисти.<br/>Быстрый доступ: ${index + 1}`,
            "raw-content": "",
            placement: "left",
            "show-after": 300
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "BaseMapEditor-zone",
                "data-zone": zone.indexColor,
                style: _normalizeStyle({ backgroundColor: `rgba(${_ctx.BaseMapColors[zone.indexColor]})` })
              }, null, 12, _hoisted_6)
            ]),
            _: 2
          }, 1032, ["content"])
        ], 10, _hoisted_5))
      }), 256)),
      _createVNode(_component_el_tooltip, {
        content: "Отменить последнее действие.<br/>Быстрый доступ: <strong>Ctrl+Z</strong>",
        placement: "left",
        "show-after": 300,
        "raw-content": "",
        disabled: !_ctx.undoAvailable
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button", { disabled: !_ctx.undoAvailable}]),
            onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.doUndo && _ctx.doUndo(...args)))
          }, [
            _createVNode(_component_el_icon, { size: 28 }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
                  [_directive_svg_inline]
                ])
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_el_tooltip, {
        content: "Вернуть последнее действие.<br/>Быстрый доступ: <strong>Ctrl+Shift+Z</strong>",
        placement: "left",
        "show-after": 300,
        "raw-content": "",
        disabled: !_ctx.redoAvailable
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button", { disabled: !_ctx.redoAvailable}]),
            onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.doRedo && _ctx.doRedo(...args)))
          }, [
            _createVNode(_component_el_icon, { size: 28 }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [
                  [_directive_svg_inline]
                ])
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_el_tooltip, {
        content: "Отменить все изменения",
        placement: "left",
        "show-after": 300,
        disabled: !_ctx.resetAvailable
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button", { disabled: !_ctx.resetAvailable}]),
            onClick: _cache[6] || (_cache[6] = (...args) => (_ctx.doReset && _ctx.doReset(...args)))
          }, [
            _createVNode(_component_el_icon, { size: 28 }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("img", _hoisted_9, null, 512), [
                  [_directive_svg_inline]
                ])
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ], 2))
}