import { DefaultPaletteType } from '@/constants/types/assets/DefaultPaletteType';
import { PaletteType } from '@/constants/types/palette/PaletteType';
import {
  BorderDataType,
  UniqDataType,
  UniqValuesType,
} from '@/constants/types/palette/UnifiedVectorPaletteType';
import { Model } from '@/models/Model';
import ApiService from '@/services/api/ApiService';
import { PaletteDto } from '@/services/api/dto/assets/palette/PaletteDto';
import AssetsGradients from '@/services/assets/AssetsGradients';
import LoggerService from '@/services/logger/LoggerService';
import { jenks } from '@/utils/jenks';
import { pythonRound } from '@/utils/pythonRound';
import chroma from 'chroma-js';
import { quantileSeq } from 'mathjs';
import { reactive } from 'vue';

export class PaletteModel extends Model {
  get id(): string {
    return this._id;
  }

  get values(): Record<string, number[] | string[]> {
    return this._values;
  }

  get property(): string | null {
    return this._property;
  }

  set property(value: string | null) {
    this._property = value;
    this.calculateRange();
    this.calculateUniqueValues();
  }

  private readonly _id: string;

  public type: PaletteType | null = null;

  private _property: string | null = null;

  public uniqData: UniqDataType = reactive({ values: [] as UniqValuesType[] } as UniqDataType);

  public borderData: BorderDataType = reactive({ classCount: 3, classification: 'equal', values: [] });

  /** Все ключи найденные в векторе */
  private keys: string[];

  /** Объект содержит уникальные значения из вектора для каждого ключа */
  private uniqueValues: Record<string, number[] | string[]>;

  /** Объект содержит все значения из вектора для каждого ключа */
  private _values: Record<string, number[] | string[]>;

  /** Хранит ключи свойств, которые содержат числа */
  private numberKeys: string[];

  public range = { min: 0, max: 0, diff: 0 };

  private precision: Record<string, number>;

  constructor(data: {
    uniqueValues: Record<string, number[] | string[]>,
    values: Record<string, number[] | string[]>,
    keys: string[],
    numberKeys: string[],
    precision: Record<string, number>,
    dto: PaletteDto | undefined
  }) {
    super();

    this.keys = reactive(data.keys);

    this.numberKeys = reactive(data.numberKeys);

    this.uniqueValues = reactive(data.uniqueValues);

    this._values = reactive(data.values);

    this.precision = reactive(data.precision);

    if (AssetsGradients.data.length === 0) {
      LoggerService.error('Gradients list is empty..');
    }
    this._id = data.dto?._id ? data.dto?._id : this.uuid;
    if (data.dto?.type) {
      this.type = data.dto?.type;
    } else {
      this.type = 'uniqValues';
    }

    if (data.dto?.property) {
      this.property = data.dto?.property;
    } else {
      this.calculateRange();
    }
    if (data.dto?.type === 'borderValues') {
      this.borderData = data.dto?.data as BorderDataType;
    } else {
      this.calculateBorderValues();
    }

    if (data.dto?.type === 'uniqValues') {
      this.uniqData = data.dto?.data as UniqDataType;
    } else {
      this.calculateUniqueValues();
    }
  }

  private calculateGradientStops(count: number) {
    const stops: number[] = [];

    if (count <= 1) {
      stops.push(0);
      stops.push(100);
    } else {
      for (let i = 0; i < count; i++) {
        stops.push((i * 100) / (count - 1));
      }
    }
    return stops;
  }

  private calculateRange() {
    if (this._property) {
      const numbers = [];
      for (let i = 0; i < this._values[this._property].length; i++) {
        const v = this._values[this._property][i];
        numbers.push(Number(v));
      }
      this.range.min = Math.min(...numbers);
      this.range.max = Math.max(...numbers);
      this.range.diff = this.range.max - this.range.min;
      this.calculateBorderValues();
    } else {
      this.range.min = 0;
      this.range.max = 0;
      this.range.diff = 0;
    }
  }

  private getGradient() {
    let id: string | undefined;
    if (this.type === 'borderValues') {
      id = this.borderData.gradient?.id;
    } else if (this.type === 'uniqValues') {
      id = this.uniqData.gradient?.id;
    }
    return AssetsGradients.data.find((value) => value.id === id) || AssetsGradients.data[0];
  }

  updateBorderValues(stops: number[]): void {
    if (stops.length === this.borderData.classCount + 1) {
      for (let i = 0; i < (this.borderData.classCount || 3); i++) {
        this.borderData.values[i].range.from = stops[i];
        this.borderData.values[i].range.to = stops[i + 1];
        if (/^(\d* - \d*)$/.test(this.borderData.values[i].label)) {
          this.borderData.values[i].label = `${stops[i]} - ${stops[i + 1]}`;
        }
      }
    } else {
      LoggerService.error('Update border values called with out of range array length.', stops, `Expected array with length: ${this.borderData.classCount + 1}`);
    }
  }

  getGradientColor(stops: number[], idx: number): string {
    const f = chroma
      .scale(this.getGradient().positions.map((p) => p.color))
      .domain(this.getGradient().positions.map((p) => p.position));
    return f(stops[idx]).toString();
  }

  calculateUniqueValues(): void {
    if (this.property) {
      const property = this.property.toString();
      const count = this.uniqueValues[property].length;
      for (let i = 0; i < count; i++) {
        this.uniqData.values.push({
          value: this.uniqueValues[property][i],
          color: this.getGradientColor(this.uniqData.gradient?.stops || this.calculateGradientStops(count), i),
          label: this.uniqueValues[property][i].toString(),
        });
      }
      this.uniqData.gradient = {
        id: this.getGradient().id,
        stops: this.uniqData.gradient?.stops || this.calculateGradientStops(count),
      };
    }
  }

  calculateBorderValues(): void {
    const _ranges = [];
    const precision = this.property ? this.precision[this.property] : 0;

    for (let i = 0; i < this.borderData.classCount; i++) {
      if (this.borderData.classification === 'equal') {
        _ranges.push({
          from: pythonRound(this.range.min + i * (this.range.diff / this.borderData.classCount), precision),
          to: pythonRound(this.range.min + (i + 1) * (this.range.diff / this.borderData.classCount), precision),
        });
      } else if (this.borderData.classification === 'jenkins') {
        const values: number[] = this.property ? this._values[this.property].map((v: string | number) => Number(v)) : [];
        let _prev: number | null = null;
        [this.range.min, ...(jenks(values, this.borderData.classCount - 1) as number[])].forEach((stop) => {
          if (_prev !== null) {
            _ranges.push({
              from: pythonRound(_prev, precision),
              to: pythonRound(stop, precision),
            });
          }
          _prev = stop;
        });
      } else if (this.borderData.classification === 'quantile') {
        const values: number[] = this.property ? this._values[this.property].map((v: string | number) => Number(v)) : [];
        const qs = [];
        for (let a = 1; a < this.borderData.classCount; a++) {
          qs.push(quantileSeq(values, a / (this.borderData.classCount - 1)));
        }
        let _prev: number | null = null;
        [this.range.min, ...qs].forEach((stop) => {
          if (_prev !== null) {
            _ranges.push({
              from: pythonRound(_prev, precision),
              to: pythonRound(stop, precision),
            });
          }
          _prev = stop;
        });
      }
    }
    this.borderData.values.splice(0, 10);
    for (let i = 0; i < this.borderData.classCount; i++) {
      this.borderData.values.push({
        color: this.getGradientColor(this.borderData.gradient?.stops || this.calculateGradientStops(this.borderData.classCount), i),
        label: `${_ranges[i].from} - ${_ranges[i].to}`,
        range: _ranges[i],
      });

      this.borderData.gradient = {
        id: this.getGradient().id,
        stops: this.uniqData.gradient?.stops || this.calculateGradientStops(this.borderData.classCount),
      };
    }
  }

  setType(type: PaletteType): void {
    this.type = type;
  }

  toJSON(): boolean | PaletteDto {
    if (!this.type) {
      return false;
    }
    if (!this.property) {
      return false;
    }
    let data: UniqDataType | BorderDataType | undefined;
    if (this.type === 'uniqValues') {
      data = this.uniqData;
    } else if (this.type === 'borderValues') {
      data = this.borderData;
    }
    if (!data) {
      return false;
    }
    return {
      _id: this._id,
      type: this.type,
      property: this.property,
      data,
    };
  }

  public async save(): Promise<void> {
    const json = this.toJSON();
    if (json) {
      await ApiService.assets.putPalette(json as PaletteDto);
    }
  }

  static defaultPaletteToValues = (palette: DefaultPaletteType, vectorValues: number[] | string[]): UniqDataType | BorderDataType => {
    if (palette.type === 'UNIQUE') {
      return {
        values: [
          {
            value: palette.palette[0].value,
            color: palette.palette[0].color,
            label: palette.palette[0].value.toString(),
          },
        ],
      } as UniqDataType;
    } if (palette.type === 'RANGE') {
      const min = Math.min(...vectorValues as number[]);
      const max = Math.max(...vectorValues as number[]);
      const limits = palette.palette.map((l) => ({ value: l.value, color: l.color }));
      const result: BorderDataType = {
        classification: 'custom',
        values: [],
        classCount: 0,
      };

      // Проходим по массиву лимитов
      for (let i = 0; i < limits.length; i++) {
        const currentLimit = limits[i];
        const nextLimit = limits[i + 1] ? Number(limits[i + 1].value) : Infinity;

        // Определяем диапазоны с учетом min и max
        const from = Math.max(min, Number(currentLimit.value));
        const to = Math.min(max, nextLimit);

        if (from < to) {
          result.values.push({
            range: { from, to },
            color: currentLimit.color,
            label: `${from} - ${to}`,
          });
        }
        if (to >= max) {
          break;
        }
      }
      result.classCount = result.values.length;

      return result;
    }
    return {} as BorderDataType;
  }
}
