import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "VectorPalettePanel"
}
const _hoisted_2 = {
  key: 1,
  class: "VectorPalettePanel-message"
}
const _hoisted_3 = { class: "mt-20 fw flex-row flex-row-justify-between" }

export function render(_ctx, _cache) {
  const _component_SettingsPalettePanel = _resolveComponent("SettingsPalettePanel")
  const _component_el_skeleton = _resolveComponent("el-skeleton")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_UniqValuesTab = _resolveComponent("UniqValuesTab")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_BorderValuesTab = _resolveComponent("BorderValuesTab")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.node)
      ? (_openBlock(), _createBlock(_component_SettingsPalettePanel, {
          key: 0,
          node: _ctx.node,
          onDeletePalette: _cache[0] || (_cache[0] = $event => (_ctx.$emit('delete-palette', $event))),
          onSaveName: _cache[1] || (_cache[1] = $event => (_ctx.$emit('save-name', $event)))
        }, null, 8, ["node"]))
      : _createCommentVNode("", true),
    (_ctx.vector.palette && _ctx.node)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_el_skeleton, {
                key: 0,
                rows: 5,
                animated: ""
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_el_form, {
                  "label-position": "top",
                  inline: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('palette-property', { ns: 'palette' })
                    }, {
                      default: _withCtx(() => [
                        (_ctx.vector.keys.length > 0)
                          ? (_openBlock(), _createBlock(_component_el_select, {
                              key: 0,
                              modelValue: _ctx.vector.palette.property,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.vector.palette.property) = $event))
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vector.keys, (key) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: key,
                                    label: key,
                                    value: key
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                (_ctx.vector.palette.property && _ctx.vector.palette?.type)
                  ? (_openBlock(), _createBlock(_component_el_tabs, {
                      key: 0,
                      modelValue: _ctx.vector.palette.type,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.vector.palette.type) = $event)),
                      lazy: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tab_pane, {
                          label: "Уникальные значения",
                          name: "uniqValues"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.vector.palette.type === 'uniqValues')
                              ? (_openBlock(), _createBlock(_component_UniqValuesTab, {
                                  key: 0,
                                  file: _ctx.vector
                                }, null, 8, ["file"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_tab_pane, {
                          label: "Естественные границы",
                          name: "borderValues"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.vector.palette.type === 'borderValues' && _ctx.vector.numberKeys.includes(_ctx.vector.palette.property))
                              ? (_openBlock(), _createBlock(_component_BorderValuesTab, {
                                  key: 0,
                                  file: _ctx.vector
                                }, null, 8, ["file"]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('borders-palette-is-not-available', `Раскраска по естественным границам не доступна, среди значений обнаружены не числовые значения.`, { ns: 'palette' })), 1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_el_button, {
                    onClick: _ctx.save,
                    type: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Сохранить")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_button, {
                    onClick: _ctx.apply,
                    type: "info"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Предпросмотр")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_popconfirm, {
                    title: _ctx.$t('side-panel-palette-settings-cancel', 'Продолжая, Вы отмените все изменения в этом окне. Настройки раскраски будут возвращены к начальному виду.', { ns: 'palette' }),
                    width: 260,
                    "hide-icon": true,
                    teleported: false,
                    "confirm-button-type": "warning",
                    "confirm-button-text": "Продолжить",
                    "cancel-button-text": "Отмена",
                    onConfirm: _ctx.close
                  }, {
                    reference: _withCtx(() => [
                      _createVNode(_component_el_button, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Отмена")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["title", "onConfirm"])
                ])
              ], 64))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}