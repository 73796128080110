<template src="./BaseMapPreview.pug" lang="pug"/>
<style src="./BaseMapPreview.scss" lang="scss"/>
<script lang="ts">
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import Coordinates from '@/constants/types/mapbox/Coordinates';
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { BaseMapColors } from '@/assets/data/BaseMapColors';
import { CanvasSource, LngLatLike } from 'mapbox-gl';
import { useBaseMapEditor } from '@/composables/baseMap/useBaseMapEditor';
import { FIELDS_LAYER_ID, POI_LAYER_ID, POI_SOURCE } from '@/constants/constants/mapbox';
import { useCreateBaseMapPreviewPoi } from '@/composables/baseMap/create/useCreateBaseMapPreviewPoi';
import { useCreateBaseMapModal } from '@/composables/baseMap/create/useCreateBaseMapModal';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import FieldsList from '@/modules/fields/FieldsList';
import PoisList from '@/modules/poi/PoisList';
import { PoiModel } from '@/models/poi/PoiModel';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';

export default defineComponent({
  name: 'BaseMapPreview',
  components: {
  },
  setup() {
    const {
      canvasPreview,
      canvasPreviewCtx,
      candidates,
      activeCandidate,
      activeMode,
      previewLayer,
    } = useCreateBaseMapModal();
    const {
      activeField,
      setActiveField,
      mapModel,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const {
      eventsSubscription,
      scaleIncrement,
      focused,
    } = useBaseMapEditor();
    const {
      activePois,
      showPoiGroups,
    } = useCreateBaseMapPreviewPoi();

    const scaleWidth = computed(() => (activeCandidate.value?.taskImage?.scaledWidth || 0));
    const scaleHeight = computed(() => (activeCandidate.value?.taskImage?.scaledHeight || 0));

    const map = computed(() => mapModel.value?.map);
    const hideCanvas = () => {
      if (map.value && previewLayer.value !== undefined) {
        map.value.setLayoutProperty(previewLayer.value.layerId, 'visibility', 'none');
      }
    };
    const showCanvas = () => {
      if (map.value && previewLayer.value !== undefined) {
        map.value.setLayoutProperty(previewLayer.value.layerId, 'visibility', 'visible');
      }
    };
    const savedCopy = ref<Record<number, ImageData>>();
    const focusChange = () => {
      if (canvasPreviewCtx.value) {
        if (savedCopy.value && savedCopy.value[focused.value]) {
          canvasPreviewCtx.value.putImageData(savedCopy.value[focused.value], 0, 0);
        } else {
          savedCopy.value = {
            [focused.value]: canvasPreviewCtx.value.getImageData(0, 0, scaleWidth.value, scaleHeight.value),
          };
          if (activeCandidate.value?.taskImage && activeCandidate.value?.taskImage) {
            const id = new Uint8ClampedArray(activeCandidate.value?.taskImage.imageDataZoned);
            const buffer = canvasPreviewCtx.value.getImageData(0, 0, scaleWidth.value, scaleHeight.value).data.buffer;
            const data = new Uint8ClampedArray(buffer);
            id.forEach((pxl, i) => {
              if (pxl > 0) {
                data[i * 4] = (focused.value > 0 && focused.value !== pxl) ? BaseMapColors[pxl][0] / 2 : BaseMapColors[pxl][0];
                data[i * 4 + 1] = (focused.value > 0 && focused.value !== pxl) ? BaseMapColors[pxl][1] / 2 : BaseMapColors[pxl][1];
                data[i * 4 + 2] = (focused.value > 0 && focused.value !== pxl) ? BaseMapColors[pxl][2] / 2 : BaseMapColors[pxl][2];
                data[i * 4 + 3] = (focused.value > 0 && focused.value !== pxl) ? 50 : BaseMapColors[pxl][3];
              } else {
                data[i * 4] = BaseMapColors[0][0];
                data[i * 4 + 1] = BaseMapColors[0][1];
                data[i * 4 + 2] = BaseMapColors[0][2];
                data[i * 4 + 3] = BaseMapColors[0][3];
              }
            });
            const img = new ImageData(data, scaleWidth.value, scaleHeight.value);
            canvasPreviewCtx.value.putImageData(img, 0, 0);
          }
        }
      }
    };
    const drawFieldCandidate = (fieldId = 0) => {
      if (!fieldId) {
        fieldId = activeField.value?.id || 0;
      }
      const candidate = candidates.value.candidates.find((c) => c.fieldId === fieldId && c.isActive);

      if (candidate?.taskImage && map.value && previewLayer.value !== undefined) {
        const bbox = [
          [candidate.taskImage.bounds.getWest(), candidate.taskImage.bounds.getNorth()],
          [candidate.taskImage.bounds.getEast(), candidate.taskImage.bounds.getNorth()],
          [candidate.taskImage.bounds.getEast(), candidate.taskImage.bounds.getSouth()],
          [candidate.taskImage.bounds.getWest(), candidate.taskImage.bounds.getSouth()],
        ] as Coordinates;

        (map.value.getSource(previewLayer.value?.sourceId || '1') as CanvasSource)?.setCoordinates(bbox.reverse() as Coordinates);

        if (canvasPreview.value && canvasPreviewCtx.value) {
          const id = candidate.taskImage.imageDataZoned;
          const buffer = canvasPreviewCtx.value.getImageData(0, 0, candidate.taskImage.scaledWidth, candidate.taskImage.scaledHeight).data.buffer;
          const data = new Uint8ClampedArray(buffer);
          id.forEach((pxl, i) => {
            if (pxl > 0) {
              data[i * 4] = BaseMapColors[pxl][0];
              data[i * 4 + 1] = BaseMapColors[pxl][1];
              data[i * 4 + 2] = BaseMapColors[pxl][2];
              data[i * 4 + 3] = (focused.value > 0 && focused.value !== pxl) ? 100 : BaseMapColors[pxl][3];
            } else {
              data[i * 4] = BaseMapColors[0][0];
              data[i * 4 + 1] = BaseMapColors[0][1];
              data[i * 4 + 2] = BaseMapColors[0][2];
              data[i * 4 + 3] = BaseMapColors[0][3];
            }
          });

          const img = new ImageData(data, candidate.taskImage.scaledWidth, candidate.taskImage.scaledHeight);
          canvasPreview.value.width = candidate.taskImage.scaledWidth;
          canvasPreview.value.height = candidate.taskImage.scaledHeight;
          canvasPreviewCtx.value.putImageData(img, 0, 0);

          showCanvas();
        }
      } else {
        hideCanvas();
      }
      return undefined;
    };
    const initMap = async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      if (map.value) {
        // canvasMapSourcesAndLayers();
        // fieldsMapSourcesAndLayers();
        // poiMapSourcesAndLayers();

        // if (FieldsList.structBounds.value) {
        //   map.value.fitBounds(FieldsList.structBounds.value, {
        //     padding: {
        //       top: 100,
        //       bottom: 100,
        //       left: 20,
        //       right: 20,
        //     },
        //   });
        // }
        eventsSubscription(map.value, 'preview');
        BaseMapEvents.onFocusChange(focusChange);

        BaseMapEvents.onZoneChanged(
          drawFieldCandidate,
        );
        BaseMapEvents.onActivateCandidate((a) => {
          drawFieldCandidate(a);
        });
        map.value.on('click', FIELDS_LAYER_ID, (e) => {
          if (map.value && activeMode.value !== 'edit') {
            const features = map.value.queryRenderedFeatures(e.point);
            const feature = features.find((v) => v.layer?.id === FIELDS_LAYER_ID);
            if (feature && feature.properties?.pk) {
              const field = FieldsList.findFieldById(feature.properties.pk);
              if (field && activeField.value?.id !== field.id) {
                setActiveField(field);
                FieldsEvents.emitChangeActiveField(field.id);
              }
            }
          }
        });
        map.value.on('click', POI_LAYER_ID, (e) => {
          if (map.value) {
            const features = map.value.queryRenderedFeatures(e.point).filter((v) => v.layer?.id === POI_LAYER_ID);
            const poiIds = features.reduce((acc, f) => {
              if (!acc.includes(Number(f?.id))) {
                acc.push(Number(f.id));
              }
              return acc;
            }, [] as number[]);
            activePois.value = poiIds
              .map((id) => PoisList.pois.value.find((v) => v.id === id) as PoiModel | undefined)
              .filter((m) => !!m) as PoiModel[];
          }
        });
        // region hover POI effect
        const hoveredPOI = ref();

        map.value?.on('mousemove', (e) => {
          EventBus.$emit(EventsEnum.MapMouseMove, e);
        });

        map.value.on('mouseover', POI_LAYER_ID, (e) => {
          const feature = map.value?.queryRenderedFeatures(e.point).find((v) => v.layer?.id === POI_LAYER_ID);
          if (feature) {
            map.value?.setFeatureState(feature, { hover: true });
            hoveredPOI.value = feature;
          }
        });
        map.value.on('mouseleave', POI_LAYER_ID, () => {
          map.value?.setFeatureState(hoveredPOI.value, { hover: false });
          hoveredPOI.value = undefined;
        });

        map.value.on('mouseover', () => {
          mapModel.value?.cursor?.setVisibility(false);
        });
        map.value.on('mouseout', () => {
          EventBus.$emit(EventsEnum.MapMouseMove, { lngLat: { lng: 0, lat: 0 } });

          mapModel.value?.cursor?.setVisibility(true);
        });
        // endregion
        // region active POI effect
        watch(activePois, (v) => {
          map.value?.querySourceFeatures(POI_SOURCE).forEach((feature) => {
            if (feature?.id) {
              map.value?.setFeatureState(
                { source: POI_SOURCE, id: feature.id },
                { active: false },
              );
            }
          });
          if (v?.length) {
            map.value?.setFeatureState(
              { source: POI_SOURCE, id: v[0].id },
              { active: true },
            );
            if (activeMode.value !== 'edit') {
              map.value?.flyTo({
                center: v[0].geometry.coordinates as LngLatLike,
              });
            }
          }
        });
        // endregion
        // FieldsEvents.onChangeActiveField((fieldId) => {
        //   hideCanvas();
        //   if (fieldId) {
        //     const field = FieldsList.findFieldById(fieldId);
        //     if (field?.bounds) {
        //       if (map.value) {
        //         map.value.fitBounds(field.bounds, {
        //           padding: {
        //             top: 20,
        //             bottom: 20,
        //             left: 20,
        //             right: 20,
        //           },
        //           duration: 1000,
        //           maxZoom: 14.5,
        //         });
        //       }
        //     }
        //     drawFieldCandidate(fieldId);
        //   }
        //   if (map.value) {
        //     FieldsList.fields.forEach((f) => {
        //       map.value?.setFeatureState(
        //         { source: FIELDS_SOURCE, id: f.id },
        //         { active: false },
        //       );
        //     });
        //     if (fieldId) {
        //       map.value?.setFeatureState(
        //         { source: FIELDS_SOURCE, id: fieldId },
        //         { active: true },
        //       );
        //     }
        //     // @ts-ignore
        //     map.value?.moveLayer(mapModel.value?.cursor?.layerId, null);
        //   }
        // });
      }
    };
    onMounted(async () => {
      initMap();
      // todo вопрос с эвентомы

      BaseMapEvents.onEditSaved(drawFieldCandidate);
      BaseMapEvents.onCandidateModeChange(() => {
        //
      });
    });
    // region resize watcher
    onMounted(() => {
      const el = document.getElementById('BaseMapPreviewMap');
      let prevValue = 0;
      setInterval(() => {
        const currentValue = el?.offsetWidth || 0;
        if (prevValue !== currentValue) {
          prevValue = currentValue;
          map.value?.resize();
        }
      });
    });
    // endregion

    watch(activeCandidate, () => {
      drawFieldCandidate(activeField.value?.id);
    });
    return {
      scaleIncrement,
      scaleWidth,
      scaleHeight,
      map,
    };
  },
});
</script>
