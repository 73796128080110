<template src="./NavigationAccount.pug" lang="pug"/>
<style src="./NavigationAccount.scss" lang="scss" />

<script lang="ts">
import AuthService from '@/services/auth/AuthService';
import { useUser } from '@/composables/useUser';
import { useMapLayout } from '@/composables/useMapLayout';
import { defineComponent } from 'vue';
import { isDevMode } from '@/lib/tools/isDevMode';

export default defineComponent({
  name: 'NavigationAccount',

  setup() {
    const { user } = useUser();
    const { showBlock } = useMapLayout();

    const logout = () => {
      AuthService.forceLogout();
    };

    const profile = () => {
      showBlock('UserProfileBlock');
    };

    const console = () => {
      showBlock('ConsoleLoggerBlock');
    };

    const apiKeys = () => {
      showBlock('ApiKeysBlock');
    };

    return {
      logout,
      profile,
      user,
      console,
      apiKeys,
      isDevMode,
    };
  },
});
</script>
