import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ViewTaskTabsContent = _resolveComponent("ViewTaskTabsContent")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.activeField)
      ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 0,
          placement: "right-start",
          width: 300,
          trigger: "hover",
          teleported: false,
          "hide-after": 0
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["MapContainer-Button", { active: _ctx.active === 'taskFile' }])
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ui_icon, {
                    name: "/images/icons/submenu/folder-files-eye.svg",
                    size: 24
                  })
                ]),
                _: 1
              })
            ], 2)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.activeTasksTab,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeTasksTab) = $event)),
              stretch: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, {
                  label: "Рабочие карты",
                  name: _ctx.TaskTypeEnum.WORK
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ViewTaskTabsContent, {
                      tasks: _ctx.activeField?.workTaskMaps,
                      loading: _ctx.isMapTasksLoaded,
                      actions: false,
                      "map-uuid": _ctx.map.uuid,
                      onView: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewTask('work', $event)))
                    }, null, 8, ["tasks", "loading", "map-uuid"])
                  ]),
                  _: 1
                }, 8, ["name"]),
                _createVNode(_component_el_tab_pane, {
                  label: "Шаблоны карт",
                  name: _ctx.TaskTypeEnum.BASE
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ViewTaskTabsContent, {
                      tasks: _ctx.activeField?.baseTaskMaps,
                      loading: _ctx.isMapTasksLoaded,
                      actions: false,
                      "map-uuid": _ctx.map.uuid,
                      onView: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewTask('base', $event)))
                    }, null, 8, ["tasks", "loading", "map-uuid"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.activeField)
      ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 1,
          placement: "right-start",
          width: 300,
          trigger: "hover",
          teleported: false,
          "hide-after": 0
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["MapContainer-Button", { active: _ctx.active === 'importFile' }])
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ui_icon, {
                    name: "/images/icons/submenu/folder_download_files.svg",
                    size: 24
                  })
                ]),
                _: 1
              })
            ], 2)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.activeImportTab,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.activeImportTab) = $event)),
              stretch: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, {
                  label: "Карты факта",
                  name: _ctx.TaskTypeEnum.FACT
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ViewTaskTabsContent, {
                      tasks: _ctx.activeField?.factTaskMaps,
                      loading: _ctx.isMapTasksLoaded,
                      actions: false,
                      "map-uuid": _ctx.map.uuid,
                      onView: _cache[3] || (_cache[3] = ($event: any) => (_ctx.viewTask('fact', $event)))
                    }, null, 8, ["tasks", "loading", "map-uuid"])
                  ]),
                  _: 1
                }, 8, ["name"]),
                _createVNode(_component_el_tab_pane, {
                  label: "Карты урожайности",
                  name: _ctx.TaskTypeEnum.HARVEST
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ViewTaskTabsContent, {
                      tasks: _ctx.activeField?.harvestTaskMaps,
                      loading: _ctx.isMapTasksLoaded,
                      actions: false,
                      "map-uuid": _ctx.map.uuid,
                      onView: _cache[4] || (_cache[4] = ($event: any) => (_ctx.viewTask('harvest', $event)))
                    }, null, 8, ["tasks", "loading", "map-uuid"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}