import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import EventBus from '@/services/eventBus/EventBus';
import { Feature, FeatureCollection } from 'geojson';
import { GeoJSONSource, MapMouseEvent } from 'mapbox-gl';

export class MapLayerCursorModel extends MapLayerModel {
  constructor(mapModel: MapModel) {
    super(mapModel, MapLayerTypeEnum.CURSOR, 'cursor', 'cursor');
    this.create();
    EventBus.$on(EventsEnum.MapMouseMove, (e: MapMouseEvent) => {
      this.move(e);
    });
  }

  setVisibility(flag: boolean): void {
    this._mapModel?.map?.setLayoutProperty(this.layerId, 'visibility', flag ? 'visible' : 'none');
  }

  calculateData(x: number, y: number): FeatureCollection {
    return {
      type: 'FeatureCollection',
      features: [{
        id: 0,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [x, y],
        },
        properties: {},
      } as Feature],
    };
  }

  move(e: MapMouseEvent): void {
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource)?.setData(this.calculateData(e.lngLat.lng, e.lngLat.lat));
  }

  create = () => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this.calculateData(0, 0),
    });

    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'circle',
      source: this.sourceId,
      layout: {
        visibility: 'none',
      },
      paint: {
        'circle-radius': [
          'interpolate', ['linear'], ['zoom'],
          7, 1,
          11, 2,
          12, 4,
          15, 5,
        ],
        'circle-color': '#006C67',
        'circle-stroke-width': [
          'interpolate', ['linear'], ['zoom'],
          7, 1,
          11, 1,
          12, 2,
          15, 2,
        ],
        'circle-stroke-color': '#FFFFFF',
      },
    });

    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.CURSOR);
  }
}
