import { Feature } from 'geojson';
import { Model } from '@/models/Model';

export class MapDrawerModel extends Model {
  public polygon: Feature

  constructor() {
    super();
    this.polygon = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [[]],
      },
    };
  }

  clearCoordinates() {
    if ('coordinates' in this.polygon.geometry) {
      this.polygon.geometry.coordinates = [[]];
    }
  }
}
