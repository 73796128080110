<template src="./MonitoringPanel.pug" lang="pug"/>
<style src="./MonitoringPanel.scss" lang="scss"/>

<script lang="ts">
import Content from '@/components/shared/Content/Content.vue';
import IndexesInfo from '@/components/shared/IndexesInfo/IndexesInfo.vue';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import TaskMapSelector from '@/components/shared/TaskMapSelector/TaskMapSelector.vue';
import NoActiveField from '@/components/ui/NoActiveField/NoActiveField.vue';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { useMonitoring } from '@/composables/useMonitoring';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MonitoringIndexKindType } from '@/constants/types/monitoring/MonitoringIndexKindType';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import { MapLayerIndexModel } from '@/models/map/Layers/MapLayerIndexModel';
import { MapLayerNirModel } from '@/models/map/Layers/MapLayerNirModel';
import { MapLayerTaskMapContourModel } from '@/models/map/Layers/MapLayerTaskMapContourModel';
import { MonitoringTaskMapContourModel } from '@/models/monitoring/MonitoringTaskMapContourModel';
import LoadingEvents from '@/services/loading/LoadingEvents';
import LoadingStatus from '@/services/loading/LoadingStatus';
import MyTrackerService from '@/services/myTracker/MyTrackerService';
import { formatRuDate } from '@/utils/formatRuDate';
import { ElNotification } from 'element-plus';
import {
  computed, defineComponent, onMounted, PropType, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'MonitoringPanel',
  components: {
    SidePanelContent,
    TaskMapSelector,
    NoActiveField,
    Content,
    NotFound,
    IndexesInfo,
  },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      indexesKinds,
    } = useMonitoring();

    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;
    const { activeField } = useMapContainers(props.mapContainer);

    const selectedIndex = ref<MonitoringIndexKindType | undefined>();
    const selectedFile = ref<FieldNirModel | undefined>();
    const isShowContrast = ref(false);
    const isShowNir = ref(false);
    const showSelectFile = ref(false);
    const isShowTaskMap = ref(false);
    const taskMapContourModel = ref<MonitoringTaskMapContourModel | undefined>();
    const indexFile = computed<FieldIndexMonitoringModel | undefined>(() => (activeField.value?.monitoringIndexes.find((index) => index.name === selectedIndex.value?.name && index.sceneId === selectedFile?.value?.scene.id)));

    const selectedTaskMap = ref<FieldTaskMapModel | undefined>();

    const products = ref<{name: string, rate: number, type: string, unit: string, show: boolean}[]>([]);

    const taskMapSettings = ref({
      fill: 1,
      opacity: 100,
      showLabels: false,
    });

    const renderIndex = () => {
      if (mapModel.value) {
        if (!indexFile.value) {
          mapModel.value.removeLayer(MapLayerTypeEnum.MONITORING_INDEX);
        } else {
          const layer = (mapModel.value.getLayer(MapLayerTypeEnum.MONITORING_INDEX) as MapLayerIndexModel);
          if (layer) {
            indexFile.value.showContrast = isShowContrast.value;
            layer.updateImage(indexFile.value);
          } else {
            mapModel.value.render(indexFile.value);
          }
        }
      }
    };

    const renderNir = () => {
      if (mapModel.value) {
        if (!selectedFile.value || !isShowNir.value) {
          mapModel.value.removeLayer(MapLayerTypeEnum.NIR_FILE);
        } else {
          const layer = mapModel.value.getLayer(MapLayerTypeEnum.NIR_FILE) as MapLayerNirModel;
          if (layer) {
            layer.updateImage(selectedFile.value);
          } else {
            mapModel.value.render(selectedFile.value);
          }
        }
      }
    };

    // region Task map contour updates
    watch(isShowTaskMap, () => {
      if (isShowTaskMap.value) {
        taskMapContourModel.value = new MonitoringTaskMapContourModel();
        mapModel.value?.render(taskMapContourModel.value);
      } else if (taskMapContourModel.value) {
        mapModel.value?.removeLayer(taskMapContourModel.value.uuid);
        taskMapContourModel.value = undefined;
      }
    });

    watch(selectedTaskMap, async () => {
      if (taskMapContourModel.value) {
        await taskMapContourModel.value.setTaskMap(selectedTaskMap.value);
        (mapModel.value?.getLayer(MapLayerTypeEnum.TASK_MAP_CONTOUR) as MapLayerTaskMapContourModel)?.update();
      }
    });

    watch(indexFile, async () => {
      if (taskMapContourModel.value) {
        await taskMapContourModel.value.setIndexes(indexFile.value);
        (mapModel.value?.getLayer(MapLayerTypeEnum.TASK_MAP_CONTOUR) as MapLayerTaskMapContourModel)?.update();
      }
    });

    watch(taskMapSettings, () => {
      if (taskMapContourModel.value) {
        (mapModel.value?.getLayer(MapLayerTypeEnum.TASK_MAP_CONTOUR) as MapLayerTaskMapContourModel)?.setSettings(taskMapSettings.value);
      }
    }, { deep: true });
    // endregion

    watch(isShowContrast, renderIndex);
    watch(selectedFile, () => {
      renderIndex();
      renderNir();
    });
    watch(isShowNir, renderNir);

    const indexMenuItemClicked = (kind: MonitoringIndexKindType) => {
      if (selectedIndex.value?.name === kind.name) {
        selectedIndex.value = undefined;
      } else if (!selectedFile.value) {
        selectedIndex.value = undefined;
        ElNotification({
          title: 'Файл индекса не найден',
          message: 'Данный индекс в процессе формирования.',
          type: 'warning',
          duration: 2000,
          position: 'bottom-right',
        });
      } else {
        selectedIndex.value = kind;
        MyTrackerService.send('Select index (Indexes selector)', { name: selectedIndex.value?.name || '', label: selectedIndex.value?.label || '' });
      }
      renderIndex();
    };

    watch(activeField, async () => {
      selectedFile.value = undefined;
      selectedIndex.value = undefined;
      selectedTaskMap.value = undefined;
      isShowTaskMap.value = false;
      await activeField.value?.fetchMonitoringIndexes();
      await activeField.value?.fetchNirFiles();
      if ((activeField.value?.nirFiles || []).length > 0) {
        selectedFile.value = activeField.value?.nirFiles[0];
      }
    });

    const loading = computed(() => LoadingStatus.isLoading.value(LoadingNamesEnum.FIELD_NIR_FILES, activeField.value?.id) || LoadingStatus.isLoading.value(LoadingNamesEnum.MONITORING_INDEXES, activeField.value?.id));

    onMounted(() => {
      activeField.value?.fetchMonitoringIndexes();
      activeField.value?.fetchNirFiles();
      LoadingEvents.onLoadingEnds((name) => {
        if (name === LoadingNamesEnum.FIELD_NIR_FILES) {
          if (activeField.value && activeField.value.nirFiles.length > 0) {
            selectedFile.value = activeField.value?.nirFiles[0];
          } else {
            selectedFile.value = undefined;
          }
        }
      });
    });

    const taskMaps = computed(() => [...(activeField.value?.workTaskMaps || []), ...(activeField.value?.baseTaskMaps || [])]);

    const changeOpacity = (evt: MouseEvent) => {
      if (evt.target) {
        const rect = (evt.target as HTMLElement)?.getBoundingClientRect();
        taskMapSettings.value.opacity = ((evt.clientX - rect.left) * 100) / rect.width;
      }
    };

    return {
      loading,
      indexMenuItemClicked,
      selectedFile,
      selectedIndex,
      indexesKinds,
      isShowContrast,
      isShowNir,
      isShowTaskMap,
      showSelectFile,
      formatRuDate,
      changeOpacity,
      activeField,
      mapModel,
      taskMaps,
      taskMapSettings,
      products,
      selectedTaskMap,
      MapContainerEnum,
    };
  },
});
</script>
