import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { LoadingStatusEnum } from '@/constants/enums/LoadingStatusEnum';
import { AnyObject } from '@/constants/types/BasedTypes';
import LoadingEvents from '@/services/loading/LoadingEvents';
import LoggerService from '@/services/logger/LoggerService';
import {
  computed, ComputedRef, Ref, ref,
} from 'vue';

type KEY_TYPE = string | number;

type LOADER_TYPE = {
  structId: number,
  key: KEY_TYPE,
  loader: LoadingNamesEnum,
  loaderName: string,
  status: Ref<LoadingStatusEnum>,
}

class LoadingStatus {
  public loaders: LOADER_TYPE[] = [];

  private currentStructId() {
    return Number(window.localStorage.getItem('structId') || '0');
  }

  private initLoader(name: LoadingNamesEnum, key: KEY_TYPE = 'default'): void {
    const foundLoader = this.loaders.find((v) => v.structId === this.currentStructId() && v.loader === name && v.key === key);
    if (!foundLoader) {
      const loaderName = Object.keys(LoadingNamesEnum)[Object.keys(LoadingNamesEnum).length / 2 + name];
      this.loaders.push({
        structId: this.currentStructId(),
        key,
        loader: name,
        loaderName,
        status: ref<LoadingStatusEnum>(LoadingStatusEnum.NONE),
      });
    }
  }

  private loader(name: LoadingNamesEnum, key: KEY_TYPE = 'default'): LOADER_TYPE {
    this.initLoader(name, key);
    return this.loaders.find((v) => v.structId === this.currentStructId() && v.loader === name && v.key === key) as LOADER_TYPE;
  }

  /**
   * Установить лоудеру статус loading
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public loading(name: LoadingNamesEnum, key: KEY_TYPE = 'default') {
    this.loader(name, key).status.value = LoadingStatusEnum.LOADING;
    LoadingEvents.emitLoadingStart(name, key);
  }

  /**
   * Установить лоудеру статус error
   * @param name - имя лоудера
   * @param key - ключ лоудера
   * @param e - сама ошибка
   */
  public error(name: LoadingNamesEnum, e: string | unknown | Error | AnyObject, key: KEY_TYPE = 'default') {
    LoggerService.error(e);
    this.loader(name, key).status.value = LoadingStatusEnum.ERROR;
    LoadingEvents.emitLoadingEnds(name, key);
  }

  /**
   * Установить лоудеру статус success
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public success(name: LoadingNamesEnum, key: KEY_TYPE = 'default') {
    this.loader(name, key).status.value = LoadingStatusEnum.SUCCESS;
    LoadingEvents.emitLoadingEnds(name, key);
  }

  /**
   * Установить лоудеру статус none
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public none(name: LoadingNamesEnum, key: KEY_TYPE = 'default') {
    this.loader(name, key).status.value = LoadingStatusEnum.NONE;
  }

  /**
   * Проверка если лоуадер ещё не вызывался
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public isNone: ComputedRef<(name: LoadingNamesEnum, key?: KEY_TYPE) => boolean> = computed(() => (name: LoadingNamesEnum, key: KEY_TYPE = 'default') => this.loader(name, key).status.value === LoadingStatusEnum.NONE);

  /**
   * Проверка если лоуадер сейчас скачивает данные
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public isLoading: ComputedRef<(name: LoadingNamesEnum, key?: KEY_TYPE) => boolean> = computed(() => (name: LoadingNamesEnum, key: KEY_TYPE = 'default') => this.loader(name, key).status.value === LoadingStatusEnum.LOADING);

  /**
   * Проверка если загрузка прошла с ошибкой
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public isError: ComputedRef<(name: LoadingNamesEnum, key?: KEY_TYPE) => boolean> = computed(() => (name: LoadingNamesEnum, key: KEY_TYPE = 'default') => this.loader(name, key).status.value === LoadingStatusEnum.ERROR);

  /**
   * Проверка если загрузка прошла успешно
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public isSuccess: ComputedRef<(name: LoadingNamesEnum | LoadingNamesEnum[], key?: KEY_TYPE) => boolean> = computed(() => (name: LoadingNamesEnum | LoadingNamesEnum[], key: KEY_TYPE = 'default') => {
    if (!Array.isArray(name)) {
      name = [name];
    }
    return name.every((v) => this.loader(v, key)?.status.value === LoadingStatusEnum.SUCCESS);
  });

  /**
   * Проверка если загрузка уже завершена, с ошибкой или успешно
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public isLoaded: ComputedRef<(name: LoadingNamesEnum, key?: KEY_TYPE) => boolean> = computed(() => (name: LoadingNamesEnum, key: KEY_TYPE = 'default') => this.loader(name, key).status.value !== LoadingStatusEnum.NONE && this.loader(name, key).status.value !== LoadingStatusEnum.LOADING);

  /**
   * Возвращает Promise который резолвится, когда загрузка по указанному лоудеру с указанным ключем завершится.
   * @param name - имя лоудера
   * @param key - ключ лоудера
   */
  public awaitLoad = (name: LoadingNamesEnum, key: KEY_TYPE = 'default') => new Promise<void>((resolve) => {
    if (![LoadingStatusEnum.NONE, LoadingStatusEnum.LOADING].includes(this.loader(name, key).status.value)) {
      resolve();
    } else {
      LoadingEvents.onLoadingEnds((_name, _key) => {
        if (_name === name && _key === key) {
          resolve();
        }
      });
    }
  });
}

export default new LoadingStatus();
