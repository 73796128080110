<template lang="pug" src="./MapAreaInfo.pug"/>
<style  lang="scss" src="./MapAreaInfo.scss"/>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, ref, toRefs,
} from 'vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';

export default defineComponent({
  name: 'MapAreaInfo',
  components: {
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    mapContainerName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const data = ref<any[]>();
    const { open } = toRefs(props);
    const computedOpen = computed(() => open.value);

    const closeDrawer = () => {
      open.value = false;
      emit('update:open', false);
    };

    const computedDataStats = computed(() => {
      const acc: {
        name: string,
        min: number,
        max: number,
        sum: number,
        unique: number,
        uniqueValues: Set<number>
      }[] = [];

      const dp: {
        name: string,
        value: number,
      }[] = [

      ];

      data.value?.forEach((d) => {
        JSON.parse(d.prod).forEach((v: {name: string, rate: number}) => {
          dp.push({
            name: v.name,
            value: v.rate,
          });
        });
      });

      dp.forEach((item) => {
        const existingItem = acc.find((elem) => elem.name === item.name);

        if (existingItem) {
          existingItem.min = Math.min(existingItem.min, item.value);
          existingItem.max = Math.max(existingItem.max, item.value);
          existingItem.sum += item.value;
          existingItem.unique = existingItem.uniqueValues.size;
          existingItem.uniqueValues.add(item.value);
        } else {
          const uniqueValues = new Set<number>();
          uniqueValues.add(item.value);

          acc.push({
            name: item.name,
            min: item.value,
            max: item.value,
            sum: item.value,
            unique: uniqueValues.size,
            uniqueValues,
          });
        }
      });

      return acc;
    });

    onMounted(() => {
      // data.value = getByIntersectFeatures(computedMap.value?.map as mapboxgl.Map, computedMap.value?.drawerLayer?.polygon as Feature, ['task-map-view'])
      //   ?.map((a) => a.properties).filter((obj, index, self) => index === self.findIndex((t) => (
      //     t?.id === obj?.id
      //   )));
    });

    return {
      data,
      computedOpen,
      closeDrawer,
      computedDataStats,
    };
  },
});
</script>
