// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/inner-border.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--transition-default:all 0.25s ease;--transition-compareMap:top 0.25s ease,left 0.25s ease,right 0.25s ease,bottom 0.25s ease,width 0.25s ease,height 0.25s ease,opacity 0.25s ease;font-size:1rem}.MapToolButton{height:40px;width:40px;background:var(--color-dark-background);display:flex;align-items:center;justify-content:center;border-radius:10px;box-shadow:0 2px 8px rgba(0,0,0,.25);cursor:pointer;transition:var(--transition-default);color:var(--color-dark-foreground)}.MapToolButton img{width:24px;height:24px}.MapToolButton.active{background:var(--color-dark-accent)}.MapToolButton:not(.active):hover{box-shadow:0 2px 8px rgba(0,0,0,.45);background:var(--color-dark-background-light)}.WindowBox{pointer-events:all;max-height:calc(100vh - 30px);max-width:calc(100vw - 30px)}.WindowBox.--shaded .WindowBox_Body{box-shadow:0 0 30px rgba(0,0,0,.3)}.WindowBox_Header{display:flex;position:relative;align-items:center}.WindowBox_Header-title{flex-grow:1;text-align:left;background:var(--color-background);color:var(--color-foreground);height:50px;border-radius:10px 10px 0 0;font-size:1.4rem;line-height:1.4rem;padding:0 20px;display:flex;align-items:center;justify-content:flex-start;overflow:hidden}.WindowBox_Header-title .-square{color:var(--color-foreground-light);margin-left:50px}.WindowBox_Header-titleText{width:100%}.WindowBox_Header-close{height:50px;width:50px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0 100%;background-size:10px 10px;background-repeat:no-repeat;display:flex;align-items:flex-start;justify-content:flex-end}.WindowBox_Body{background:var(--color-background);border-radius:0 10px 10px 10px;padding:20px;max-height:calc(100vh - 80px);max-width:calc(100vw - 30px);overflow:auto}.WindowBox_Body.compact{padding:5px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
