<template lang="pug" src="./MapRastersTool.pug"/>
<style lang="scss" src="./MapRastersTool.scss"/>

<script lang="ts">
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from 'vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { useMap } from '@/composables/useMap';
import { DropDownContentType } from '@/constants/types/DropDownContentType';
import { RasterTypeEnum } from '@/constants/enums/RasterTypeEnum';
import AdminService from '@/modules/admin/AdminService';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import MapDropDownMenu from '@/components/shared/MapDropDownMenu/MapDropDownMenu.vue';
import MapRastersList from '@/modules/map/MapRastersList';
import StructList from '@/modules/struct/StructList';
import { useStorage } from '@/composables/useStorage';
import StorageService from '@/services/storage/StorageService';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { MapModel } from '@/models/map/MapModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { RasterModel } from '@/models/RasterModel';

type rasterContent = {
  title: string,
  id: number,
  active: boolean,
  order: number,
  properties: {
    zIndex: number,
    startOrder: number,
  }
}

export default defineComponent({
  name: 'MapRastersTool',
  props: {
    map: {
      type: Object as PropType<UnwrapRef<MapModel>>,
      required: true,
    },
    popupContainer: {
      type: String,
      required: true,
    },
    padding: {
      type: Number,
    },
  },
  components: {
    UiIcon,
    MapDropDownMenu,
  },

  setup(props) {
    const activeMenu = ref(false);
    const map = reactive(props.map);
    const { paddings } = useMapLayout();
    const { getRasterOrder } = useStorage();

    const activeRasters = computed(() => (map.getLayers(MapLayerTypeEnum.RASTER_FILE) || []));

    const { rasterLayerActive } = useMap();

    const _content = ref<DropDownContentType[]>([]);

    const content = computed({
      get: () => _content.value,
      set: (k) => {
        _content.value = k;
      },
    });

    const paddingsLeft = computed(() => (props.padding !== undefined ? props.padding : paddings.value.left));

    const getRasters = computed(() => [...MapRastersList.rasters.value].reverse());

    onMounted(() => {
      AdminService.fetchRasters();
    });
    EventBus.$on(EventsEnum.SelectStructUnit, async () => {
      await (AdminService.fetchRasters());
    });
    const order = ref(1);

    const calculateContent = () => {
      const arr1 = ref([...getRasterOrder.value]);
      const zIndexArr = arr1.value.map((ax, index) => index);
      let minZIndex = Math.min(...zIndexArr);

      arr1.value.forEach((r) => {
        MapRastersList.rasters.value.forEach((cr, index) => {
          if (cr.uuid === r) {
            MapRastersList.rasters.value[index].zIndex = minZIndex;
            minZIndex++;
          }
        });
      });

      MapRastersList.rasters.value.sort((ac, b) => ac.zIndex - b.zIndex);

      const arr: DropDownContentType[] = [];
      order.value = 1;
      getRasters.value.forEach((r) => {
        if (r.raster_type === RasterTypeEnum.user) {
          arr.push({
            title: r.alias,
            id: r.id,
            active: false,
            order: order.value,
            properties: {
              zIndex: r.zIndex,
              startOrder: order.value,
            },
          });
          order.value++;
        }
      });

      return arr;
    };

    watch(getRasterOrder, (a) => {
      //
    });

    watch(MapRastersList.rasters, () => {
      content.value = calculateContent();
    });
    const sortContentAfterDrag = (contentAfterDrag: rasterContent[]) => {
      const arr = ref([...contentAfterDrag].reverse());
      const zIndexArr = arr.value.map((a) => a.properties.zIndex);
      let minZIndex = Math.min(...zIndexArr);

      arr.value.forEach((r) => {
        MapRastersList.rasters.value.forEach((cr, index) => {
          if (cr.id === r.id) {
            MapRastersList.rasters.value[index].zIndex = minZIndex;
            minZIndex++;
          }
        });
      });

      MapRastersList.rasters.value.sort((a, b) => a.zIndex - b.zIndex);

      return arr;
    };

    watch(content, (a, b) => {
      if (a.length && b.length && a.length === b.length) {
        a.some((x, i) => {
          const raster = MapRastersList.rasters.value.find((r) => r.id === x.id);

          if (x.properties?.startOrder as number !== x.order) {
            sortContentAfterDrag(a as rasterContent[]);
            const acc: any[] = [];
            order.value = 1;
            getRasters.value.forEach((r) => {
              if (r.raster_type === RasterTypeEnum.user) {
                acc.push({
                  title: r.alias,
                  id: r.id,
                  active: a.find((x2) => x2.id === r.id)?.active,
                  order: order.value,
                  properties: {
                    zIndex: r.zIndex,
                    startOrder: order.value,
                  },
                });
              }
            });
            content.value = acc;
            // MapList.redrawRasters(map.mapContainer);
            return true;
          }
          if (x.active !== b[i].active) {
            if (raster) {
              if (map.getLayer(raster.uuid)) {
                map.removeLayer(raster.uuid);
              } else {
                map.render(raster as RasterModel);
              }
              raster.active = !raster.active;
              return true;
            }
          }
          return false;
        });
        getRasterOrder.value.length === 0
          ? StorageService.setItem(StorageKeyEnum.RASTER_ORDER, MapRastersList.rasters.value.map((r) => r.uuid))
          : StorageService.updateItem(StorageKeyEnum.RASTER_ORDER, MapRastersList.rasters.value.map((r) => r.uuid));
      }
    });

    watch(StructList.activeStruct, () => {
      // MapList.removeAllRaster(map.mapContainer);
      content.value = [];
    });
    onMounted(() => {
    //
    });
    return {
      AdminService,
      activeMenu,
      getRasters,
      rasterLayerActive,
      RasterTypeEnum,
      paddingsLeft,
      content,
    };
  },
});
</script>
