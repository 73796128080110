<template lang="pug" src="./WorkMapContent.pug"/>
<style lang="scss" src="./WorkMapContent.scss"/>

<script lang="ts">
import {
  defineComponent, onMounted, onUnmounted, ref, watch,
} from 'vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import FieldsList from '@/modules/fields/FieldsList';
import FieldCard from '@/components/shared/FieldCard/FieldCard.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useTaskMap } from '@/modules/taskMap/composables/useTaskMap';
import DialogBox from '@/components/shared/DialogBox/DialogBox.vue';
import { formatRuDate } from '@/utils/formatRuDate';
import { FieldModel } from '@/models/field/FieldModel';
import EventBus from '@/services/eventBus/EventBus';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import StructList from '@/modules/struct/StructList';
import TaskMapSelector from '@/components/shared/TaskMapSelector/TaskMapSelector.vue';
import PermissionsList from '@/modules/permissions/PermissionsList';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { useRoute } from 'vue-router';
import Content from '@/components/shared/Content/Content.vue';
import TaskMapNormaFormBlock
  from '@/modules/taskMap/ui/TaskMapNormaFormBlock/TaskMapNormaFormBlock.vue';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import TaskMapZoneFormBlock
  from '@/modules/taskMap/ui/TaskMapZoneFormBlock/TaskMapZoneFormBlock.vue';
import { TaskCollection } from '@/collection/TaskCollection';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { differenceArray } from '@/utils/differenceArray';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';

export default defineComponent({
  name: 'WorkMapContent',
  components: {
    DialogBox,
    UiIcon,
    RightPanel,
    FieldCard,
    TaskMapSelector,
    Content,
    TaskMapNormaFormBlock,
    TaskMapZoneFormBlock,
  },
  setup() {
    const {
      activeModeCreateTask,
      selectBaseTaskMaps,
    } = useTaskMap();

    const route = useRoute();

    const {
      mapModel,
      fields,
      setActiveField,
      selectedFields,
      addDisabledField,
      setSelectState,
      cleanSelectedFields,
      cleanDisabledFields,
      disabledFields,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const activeInfo = ref(false);
    const isOpenDrawer = ref(false);
    const activeDrawerFieldId = ref(0);
    const showModal = ref(false);
    const fieldDrawer = ref<FieldModel>();
    const fieldLayer = ref<MapLayerFieldsModel>();

    const openDrawer = (id: number) => {
      activeDrawerFieldId.value = id;
      fieldDrawer.value = FieldsList.getFieldModel(id);
      isOpenDrawer.value = true;
    };

    const openModel = () => {
      showModal.value = true;
    };

    watch(selectBaseTaskMaps, (a, b) => {
      const tasks = new TaskCollection(a as FieldTaskMapBaseModel[]);
          mapModel.value?.render(tasks);
    }, { deep: true });

    const disabled = () => {
      fields.value.forEach((f) => {
        if (f.baseTaskMaps.length === 0 || !PermissionsList.hasPermission('worktaskmap', 'add', f.id)) {
          addDisabledField(f as FieldModel);
          mapModel.value.map?.setFeatureState(
            { source: fieldLayer.value.sourceId, id: f.id },
            { block: true },
          );
          EventBus.$emit('taskNull', { id: f.id, value: true });
        }
      });
    };
    const prevArray = ref<FieldModel[]>([]);
    watch(selectedFields, (a) => {
      const removeList = differenceArray(prevArray.value, a);
      const appendList = differenceArray(a, prevArray.value);
      appendList.forEach((a1) => {
        selectBaseTaskMaps.value.push(a1.baseTaskMaps[0]);
      });

      removeList.forEach((b1) => {
        const index = prevArray.value.indexOf(b1);
        selectBaseTaskMaps.value.splice(index, 1);
      });

      prevArray.value = [...a];
    }, { deep: true });

    const fetchData = () => {
      if (StructList.activeStruct.value?.id) {
        FieldsList.fetchAllBaseTask(StructList.activeStruct.value?.id);
        LoadingStatus.awaitLoad(LoadingNamesEnum.ALL_BASE_TASK).then(() => {
          setTimeout(() => {
            disabled();
          });
        });
      }
    };

    const removeFields = () => {
      // selectedFields.value.forEach((a) => {
      //   // a.baseTaskMaps.forEach((b) => b.setSelected(false));
      // });
    };

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      fetchData();
      setActiveField();
      setSelectState('multiple');
      fieldLayer.value = mapModel.value.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
    });

    onUnmounted(() => {
      setSelectState('single');
      removeFields();
      cleanSelectedFields();
      activeModeCreateTask.value = 0;
      mapModel.value?.removeLayer(MapLayerTypeEnum.TASK_MAP_COLLECTION);
      disabledFields.value.forEach((f) => {
        mapModel.value.map?.setFeatureState(
          { source: fieldLayer.value.sourceId, id: f.id },
          { block: false },
        );
      });
      cleanDisabledFields();
    });

    return {
      selectedFields,
      mapModel,
      isOpenDrawer,
      openDrawer,
      activeModeCreateTask,
      showModal,
      openModel,
      activeInfo,
      formatRuDate,
      fieldDrawer,
      route,
      selectBaseTaskMaps,
      MapContainerEnum,
    };
  },
});
</script>
