import { LngLat, Map, MapMouseEvent } from 'mapbox-gl';
import { ref } from 'vue';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';

const activeTool = ref('pointer');

const scaleIncrement = 10;

const focused = ref(0);

const imageDataZoned = ref<Uint8ClampedArray>();

export const useBaseMapEditor = () => {
  const eventsSubscription = (map: Map, sourceFrom: string) => {
    map.on('rotate', (evt: MapMouseEvent) => {
      if (!('crossAction' in evt)) {
        BaseMapEvents.emitMapRotate(map.getBearing(), sourceFrom);
      }
    });

    BaseMapEvents.onMapRotate((bearing: number, from: string) => {
      if (bearing && from && from !== sourceFrom) {
        map.setBearing(bearing, { crossAction: true });
      }
    });

    map.on('zoom', (evt: MapMouseEvent) => {
      if (!('crossAction' in evt)) {
        BaseMapEvents.emitMapZoom(map.getZoom(), sourceFrom);
      }
    });

    BaseMapEvents.onMapZoom((zoom: number, from: string) => {
      if (zoom && from && from !== sourceFrom) {
        map.setZoom(zoom, { crossAction: true });
      }
    });

    map.on('move', (evt: MapMouseEvent) => {
      if (!('crossAction' in evt)) {
        BaseMapEvents.emitMapMove(map.getCenter(), sourceFrom);
      }
    });

    BaseMapEvents.onMapMove((coords: LngLat, from: string) => {
      if (coords && from && from !== sourceFrom) {
        map.setCenter(coords, { crossAction: true });
      }
    });
  };

  return {
    activeTool,
    scaleIncrement,
    imageDataZoned,
    eventsSubscription,
    focused,
  };
};
