import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "MapHistoryMenu"
}
const _hoisted_2 = { class: "MapHistoryMenu-header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "MapHistoryMenu-short" }
const _hoisted_5 = { class: "MapHistoryMenu-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_IndexesInfo = _resolveComponent("IndexesInfo")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_NotFound = _resolveComponent("NotFound")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "right-start",
    width: 320,
    trigger: _ctx.activeField ? 'hover' : 'focus',
    teleported: false,
    "hide-after": 0
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["MapContainer-Button", { active: ['msf', 'rsf', 'rvi'].includes(_ctx.active), disabled: !_ctx.activeField }])
      }, [
        _createVNode(_component_ui_icon, {
          name: "mdiAccountHardHatOutline",
          size: 24
        })
      ], 2)
    ]),
    default: _withCtx(() => [
      (_ctx.activeField)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('history-index-title')), 1),
            (_ctx.activeField?.historyIndexes.length > 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.DictionaryColorsKitList.colorKits.value.filter((c) => c.properties.is_history_index), (colorKit) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (_ctx.checkIndex(<DictionaryColorsKitModel>colorKit))
                      ? (_openBlock(), _createBlock(_component_el_popover, {
                          key: 0,
                          placement: "right-start",
                          width: 320,
                          trigger: "hover",
                          "hide-after": 0
                        }, {
                          reference: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(["MapHistoryMenu-row", { active: _ctx.activeColorKit === colorKit.key, disabled: !_ctx.isIndexAvailable(<DictionaryColorsKitModel>colorKit) }]),
                              onClick: ($event: any) => (_ctx.showIndex(<DictionaryColorsKitModel>colorKit))
                            }, [
                              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`index-info-short-${colorKit.key}`, { ns:'monitoring' })), 1),
                              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(`index-info-full-${colorKit.key}`, { ns:'monitoring' })), 1)
                            ], 10, _hoisted_3)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_IndexesInfo, {
                              index: colorKit.key,
                              effect: 'light'
                            }, null, 8, ["index"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 256))
              : (_openBlock(), _createBlock(_component_NotFound, {
                  key: 1,
                  message: _ctx.$t('not-found-history-index',{ns:'mapbox'})
                }, null, 8, ["message"]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["trigger"]))
}