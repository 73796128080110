<template  lang="pug" src="./BaseMapContent.pug"/>
<style  lang="scss" src="./BaseMapContent.scss"/> >

<script  lang="ts">

import {
  defineComponent, onMounted, onUnmounted, watch,
} from 'vue';
import Content from '@/components/shared/Content/Content.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { TaskCandidateModel } from '@/models/taskMap/TaskCandidateModel';
import { useCreateBaseMapModal } from '@/composables/baseMap/create/useCreateBaseMapModal';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { ElMessageBox } from 'element-plus';
import CandidateSelector
  from '@/pages/task-map/create/base-map/ui/CreateBaseMapModal/CandidateSelector/CandidateSelector.vue';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';
import BaseMapEdit
  from '@/pages/task-map/create/base-map/ui/CreateBaseMapModal/BaseMapEdit/BaseMapEdit.vue';
import BaseMapPreview
  from '@/pages/task-map/create/base-map/ui/CreateBaseMapModal/BaseMapPreview/BaseMapPreview.vue';
import BaseMapEditor
  from '@/pages/task-map/create/base-map/ui/CreateBaseMapModal/BaseMapEditor/BaseMapEditor.vue';

export default defineComponent({
  name: 'BaseMapContent',
  components: {
    Content,
    CandidateSelector,
    BaseMapEdit,
    BaseMapPreview,
    BaseMapEditor,
  },
  setup() {
    const {
      activeField,
      mapModel,
      compareMode,
      fitField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const {
      candidates,
      activeCandidate,
      activeMode,
      canvasPreview,
      canvasPreviewCtx,
      previewLayer,
      generalizationSize,
      postBaseMap,
      setActiveMode,
    } = useCreateBaseMapModal();

    const fetch = async (fieldId: number | undefined) => {
      await candidates.value.fetchCandidates();

      const candidate = candidates.value.candidates.find((c) => c.fieldId === fieldId && c.isActive);
      if (candidate) {
        activeCandidate.value = candidate as TaskCandidateModel;
      }
    };

    watch(activeField, () => {
      if (activeField.value?.id) {
        fetch(activeField.value.id);
      }
    });

    const createCanvas = () => {
      const canvasTypes = [
        {
          id: 'CreateBaseMapCanvas-preview',
          container: `${MapContainerEnum.MAIN_MAP}`,
          refs: {
            canvas: canvasPreview,
            ctx: canvasPreviewCtx,
            layer: previewLayer,
          },
          map: mapModel,
        },
      ];

      canvasTypes.forEach(({
        id, container, refs, map,
      }) => {
        const canvas = document.createElement('canvas');
        canvas.id = id;
        document.body.querySelector(`#${container}`)?.appendChild(canvas);

        const elementCanvas = new MapCanvasModel(id, [
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
        ], true);

        const layer = map.value?.render(elementCanvas) as MapLayerCanvasModel;
        refs.canvas.value = elementCanvas.canvas;
        refs.ctx.value = elementCanvas.ctx;
        refs.layer.value = layer;
      });
    };

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      createCanvas();
      if (activeField.value !== undefined) {
        fetch(activeField.value.id);
      }
    });
    const create = () => {
      if (activeCandidate.value) {
        postBaseMap();
        activeField.value?.fetchTaskMaps();
      }
    };

    const savePaint = () => {
      ElMessageBox.confirm(
        'Изменения в редакторе сохранятся на шаблоне карты. Все данные вне контура поля будут обрезаны после создания шаблона.',
        'Сохранить изменения?',
        {
          confirmButtonText: 'Сохранить изменения',
          confirmButtonClass: 'success',
          cancelButtonText: 'Отмена',
          type: 'warning',
        },
      ).then(() => {
        activeCandidate.value && (activeCandidate.value.isEdited = true);
        BaseMapEvents.emitEditSave();
        setActiveMode('form');
        compareMode.value = 'single';
      }).catch(() => {
        // do nothing
      });
    };
    const canselPaint = () => {
      ElMessageBox.confirm(
        'Изменения в редакторе не будут сохранены, и вы сможете продолжить редактировать зоны шаблона карты.',
        'Отменить изменения?',
        {
          confirmButtonText: 'Отменить изменения',
          confirmButtonClass: 'default',
          cancelButtonText: 'Отмена',
          type: 'warning',
        },
      ).then(() => {
        setActiveMode('form');
        compareMode.value = 'single';
      }).catch(() => {
        // do nothing
      });
    };

    onUnmounted(() => {
      BaseMapEvents.onGeneralization(() => {
        activeCandidate.value?.taskImage?.generalization(generalizationSize.value);
      });
    });

    onUnmounted(() => {
      if (previewLayer.value?.uuid) {
        mapModel.value?.removeLayer(previewLayer.value?.uuid);
      }
      compareMode.value = 'single';
      activeCandidate.value?.reset();
      setActiveMode('selector');
      activeCandidate.value = undefined;
    });

    return {
      activeField,
      activeMode,
      setActiveMode,
      create,
      savePaint,
      canselPaint,
      generalizationSize,
    };
  },
});
</script>
