import {
  GeoJSONSource,
} from 'mapbox-gl';
import {
  POI_LABEL_LAYER_ID,
  POI_LABEL_SOURCE,
  POI_LAYER_ID,
  POI_SOURCE,
} from '@/constants/constants/mapbox';
import { computed, ref, watch } from 'vue';
import { PoiModel } from '@/models/poi/PoiModel';
import { Feature, FeatureCollection } from 'geojson';
import PoisList from '@/modules/poi/PoisList';
import { useCreateBaseMapPreview } from '@/composables/baseMap/create/useCreateBaseMapPreview';
import { useTranslate } from '@tolgee/vue';

const activePois = ref<PoiModel[]>([]);

const showPoiPanel = ref(false);

const showPoiGroups = ref<number[]>([]);

const showPoiName = ref(true);

const showPoiLabel = ref(true);

const activeFilter = ref(true);

const activeGroup = ref(0);

const searchRow = ref('');

const activeLabels = ref<string[]>([]);

const filterLabels = ref<string[]>([]);

export const useCreateBaseMapPreviewPoi = () => {
  const { t } = useTranslate('poi');

  const groupsPois = computed(() => [...PoisList.pois.value]
    .filter((v) => showPoiGroups.value.includes(Number(v.group))));

  const isFilterActive = computed(() => filterLabels.value.length > 0 && activeFilter.value);

  const filteredPois = computed(() => groupsPois.value
    .filter((v) => (isFilterActive.value ? Object.keys(v.params).some((key) => filterLabels.value.includes(key)) : true)));

  const calculatePois = () => ({
    type: 'FeatureCollection',
    features: filteredPois.value.map((poi) => ({
      id: poi.id,
      type: 'Feature',
      geometry: poi.geometry,
    } as Feature)) || [],
  } as FeatureCollection);

  const calculatePoiLabels = () => ({
    type: 'FeatureCollection',
    features: filteredPois.value.map((poi) => {
      const labelWords = [];
      if (showPoiName.value) {
        labelWords.push(poi.name);
      }

      activeLabels.value.forEach((key) => {
        if (poi.params[key]) {
          const labelName = t.value(`${key}`, key);

          labelWords.push(`${showPoiLabel.value ? `${labelName}: ` : ''}${poi.params[key]}`);
        }
      });

      return {
        id: poi.id,
        type: 'Feature',
        geometry: poi.geometry,
        properties: {
          label: labelWords.join('\n'),
        },
      } as Feature;
    }) || [],
  } as FeatureCollection);

  const redrawPois = () => {
    const { map } = useCreateBaseMapPreview();
    if (map.value) {
      (map.value.getSource(POI_SOURCE) as GeoJSONSource).setData(calculatePois());
      (map.value.getSource(POI_LABEL_SOURCE) as GeoJSONSource).setData(calculatePoiLabels());
    }
  };

  const redrawPoiLabels = () => {
    const { map } = useCreateBaseMapPreview();
    if (map.value) {
      (map.value.getSource(POI_LABEL_SOURCE) as GeoJSONSource).setData(calculatePoiLabels());
    }
  };

  const poiMapSourcesAndLayers = () => {
    const { map } = useCreateBaseMapPreview();
    if (map.value) {
      map.value.addSource(POI_SOURCE, {
        type: 'geojson',
        data: calculatePois(),
      });
      map.value.addSource(POI_LABEL_SOURCE, {
        type: 'geojson',
        data: calculatePoiLabels(),
      });

      map.value.addLayer({
        id: POI_LABEL_LAYER_ID,
        type: 'symbol',
        source: POI_LABEL_SOURCE,
        layout: {
          'text-field': ['get', 'label'],
          'text-radial-offset': 0.5,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-size': [
            'interpolate', ['linear'], ['zoom'],
            9, 0,
            10, 5,
            11, 10,
            12, 13,
            20, 14,
          ],
          'text-justify': 'center',
          // @ts-ignore
          'text-font': [
            'literal',
            ['Inter Regular'],
          ],
        },
        paint: {
          'text-color': '#25282B',
          'text-halo-color': 'rgba(255,255,255,0.6)',
          'text-halo-width': 2,
        },
      });

      map.value.addLayer({
        id: POI_LAYER_ID,
        type: 'circle',
        source: POI_SOURCE,
        paint: {
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            7, 1,
            11, 2,
            12, 4,
            15, 5,
          ],
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false], '#45aaf2',
            ['boolean', ['get', 'visited'], true], '#B25900',
            '#006C67',
          ],
          'circle-stroke-width': [
            'interpolate', ['linear'], ['zoom'],
            7, 1,
            11, 1,
            12, 2,
            15, 2,
          ],
          'circle-stroke-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false], '#45aaf2',
            ['boolean', ['feature-state', 'hover'], false], '#ffaf7a',
            '#FFEBC6',
          ],
        },
      });

      map.value.on('mouseenter', POI_LAYER_ID, () => {
        if (map.value) {
          map.value.getCanvas().style.cursor = 'pointer';
        }
      });
    }
  };

  return {
    poiMapSourcesAndLayers,
    redrawPois,
    redrawPoiLabels,
    groupsPois,
    filteredPois,
    showPoiPanel,
    showPoiGroups,
    showPoiName,
    showPoiLabel,
    searchRow,
    activePois,
    activeGroup,
    activeLabels,
    activeFilter,
    filterLabels,
  };
};
