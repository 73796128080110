export enum EventsEnum {
  SocketMessage,
  MapboxElementReady,
  TokenRenewRequire,
  TokenRenewComplete,
  UserLogout,
  UserLogin,
  UserLoaded,
  UserDoLogout,
  OnKeyPressedEscape,
  OnKeyPressedEnter,
  OnKeyPressed,
  MapLoaded,
  MapOnClick,
  MapLayoutChanged,
  MapBackingChanged,
  MapToggleRuler,
  MapRedrawPois,
  MapRedrawFields,
  SelectStructUnit,
  MapToggleLayerVisibility,
  MapToggleRasterVisibility,
  MapOnPolygonDraw,
  MapPolygonDrawStart,
  ModalBackgroundClicked,
  MapPolygonDrawStop,
  ModalWrapperOnClick,
  MapFeaturesUpdated,
  HoverField,
  MapPoisUpdated,
  MapCompactAddPoi,
  MapMouseMove,
  MapHideFilling,
  Map3DViewToggle,
  BlockClosed,
  BlockRendered,
  CreatePOI,
  CreatePOIEnded,
  ToolMenuOpened,
  FieldToggleActivate,
  MapFeatureEdit,
  MapRedrawVector,
  MapEditOutline,
  MapToggleVectorVisibility,
  PoiToggleActivePoi,
  ProgressBarValue,
  MapClearVector,
  UpdateRaster,
  BlockReRender,
  ServerTime,
  UpdateStorage,
  StorageLoaded,
  MapToggleIndex,
  MapToggleMonitoringIndex,
  CompareMapToggleIndex,
  CompareMapToggleRgb,
  ViewTaskMap,
  SetSelectedTaskMap,
  ContextMenu,
}
