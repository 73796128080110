import { DictionaryCropRecordModel } from '@/models/dictionary/DictionaryCropRecordModel';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import ApiService from '@/services/api/ApiService';
import LoggerService from '@/services/logger/LoggerService';
import { Ref, ref, UnwrapRef } from 'vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';

class DictionaryList {
  get crop(): Ref<UnwrapRef<DictionaryCropRecordModel[]>> {
    return this._crop;
  }

  get loading(): Ref<UnwrapRef<boolean>> {
    return this._loading;
  }

  private _loading = ref(false);

  private _crop = ref<DictionaryCropRecordModel[]>([]);

  private farmunit;

  constructor() {
    this.farmunit = Number(window.localStorage.getItem('structId')) || 0;
    this.fetchRecords();

    EventBus.$on(EventsEnum.SelectStructUnit, (farmunit: number) => {
      this.farmunit = farmunit;
      this.fetchRecords();
    });
  }

  async saveCropOrders() {
    let order = 1;
    const patchData: {id: number, order: number}[] = [];
    this._crop.value
      .sort((a, b) => a.order - b.order)
      .forEach((record) => {
        patchData.push({
          id: record.id,
          order: order++,
        });
      });
    await ApiService.dictionary.patchCropRecords(
      this.farmunit,
      patchData,
    );
    this._crop.value.forEach((record) => {
      record.order = (patchData.find((pd) => pd.id === record.id))?.order || 0;
    });
  }

  async pushRecordCrop(value: {
    name: string,
    order: number,
    color?: string,
  }) {
    this._crop.value.forEach((record) => {
      if (record.order >= value.order) {
        record.order += 1;
      }
    });
    const { data } = await ApiService.dictionary.postCropRecord(this.farmunit, value);
    this._crop.value.push(new DictionaryCropRecordModel(data));
    await this.saveCropOrders();
    await this.fetchRecords();
  }

  async deleteRecordCrop(record: DictionaryCropRecordModel) {
    this._crop.value.splice(this._crop.value.indexOf(record), 1);
    await ApiService.dictionary.deleteCropRecord(this.farmunit, record.id);
    await this.saveCropOrders();
    await this.fetchRecords();
  }

  async fetchRecords() {
    await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
    this._loading.value = true;
    try {
      const { data } = await ApiService.dictionary.getRecords();
      this._crop.value = [];
      data.crop_catalog.items.forEach((dto) => {
        this._crop.value.push(new DictionaryCropRecordModel(dto));
      });
    } catch (e) {
      LoggerService.error(e);
    }
    this._loading.value = false;
  }
}

export default new DictionaryList();
