/* eslint-disable camelcase */
import { useAuth } from '@/composables/useAuth';
import { RasterTypeEnum } from '@/constants/enums/RasterTypeEnum';
import { FileModel } from '@/models/file/FileModel';
import type { MapModel } from '@/models/map/MapModel';
import { RastersDto } from '@/services/api/dto/gis/RastesDto';

export class RasterModel extends FileModel {
  get scope(): string {
    return this._scope;
  }

  get preview(): string {
    return this._preview;
  }

  get zIndex(): number {
    return this._zIndex;
  }

  set zIndex(value: number) {
    this._zIndex = value;
  }

  get uuidServer() {
    return this._uuidServer;
  }

  get id() {
    return this._id;
  }

  get update_date() {
    return this._updateDate;
  }

  get colorizer_name() {
    return this._colorizerName;
  }

  get raster_type() {
    return this._rasterType;
  }

  get alias() {
    return this._alias;
  }

  get hover() {
    return this._hover;
  }

  set hover(v) {
    this._hover = v;
  }

  get active() {
    return this._active;
  }

  set active(v) {
    this._active = v;
  }

  get source() {
    return this._source;
  }

  get source_number() {
    return this._sourceNumber;
  }

  get bound() {
    return this._bound;
  }

  get colorizer() {
    return this._colorizer;
  }

  get farmunit() {
    return this._farmunit;
  }

  get path() {
    return this._path;
  }

  get layername() {
    return this._layername;
  }

  private _zIndex = 0;

  private _uuidServer: string;

  private _id: number;

  private _sourceNumber: number;

  private _updateDate: string;

  private _colorizerName: string;

  private _rasterType: RasterTypeEnum;

  private _alias: string;

  private _colorizer;

  private _source: string;

  private _hover = false;

  private _active = false;

  private _bound;

  private _farmunit;

  private _path;

  private _layername;

  private _preview: string;

  private _scope: string

  constructor(dto: RastersDto) {
    super(dto.update_date);
    this._sourceNumber = dto.source_number;
    this._alias = dto.alias;
    this._colorizer = dto.colorizer;
    this._colorizerName = dto.colorizer_name;
    this._rasterType = dto.raster_type;
    this._updateDate = dto.update_date;
    this._id = dto.id;
    this._uuidServer = dto.uuid;
    this._source = dto.source;
    this._bound = dto.bound;
    this._farmunit = dto.farmunit;
    this._path = dto.path;
    this._layername = dto.layername;
    this._scope = dto.scope;
    this._preview = `${process.env.VUE_APP_BASE_URL}/api/v1/gis/export_raster/?format=png&source=${this._source}&access_token=${useAuth().accessToken.value}&width=50&height=50&srs=epsg:3857&solt=${Date.now()}`;
  }

  update(dto: RastersDto) {
    this._sourceNumber = dto.source_number;
    this._alias = dto.alias;
    this._colorizer = dto.colorizer;
    this._colorizerName = dto.colorizer_name;
    this._rasterType = dto.raster_type;
    this._updateDate = dto.update_date;
    this._id = dto.id;
    this._uuidServer = dto.uuid;
    this._source = dto.source;
    this._bound = dto.bound;
    this._farmunit = dto.farmunit;
    this._path = dto.path;
    this._layername = dto.layername;
  }

  setActive(v: boolean) {
    this._active = v;
  }

  async render(map: MapModel, treeKey: string): Promise<boolean> {
    map.render(this, { treeKey });
    return true;
  }
}
