<template src="./Content.pug" lang="pug"/>
<style src="./Content.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, Slots, useSlots,
} from 'vue';
import { useRouter } from 'vue-router';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useMapLayout } from '@/composables/useMapLayout';

export default defineComponent({
  name: 'Content',
  components: { UiIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const slots = useSlots();
    const {
      showContent,
    } = useMapLayout();
    const router = useRouter();

    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: Slots[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type?.toString() !== 'Symbol(Comment)').length > 0;
    };

    const onClose = () => {
      router.push({
        name: 'struct',
      });
    };

    return {
      hasSlot,
      onClose,
      showContent,
    };
  },
});
</script>
