import { MonitoringIndexKindType } from '@/constants/types/monitoring/MonitoringIndexKindType';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MonitoringFilesList } from '@/models/monitoring/MonitoringFilesList';
import { MonitoringIndexesList } from '@/models/monitoring/MonitoringIndexesList';
import StructList from '@/modules/struct/StructList';
import { TaskMapIndexOverlapDto } from '@/services/api/dto/taskMap/TaskMapIndexOverlapDto';
import { computed, ref } from 'vue';

const _activeFile = ref<FieldNirModel>();

const activeDate = ref<Date>(new Date('01-01-1971'));

const _indexesFiles = ref<MonitoringFilesList>(new MonitoringFilesList()); // key - fieldId

const _indexesKinds = ref<Record<number, MonitoringIndexesList>>({}); // key - structId

const _showRgb = ref(true);

const _showContrast = ref(false);

const loading = ref(false);

const _showChart = ref<boolean>(false);

const _activeHRS = ref(false);

const selectedIndex = ref<MonitoringIndexKindType | undefined>();

const selectedMask = ref< MonitoringIndexKindType | undefined >();

const overlap = ref<TaskMapIndexOverlapDto>();

const _selectedWorkTask = ref<FieldTaskMapWorkModel>();

const _products = ref<{name: string, rate: number, type: string, unit: string, show: boolean}[]>([]);

const _showCloud = ref(true);

export const useMonitoring = () => {
  const showCloud = computed({
    get: () => _showCloud.value,
    set: (s) => {
      _showCloud.value = s;
    },
  });

  const activeFile = computed({
    get: () => _activeFile.value,
    set: (v) => {
      if (v) {
        activeDate.value = v.scene.datetime;
      }
      _activeFile.value = v;
    },
  });

  const indexesFiles = computed(() => _indexesFiles.value);

  const showRgb = computed({
    get: () => _showRgb.value,
    set: (v: boolean) => {
      _showRgb.value = v;
    },
  });

  const showContrast = computed({
    get: () => _showContrast.value,
    set: (v: boolean) => {
      _showContrast.value = v;
    },
  });

  const indexesKinds = computed({
    get: (): MonitoringIndexesList => {
      const structId = Number(window.sessionStorage.getItem('structId')) || 0;
      return _indexesKinds.value[structId];
    },
    set: (v: MonitoringIndexesList) => {
      const structId = Number(window.sessionStorage.getItem('structId')) || 0;
      _indexesKinds.value[structId] = v;
    },
  });

  const showChart = computed({
    get: () => _showChart.value,
    set: (v) => {
      _showChart.value = v;
    },
  });
  const activeHRS = computed({
    get: () => _activeHRS.value,
    set: (v) => {
      _activeHRS.value = v;
    },
  });

  const products = computed(() => _products.value);

  const selectedWorkTask = computed({
    get: () => _selectedWorkTask.value,
    set: (v) => {
      _selectedWorkTask.value = v;
      setTimeout(() => {
        if ((_selectedWorkTask.value?.geojson?.features?.length || 0) > 0 && _selectedWorkTask.value?.geojson?.features[0].properties?.prod) {
          _products.value = [];
          _selectedWorkTask.value.geojson.features[0].properties.prod.forEach((p: {name: string, rate: number, type: string, unit: string}) => {
            _products.value.push({
              name: p.name,
              rate: p.rate,
              type: p.type,
              unit: p.unit,
              show: true,
            });
          });
        }
      }, 200);
    },
  });

  const indexFile = computed<FieldIndexMonitoringModel | undefined>(() =>
    // if (selectedIndex.value && activeFile.value?.scene.id) {
    //   return useMapContainers().activeField.value?.monitoringIndexes.find((f: FieldIndexMonitoringModel) => f.kind === selectedIndex.value?.value && f.sceneId === activeFile.value?.scene.id);
    // }
    // eslint-disable-next-line implicit-arrow-linebreak
    undefined);

  const maskFile = computed<FieldIndexMonitoringModel | undefined>(() =>
    // if (selectedMask.value && activeFile.value?.scene.id) {
    //   return useMapContainers().activeField.value?.monitoringIndexes.find((f: FieldIndexMonitoringModel) => f.kind === selectedMask.value?.value && f.sceneId === activeFile.value?.scene.id);
    // }
    // eslint-disable-next-line implicit-arrow-linebreak
    undefined);

  return {
    loading,
    activeDate,
    activeFile,
    indexesFiles,
    indexesKinds,
    showRgb,
    showContrast,
    showChart,
    activeHRS,
    indexFile,
    selectedIndex,
    overlap,
    products,
    selectedWorkTask,
    showCloud,
    maskFile,
    selectedMask,
  };
};
