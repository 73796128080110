export enum MapAnchorEnum {

  RASTER = 'raster',
  NIR_FILE = 'nir',
  HISTORY_INDEX_FILE = 'historyIndex',
  INDEX_FILE = 'index',
  TASK_MAP = 'task-map',
  TASK_MAP_EXPERIMENT = 'task-map-experiment',
  MASK_FILE = 'mask',
  TREE_IMAGES = 'tree-images',
  VECTOR = 'vector',
  FIELD_FILL = 'field-fill',
  FIELD_CONTOUR = 'field-contour',
  CROP_RATE = 'cropRate',
  STRUCT_VIEW = 'structView',
  POI = 'poi',
  POI_LABEL = 'poi-label',
  FIELD_LABEL = 'field-label',
  TASK_MAP_CONTOUR = 'task-map-contour',
  RULER = 'ruler',
  DRAWER = 'drawer',
  CURSOR = 'cursor',
}
