import Coordinates from '@/constants/types/mapbox/Coordinates';
import { Model } from '@/models/Model';

export class MapCanvasModel extends Model {
  get animate(): boolean {
    return this._animate;
  }

  get ctx(): CanvasRenderingContext2D | null {
    return this._ctx;
  }

  set ctx(value: CanvasRenderingContext2D | null) {
    this._ctx = value;
  }

  get canvas(): HTMLCanvasElement | undefined {
    return this._canvas;
  }

  set canvas(value: HTMLCanvasElement | undefined) {
    this._canvas = value;
  }

  get canvasId(): string {
    return this._canvasId;
  }

  get bbox(): Coordinates {
    return this._bbox;
  }

  set bbox(value: Coordinates) {
    this._bbox = value;
  }

  private _canvasId: string

  private _bbox: Coordinates

  private _canvas: HTMLCanvasElement | undefined;

  private _ctx: CanvasRenderingContext2D | null = null;

  private _animate: boolean

  /**
   * @param canvasId Id canvas
   * @param bboxCanvas Bbox canvas
   * @param animateCanvas нежен для тогочтобы на карте бновлялся canvas при изменении
   */
  constructor(canvasId: string, bboxCanvas: Coordinates, animateCanvas: boolean) {
    super();
    this._canvasId = canvasId;
    this._bbox = bboxCanvas;
    this._canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    this._ctx = this._canvas.getContext('2d');
    this._animate = animateCanvas;
  }
}
