import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MapContainer-Button" }

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_popover = _resolveComponent("el-popover")

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "right-start",
    width: 320,
    teleported: false,
    "hide-after": 0,
    trigger: "click",
    "popper-class": "popover-double"
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_icon, {
          name: "/images/icons/raster_layers.svg",
          size: 24
        })
      ])
    ]),
    _: 1
  }))
}