import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "SettingsPalettePanel" }
const _hoisted_2 = { class: "SettingsPalettePanel-header" }
const _hoisted_3 = { class: "SettingsPalettePanel-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('palette-name', { ns: 'palette' })), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_input, {
        modelValue: _ctx.node.label,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.node.label) = $event)),
        disabled: !_ctx.activeNameEdit,
        placeholder: "Наименование слоя"
      }, {
        append: _withCtx(() => [
          (_ctx.activeNameEdit)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                circle: "",
                onClick: _ctx.save,
                icon: _ctx.Check
              }, null, 8, ["onClick", "icon"]))
            : _createCommentVNode("", true),
          (!_ctx.activeNameEdit)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                circle: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeNameEdit = true)),
                icon: _ctx.Edit
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "disabled"]),
      _createVNode(_component_el_popconfirm, {
        title: _ctx.$t('palette-delete-confirm', { ns: 'palette' }),
        onConfirm: _ctx.deletePalette,
        "hide-icon": "",
        "confirm-button-type": 'danger',
        "confirm-button-text": _ctx.$t('delete'),
        width: 250
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_el_button, {
            circle: "",
            type: "danger",
            icon: _ctx.Delete
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["title", "onConfirm", "confirm-button-text"])
    ])
  ]))
}