import { useApp } from '@/composables/useApp';
import { useAuth } from '@/composables/useAuth';
import BillingService from '@/modules/billing/BillingService';
import FieldsList from '@/modules/fields/FieldsList';
import MonitoringService from '@/modules/monitoring/MonitoringService';
import StructFilesList from '@/modules/struct/StructFilesList';
import StructList from '@/modules/struct/StructList';
import AuthService from '@/services/auth/AuthService';
import StorageService from '@/services/storage/StorageService';
import StorageServiceStruct from '@/services/storage/StorageStructService';

class CoreLoaderService {
  async fetchData(): Promise<void> {
    useApp().setLoadingScreen('core-loader-service', 'Загрузка данных приложения');
    const _structId = window.localStorage.getItem('structId');
    if (_structId) {
      await AuthService.tryStorageLogin();
      if (useAuth().isAuth()) {
        await StructList.fetchStructs();
        await StructList.setActiveStruct(_structId);
        if (StructList.activeStruct.value?.id) {
          await Promise.allSettled([
            await StructList.activeStruct.value.fetchUnitDetails(),
            await FieldsList.fetchFields(StructList.activeStruct.value.id),
            await StructFilesList.fetchRasters(),
            await StructFilesList.fetchVectors(),
            await BillingService.fetchStructBilling(),
            await StorageService.fetchStorage(),
            await StorageServiceStruct.fetchStorage(),
            await MonitoringService.fetchKinds(),
          ]);
        }
      }
    }
    useApp().setLoadingScreen('core-loader-service');
  }
}

export default new CoreLoaderService();
