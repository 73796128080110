import { useAuth } from '@/composables/useAuth';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import UserService from '@/modules/user/UserService';
import { ApiResponse } from '@/services/api/ApiResponse';
import ApiService from '@/services/api/ApiService';
import { AuthTokenDto } from '@/services/api/dto/auth/AuthTokenDto';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';

class AuthService {
  private renewProcess = false;

  constructor() {
    EventBus.$on(EventsEnum.TokenRenewRequire, () => {
      if (!this.renewProcess) {
        this.renewProcess = true;
        this.renewToken().then(() => {
          EventBus.$emit(EventsEnum.TokenRenewComplete);
          this.renewProcess = false;
        });
      }
    });

    EventBus.$on(EventsEnum.UserDoLogout, async () => {
      this.renewProcess = true;
      await this.forceLogout();
      this.renewProcess = false;
    });
  }

  expiresDate(seconds: number) {
    return new Date(Date.now() + seconds * 1000);
  }

  async setLocalStorage(data: AuthTokenDto) {
    useAuth().setTokens({
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      expiresIn: this.expiresDate(data.expires_in),
    });

    await window.localStorage.setItem('accessToken', data.access_token);
    await window.localStorage.setItem('refreshToken', data.refresh_token);
    await window.localStorage.setItem('expiresIn', this.expiresDate(data.expires_in).toString());
  }

  async loadTokenData(response: ApiResponse<AuthTokenDto>) {
    if (response.status === 200) {
      await this.setLocalStorage(response.data);
      await UserService.loadUser();
    } else if (response.status === 400) {
      await window.localStorage.removeItem('accessToken');
      await window.localStorage.removeItem('refreshToken');
      await window.localStorage.removeItem('expiresIn');
    }
  }

  async authPassword(username: string, password: string) {
    try {
      const response = await ApiService.auth.getToken({
        grant_type: 'password',
        client_id: process.env.VUE_APP_ID || '',
        client_secret: process.env.VUE_APP_SECRET || '',
        password,
        username,
      });
      if (response.data) {
        if (username !== window.localStorage.getItem('UserEmail')) {
          await window.localStorage.removeItem('SelectedStructUnit');
        }
        await this.setLocalStorage(response.data);
        await this.loadTokenData(response);
      }
    } catch (e) {
      LoggerService.error(e);
    }
  }

  async forceLogout() {
    await ApiService.auth.logout({
      token: useAuth().accessToken.value,
      client_id: process.env.VUE_APP_ID || '',
      client_secret: process.env.VUE_APP_SECRET || '',
    });

    useAuth().revokeToken();

    await window.localStorage.removeItem('accessToken');
    await window.localStorage.removeItem('refreshToken');
    await window.localStorage.removeItem('expiresIn');

    EventBus.$emit(EventsEnum.UserLogout);
    EventBus.clearMapLayoutListeners();
    window.location.href = '/';
  }

  async renewToken() {
    const response = await ApiService.auth.refreshToken({
      refresh_token: useAuth().refreshToken.value,
      grant_type: 'refresh_token',
      client_id: process.env.VUE_APP_ID || '',
      client_secret: process.env.VUE_APP_SECRET || '',
    });

    await this.loadTokenData(response);
  }

  async tryStorageLogin() {
    const refreshToken = window.localStorage.getItem('refreshToken') || '';

    if (refreshToken) {
      try {
        const response = await ApiService.auth.refreshToken({
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
          client_id: process.env.VUE_APP_ID || '',
          client_secret: process.env.VUE_APP_SECRET || '',
        });
        await this.loadTokenData(response);
      } catch (e) {
        LoggerService.error(e);
      }
    }
  }
}

export default new AuthService();
