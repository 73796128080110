// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/auth/auth-agronote-min.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--transition-default:all 0.25s ease;--transition-compareMap:top 0.25s ease,left 0.25s ease,right 0.25s ease,bottom 0.25s ease,width 0.25s ease,height 0.25s ease,opacity 0.25s ease;font-size:1rem}.MapToolButton{height:40px;width:40px;background:var(--color-dark-background);display:flex;align-items:center;justify-content:center;border-radius:10px;box-shadow:0 2px 8px rgba(0,0,0,.25);cursor:pointer;transition:var(--transition-default);color:var(--color-dark-foreground)}.MapToolButton img{width:24px;height:24px}.MapToolButton.active{background:var(--color-dark-accent)}.MapToolButton:not(.active):hover{box-shadow:0 2px 8px rgba(0,0,0,.45);background:var(--color-dark-background-light)}.UnavailableLayout{display:flex;justify-content:center;height:100vh;width:100vw;overflow:hidden;box-shadow:inset 0 6px 33px -7px #0f8764}.UnavailableLayout_agronote{position:absolute;left:30vw;right:30vw;top:10vh;bottom:60vh}.UnavailableLayout_agronote-image{width:100%;height:100%;margin:0 auto;max-height:672px;max-width:493px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-position:50%;background-repeat:no-repeat}.UnavailableLayout_text{width:100%;bottom:20vh;font-size:3rem;font-weight:500;margin:50vh 0 0 0;background:#ffdaa6;padding:1rem .5rem;border-radius:9px;box-shadow:0 0 60px 20px #ffdaa6}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
