<template lang="pug" src="./NavigationPanel.pug"/>
<style lang="scss" src="./NavigationPanel.scss"/>

<script lang="ts">
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import { useNavigation } from '@/composables/useNavigation';
import { NavigationMenuItemType } from '@/constants/types/NavigationMenuItemType';
import { useMapLayout } from '@/composables/useMapLayout';
import { BlockNameType } from '@/constants/types/BlockNameType';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { useUser } from '@/composables/useUser';
import PermissionsList from '@/modules/permissions/PermissionsList';
import { NavigationMenuItems } from '@/assets/data/NavigationMenuItems';
import NavigationPanelItem from '@/modules/navigation/ui/NavigationPanel/NavigationPanelItem.vue';

export default defineComponent({
  name: 'NavigationPanel',
  components: {
    UiIcon,
    NavigationPanelItem,
  },
  setup() {
    const { activeSector } = useNavigation();
    const { user } = useUser();
    const { showBlock, areaComponents, clearArea } = useMapLayout();
    const submenuHovered = ref(false);
    const hovered = ref<string>('');
    const menu = computed(() => activeSector.value?.children || []);

    const mode = ref<'collapse' | 'expand'>((window.localStorage.getItem('sidebar-mode') as 'collapse' | 'expand') || 'expand');

    const open = (item: NavigationMenuItemType) => {
      submenuHovered.value = false;
      if (item.block) {
        clearArea(MapLayoutAreaEnum.LEFT);
        clearArea(MapLayoutAreaEnum.RIGHT);

        setTimeout(() => {
          item.block && showBlock(item.block);
        },
        0);
      }
    };

    const isActiveBlock = computed(() => (block: BlockNameType) => (areaComponents.value[MapLayoutAreaEnum.LEFT].includes(block) || areaComponents.value[MapLayoutAreaEnum.RIGHT].includes(block)));

    const submenuActive = computed(() => menu.value.length > 0 && (areaComponents.value[MapLayoutAreaEnum.LEFT].length + areaComponents.value[MapLayoutAreaEnum.RIGHT].length) === 0);

    const menuItemPermission = computed(() => (item: NavigationMenuItemType) => (item.permission ? PermissionsList.hasPermission(item.permission.target, item.permission.action) : true));

    const over = (name: string) => {
      hovered.value = name;
    };
    const mouseOut = () => {
      hovered.value = '';
    };

    const setWidthProperty = () => {
      if (window.document.body.clientWidth < 1000) {
        document.documentElement.style.setProperty('--map-area-navigation-width', '0');
      }
    };
    window.onresize = setWidthProperty;
    setWidthProperty();

    EventBus.$on(EventsEnum.UserLogout, () => {
      document.documentElement.style.setProperty('--map-area-submenu-width', '0px');
      document.documentElement.style.setProperty('--map-area-inner-left-padding', '0px');
    });

    const collapseItem = computed(() => ({
      name: 'collapse',
      callback: () => {
        mode.value = mode.value === 'expand' ? 'collapse' : 'expand';
        window.localStorage.setItem('sidebar-mode', mode.value);
        document.documentElement.style.setProperty('--map-area-navigation-width', mode.value === 'expand' ? '250px' : '50px');
      },
      icon: 'sidebar',
    }));

    onMounted(() => {
      document.documentElement.style.setProperty('--map-area-navigation-width', mode.value === 'expand' ? '250px' : '50px');
    });

    return {
      menu,
      over,
      mouseOut,
      open,
      hovered,
      user,
      submenuHovered,
      isActiveBlock,
      submenuActive,
      menuItemPermission,
      NavigationMenuItems,
      mode,
      collapseItem,
    };
  },
});
</script>
