import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "FieldsPanel" }
const _hoisted_2 = { class: "FieldsPanel_search" }
const _hoisted_3 = ["onClick", "onMouseenter"]
const _hoisted_4 = { class: "FieldsPanel_image" }
const _hoisted_5 = { class: "FieldsPanel_info" }
const _hoisted_6 = { class: "FieldsPanel_info-name" }
const _hoisted_7 = { class: "FieldsPanel_info-area" }
const _hoisted_8 = { class: "FieldsPanel_counter" }
const _hoisted_9 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_Select = _resolveComponent("Select")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_FieldView = _resolveComponent("FieldView")
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_SidePanelContent = _resolveComponent("SidePanelContent")

  return (_openBlock(), _createBlock(_component_SidePanelContent, {
    title: _ctx.$t('fields-contour', { ns: 'side-panel' }),
    onClose: _cache[4] || (_cache[4] = $event => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_input, {
            placeholder: _ctx.$t('search-field', { ns: 'placeholder' }),
            "prefix-icon": _ctx.Search,
            modelValue: _ctx.searchRow,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.searchRow) = $event)),
            onInput: _ctx.scrollTop
          }, null, 8, ["placeholder", "prefix-icon", "modelValue", "onInput"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["FieldsPanel_fields", { ['--has-counter']: true }]),
          ref: "fieldsListRef",
          onScroll: _cache[3] || (_cache[3] = (...args) => (_ctx.calculateTopIndex && _ctx.calculateTopIndex(...args)))
        }, [
          _createElementVNode("div", {
            class: "FieldsPanel_fields-prefix",
            style: _normalizeStyle({ paddingTop: _ctx.topIndex * 40 + 'px' })
          }, null, 4),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slicedFields, (field) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'field-' + field.id,
              class: _normalizeClass(["FieldsPanel_field", _ctx.featureClassList(field)]),
              onClick: $event => (_ctx.emitSelectField(field.id)),
              onMouseenter: $event => (_ctx.onMouseEnter(field.id)),
              onMouseleave: _cache[2] || (_cache[2] = $event => (_ctx.onMouseLeave())),
              style: _normalizeStyle({pointerEvents: _ctx.isDisabledField(field.id) ? 'none' : 'all'})
            }, [
              (_ctx.selectState === 'multiple')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["FieldsPanel_field-checkbox", { selected: (_ctx.activeField?.id === field?.id) || _ctx.hasSelectedField(field.id), disabled: _ctx.isDisabledField(field.id) }])
                  }, [
                    (_ctx.hasSelectedField(field.id))
                      ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Select)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (field?.id)
                  ? (_openBlock(), _createBlock(_component_FieldView, {
                      key: 0,
                      id: field.id,
                      size: 'm',
                      active: false
                    }, null, 8, ["id"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(field?.name), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(Math.round(field?.sq * 100) / 100) + " га", 1)
              ]),
              (_ctx.selectState === 'single')
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = $event => (_ctx.informationMode = 'field')),
                    circle: "",
                    link: "",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ui_icon, {
                        name: "mdiInformationVariantCircleOutline",
                        size: 24
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 46, _hoisted_3))
          }), 128)),
          _createElementVNode("div", {
            class: "FieldsPanel_fields-postfix",
            style: _normalizeStyle({ paddingBottom : (_ctx.computedFields.length - _ctx.topIndex - _ctx.rowsCount - 1) * 40 + 'px' })
          }, null, 4)
        ], 544),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('total', { ns: 'common' })) + " : " + _toDisplayString(_ctx.fields.length), 1),
          (_ctx.selectState === 'multiple' && _ctx.maxSelectFields !== Infinity)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.selectedFields.length) + " / " + _toDisplayString(_ctx.maxSelectFields), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}