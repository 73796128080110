import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import { GeoJSONSource } from 'mapbox-gl';

export class MapLayerUnifiedVectorModel extends MapLayerModel implements IMapLayerModel {
  readonly data: UnifiedVectorModel;

  readonly treeKey: string;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType, options: { treeKey: string }) {
    super(mapModel, type, 'unified-vector', `${input.uuid}-${options.treeKey}`);
    this.data = input as UnifiedVectorModel;
    this.treeKey = options.treeKey;
    this.createSource();
    this.createLayer();
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  createSource = (): void => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this.data.featuresCollection,
    });
  }

  createLayer = (): void => {
    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'fill',
      source: this.sourceId,
      layout: {},
      metadata: { type: 'unified-vector' },
      paint: {
        'fill-color': ['get', '__paletteColor'],
        'fill-opacity': 1,
        'fill-outline-color': '#329FCF',
      },
    });
    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.VECTOR);
  }

  redraw =(): void => {
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource).setData(this.data.featuresCollection);
  }

  paintDefaultVector = () => {
    // if (usePaint().selectedPaint.value === 'vector-custom' && usePaint().customPalette.value.customType === 'class') {
    //   const prop = `${usePaint().customPalette.value.name}_dg`;
    //   const coloring: any[] = [];
    //
    //   usePaint().customPalette.value?.palette.forEach((item: any, index: number) => {
    //     coloring.push(['==', ['get', prop], item.value], item.color);
    //   });
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     ['case',
    //       ...coloring,
    //       'rgba(255,255,255,0.40)',
    //     ]
    //
    //     ,
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#000000',
    //   );
    //   return;
    // }
    // if (!usePaint().selectedColor.value && usePaint().customPalette.value.palette.length === 0) {
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     '#A5F3F3',
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#329FCF',
    //   );
    // } else if (usePaint().selectedPaint.value === 'vector-custom' && usePaint().customPalette.value.palette.length > 0) {
    //   const coloring: any[] = [];
    //   if (usePaint().customPalette.value?.type === 'range') {
    //     const reversArr = [...usePaint().customPalette.value?.palette];
    //
    //     reversArr.forEach((item: any, index: number) => {
    //       if (index === reversArr.length - 1) {
    //         coloring.push(['>', ['get', usePaint().customPalette.value?.name], item.value], item.color);
    //       } else {
    //         coloring.push(['<=', ['get', usePaint().customPalette.value?.name], item.value], item.color);
    //       }
    //     });
    //   }
    //   if (usePaint().customPalette.value?.type === 'unique') {
    //     [...usePaint().customPalette.value?.palette].reverse().forEach((item: any, index: number) => {
    //       coloring.push(['==', ['get', usePaint().customPalette.value?.name], item.value], item.color);
    //     });
    //   }
    //
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     ['case',
    //       ...coloring,
    //       '#fff',
    //     ],
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#000000',
    //   );
    // } else if (usePaint().selectedColor.value) {
    //   const selectedColor: VectorColorType = usePaint().selectedColor.value as VectorColorType;
    //
    //   const coloring: any[] = [];
    //
    //   selectedColor.palette.forEach((item, index: number) => {
    //     // @ts-ignore
    //     if (selectedColor.type === VectorPaletteEnum.UNIQUE) {
    //       coloring.push(['==', ['get', selectedColor.name], item.value], item.color);
    //     } else if (index === 0) {
    //       coloring.push(['<=', ['get', selectedColor.name], item.value], item.color);
    //     } else if (index === selectedColor.palette.length - 1) {
    //       coloring.push(['>', ['get', selectedColor.name], item.value], item.color);
    //     } else {
    //       coloring.push(['<=', ['get', selectedColor.name], item.value], item.color);
    //     }
    //   });
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-color',
    //     ['case',
    //       ...coloring,
    //       'rgba(255,255,255,0.40)',
    //     ],
    //   );
    //   this._mapModel?.map?.setPaintProperty(
    //     this.layerId,
    //     'fill-outline-color',
    //     '#000000',
    //   );
    // }
  }
}
