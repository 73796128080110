import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { PaletteDto } from '@/services/api/dto/assets/palette/PaletteDto';
import { DefaultPaletteDto } from '@/services/assets/dto/DefaultPaletteDto';
import { GradientDto } from '@/services/assets/dto/GradientDto';

const uriPrefix = `${process.env.VUE_APP_ASSETS_URL}/${process.env.VUE_APP_SERVER_LABEL}/scripts`;

export const endpoints = {
  getPalette: (unit: number, paletteId: string): string => `${uriPrefix}/palette/${unit}/get/${paletteId}`,
  putPalette: (unit: number): string => `${uriPrefix}/palette/${unit}/insert`,
  gradientsList: (unit: number): string => `${uriPrefix}/gradients/${unit}/list`,
  defaultPalettes: (unit: number): string => `${uriPrefix}/default-palettes/${unit}/list`,
};

export class ApiAssets extends ApiHandler {
  public async getPalette(paletteId: string): Promise<ApiResponse<PaletteDto>> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return await this.request({ auth: true, serviceToken: true, freeze: false }).get<PaletteDto>(endpoints.getPalette(unit, paletteId));
  }

  public async putPalette(dto: PaletteDto): Promise<ApiResponse<void>> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return await this.request({ auth: true, serviceToken: true }).put<void>(endpoints.putPalette(unit), dto as unknown as Record<string, unknown>);
  }

  public async defaultPalettes(): Promise<ApiResponse<DefaultPaletteDto[]>> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return await this.request({ auth: true, serviceToken: true }).get<DefaultPaletteDto[]>(endpoints.defaultPalettes(unit));
  }

  public async gradientsList(): Promise<ApiResponse<GradientDto[]>> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return await this.request({ auth: true, serviceToken: true }).get<GradientDto[]>(endpoints.gradientsList(unit));
  }
}
