<template src="./AppLayout.pug" lang="pug" />
<style src="./AppLayout.scss" lang="scss" />

<script lang="ts">
import HoverLayer from '@/components/ui/HoverLayer/HoverLayer.vue';
import { useAuth } from '@/composables/useAuth';
import { useMapContainers } from '@/composables/useMapContainers';
import { useMapLayout } from '@/composables/useMapLayout';
import { useUser } from '@/composables/useUser';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayoutAreaEnum } from '@/constants/enums/MapLayoutEnums';
import CoreLoaderService from '@/modules/core/CoreLoaderService';
import CropManage from '@/modules/dictionary/ui/crop/CropManage.vue';
import MapContainer from '@/modules/map/container/MapContainer.vue';
import MapContextMenu from '@/modules/map/container/MapContextMenu/MapContextMenu.vue';
import NavigationBlock from '@/modules/navigation/ui/NavigationBlock.vue';
import NavigationPanel from '@/modules/navigation/ui/NavigationPanel/NavigationPanel.vue';
import NavigationSubmenu from '@/modules/navigation/ui/submenu/NavigationSubmenu.vue';
import StatusBarBlock from '@/modules/statusBar/ui/StatusBarBlock/StatusBarBlock.vue';
import StructList from '@/modules/struct/StructList';
import EulaBlock from '@/modules/user/ui/EulaBlock/EulaBlock.vue';
import EventBus from '@/services/eventBus/EventBus';
import MyTrackerService from '@/services/myTracker/MyTrackerService';
import { TolgeeProvider } from '@tolgee/vue';
import { MapMouseEvent } from 'mapbox-gl';
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    EulaBlock,
    NavigationSubmenu,
    HoverLayer,
    MapContextMenu,
    CropManage,
    TolgeeProvider,
    MapContainer,
    NavigationPanel,
    StatusBarBlock,
    NavigationBlock,

  },
  setup() {
    const {
      mapModel,
      compareMode,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const { isAuth } = useAuth();

    const {
      isLoaded,
    } = useUser();

    const {
      areaComponents,
      showProgress,
      showCropManage,
      showMapLayerSettings,
      showContent,
    } = useMapLayout();

    const router = useRouter();

    const loading = ref(true);

    const leftComponent = computed(() => areaComponents.value[MapLayoutAreaEnum.LEFT][0] || null);

    const rightComponent = computed(() => areaComponents.value[MapLayoutAreaEnum.RIGHT][0] || null);

    const modalComponents = computed(() => areaComponents.value[MapLayoutAreaEnum.MODAL]);

    onMounted(async () => {
      setTimeout(async () => await CoreLoaderService.fetchData());
      MyTrackerService.send('Start the application.');
      loading.value = false;
    });

    const modalWrapperClick = (e: any) => {
      if (e.target.className === 'AppLayout_area _modal-wrapper active') {
        EventBus.$emit(EventsEnum.ModalWrapperOnClick);
      }
    };
    const modalBgClicked = () => {
      EventBus.$emit(EventsEnum.ModalBackgroundClicked);
    };

    // region Cross settings
    const event = ref<MapMouseEvent | undefined>();
    mapModel.value?.events.onContextMenu((e: MapMouseEvent) => {
      event.value = e;
    });

    mapModel.value?.events.onContextMenuClose(() => {
      event.value = undefined;
    });

    const computedCrossStyle = computed(() => {
      if (event.value) {
        return {
          position: 'fixed',
          left: `${Number(event.value?.originalEvent.offsetX) - 15}px`,
          top: `${Number(event.value?.originalEvent.offsetY) - 15}px`,
        };
      }
      return {};
    });

    const contentBlockWidth = computed(() => (showContent.value ? router.currentRoute.value.meta?.width || 0 : 10));
    // endregion

    return {
      isAuth,
      isLoaded,
      StructList,
      modalWrapperClick,
      modalBgClicked,
      loading,
      computedCrossStyle,
      compareMode,
      MapContainerEnum,
      leftComponent,
      rightComponent,
      modalComponents,
      showProgress,
      showCropManage,
      showMapLayerSettings,
      contentBlockWidth,
    };
  },
});

</script>
