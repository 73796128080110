import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { PoiModel } from '@/models/poi/PoiModel';
import { Feature, FeatureCollection } from 'geojson';
import { AnyLayer, GeoJSONSource } from 'mapbox-gl';
import { MapPoiEvents } from '@/models/map/Events/MapPoiEvents';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { POIParams } from '@/assets/data/POIParams';
import { SimpleType } from '@/constants/types/BasedTypes';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { PoiCollection } from '@/collection/PoiCollection';
import { OrderedPoiKeys } from '@/assets/data/OrderedPoiKeys';

const layerDef = (layerId: string, sourceId: string): AnyLayer => ({
  id: layerId,
  type: 'circle',
  source: sourceId,
  metadata: { type: 'POI' },
  paint: {
    'circle-radius': [
      'interpolate', ['linear'], ['zoom'],
      7, 1,
      11, 2,
      12, 4,
      15, 5,
    ],
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'active'], false], '#ff812e',
      ['boolean', ['get', 'create'], true], '#002aff',
      ['boolean', ['get', 'visited'], true], '#B25900',
      '#006C67',
    ],
    'circle-stroke-width': [
      'interpolate', ['linear'], ['zoom'],
      7, 1,
      11, 1,
      12, 2,
      15, 2,
    ],
    'circle-stroke-color': [
      'case',
      ['boolean', ['feature-state', 'active'], false], '#ff812e',
      ['boolean', ['feature-state', 'hover'], false], '#ffaf7a',
      '#FFEBC6',
    ],
  },
});

const labelLayerDef = (layerId: string, sourceId: string): AnyLayer => ({
  id: layerId,
  type: 'symbol',
  source: sourceId,
  layout: {
    'text-field': ['get', 'label'],
    'text-radial-offset': 0.5,
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-size': [
      'interpolate', ['linear'], ['zoom'],
      9, 0,
      10, 5,
      11, 10,
      12, 13,
      20, 14,
    ],
    'text-justify': 'center',
    // @ts-ignore
    'text-font': [
      'literal',
      ['Inter Regular'],
    ],
  },
  paint: {
    'text-color': '#25282B',
    'text-halo-color': 'rgba(255,255,255,0.6)',
    'text-halo-width': 2,
  },

});

export class MapLayerPoiModel extends MapLayerModel implements IMapLayerModel {
  get data(): PoiCollection {
    return this._data;
  }

  get showTextLabel(): boolean {
    return this._showTextLabel;
  }

  set showTextLabel(value: boolean) {
    this._showTextLabel = value;
  }

  get showTextName(): boolean {
    return this._showTextName;
  }

  set showTextName(value: boolean) {
    this._showTextName = value;
  }

  get poisLabels(): Record<string, string> {
    return this._poisLabels;
  }

  private _data: PoiCollection

  // хранит ключ и перевод для ключа
  private _poisLabels: Record<string, string> = {}

  private _showTextName = true;

  private _showTextLabel = true;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'pois', input.uuid);
    this._data = input as PoiCollection;
    this.getLabelStorage();
    this.calculateLabelPoi();
    this.calculatePOILabelSource();

    if (this._mapModel?.map) {
      this._mapModel.map
        .addSource(this.sourceId, {
          type: 'geojson',
          data: this.calculatePOISource(),
        })
        .addLayer(layerDef(this.layerId, this.sourceId))
        .moveLayer(this.layerId, MapAnchorEnum.POI)
        .addSource(`${this.sourceId}-label`, {
          type: 'geojson',
          data: this.calculatePOILabelSource(),
        })
        .addLayer(labelLayerDef(`${this.layerId}-label`, `${this.sourceId}-label`))
        .moveLayer(`${this.layerId}-label`, MapAnchorEnum.POI);

      this.layerIds.push(this.layerId);
      this.layerIds.push(`${this.layerId}-label`);
      this.sourceIds.push(this.sourceId);
      this.sourceIds.push(`${this.sourceId}-label`);
    }

    // eslint-disable-next-line no-new
    new MapPoiEvents(this, mapModel);
  }

  getLabelStorage() {
    const showText = JSON.parse(window.localStorage.getItem('poi-show-text') || JSON.stringify({ label: true, name: true }));
    this._showTextLabel = showText.label;
    this._showTextName = showText.name;
  }

   calculateLabelPoi = () => {
     // this._poisLabels = {};
     Object.keys(this._poisLabels).forEach((key) => delete this._poisLabels[key]);
     const dp = new Set<string>();
     this.data.collection.forEach((p: PoiModel) => {
       Object.keys(p.params).forEach((k: string) => {
         if (![undefined, null, true, false, -1].some((e) => e === p.params[k]) && !['y', 'x', 'n_prob'].includes(k)) {
           dp.add(k);
         }
       });
     });

     Array.from(dp).sort((a, b) => {
       const indexA = OrderedPoiKeys.indexOf(a);
       const indexB = OrderedPoiKeys.indexOf(b);

       if (indexA === -1 && indexB === -1) {
         return 0; // Если оба элемента отсутствуют в OrderedPoiKeys, они равнозначны
       } if (indexA === -1) {
         return 1; // Если элемент a отсутствует, он идет после элемента b
       } if (indexB === -1) {
         return -1; // Если элемент b отсутствует, он идет после элемента a
       }
       return indexA - indexB;
     }).forEach((a) => {
       this._poisLabels[a] = '';
     });
   };

  redraw = (pois: PoiModel[]) => {
    this._data.collection = pois;
    this.calculateLabelPoi();
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource)?.setData(this.calculatePOISource());
    this.redrawLabels();
  }

  redrawLabels = () => {
    (this._mapModel?.map?.getSource(`${this.sourceId}-label`) as GeoJSONSource)?.setData(this.calculatePOILabelSource());
  }

  calculatePOISource = (): FeatureCollection => ({
    type: 'FeatureCollection',
    features: this.data.collection.map((poi) => ({
      id: poi.id,
      type: 'Feature',
      geometry: poi.geometry,
      properties: {
        visited: poi.visited,
        active: poi.isActive,
        create: poi.params?.create || false,
      },
    } as Feature)) || [],
  })

  setActivePoi = (poiId: number | undefined) => {
    this._data.collection.forEach((a) => {
      if (this._mapModel?.map?.getSource(this.sourceId)) {
        if (a.id === poiId) {
          this._mapModel.map.setFeatureState(
            { source: this.sourceId, id: a.id },
            { active: true, hover: false },
          );
        } else {
          this._mapModel.map.setFeatureState(
            { source: this.sourceId, id: a.id },
            { active: false, hover: false },
          );
        }
      }
    });
  }

  calculatePOILabelSource = (): FeatureCollection => ({
    type: 'FeatureCollection',
    features: this.data.collection.map((poi) => {
      const labelWords = [];
      if (this._showTextName) {
        labelWords.push(poi.name);
      }
      const canShowParam = (key: string, value: SimpleType) => {
        if (POIParams[key] && ['number', 'int'].includes(POIParams[key].type)) {
          return Number(value) !== -1;
        }
        return true;
      };

      if (this._showTextLabel) {
        Object.keys(this._poisLabels).forEach((key) => {
          if (canShowParam(key, poi.params[key])) {
            if (poi.params[key] && this._poisLabels[key].length) {
              labelWords.push(`${this._poisLabels[key]}: ${poi.params[key]}`);
            } else if (poi.params[key] && this._poisLabels[key]) {
              labelWords.push(`${key}: ${poi.params[key]}`);
            }
          }
        });
      } else {
        Object.keys(this._poisLabels).forEach((key) => {
          if (canShowParam(key, poi.params[key])) {
            poi.params[key] !== undefined && this._poisLabels[key].length > 0 && labelWords.push(`${poi.params[key]}`);
          }
        });
      }
      return {
        id: poi.id,
        type: 'Feature',
        geometry: poi.geometry,
        properties: {
          label: labelWords.join('\n'),
        },
      } as Feature;
    }) || [],
  });
}
