import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "MapContainer-Button disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_MonitoringCompactBlock = _resolveComponent("MonitoringCompactBlock")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (!_ctx.activeField)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ui_icon, {
          name: "/images/icons/submenu/monitoring.svg",
          size: 24
        })
      ]))
    : (_openBlock(), _createBlock(_component_el_popover, {
        key: 1,
        placement: "right-start",
        width: 320,
        trigger: "hover",
        teleported: false,
        "hide-after": 0
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["MapContainer-Button", { active: _ctx.active === 'monitoring', disabled: !_ctx.activeField }])
          }, [
            _createVNode(_component_ui_icon, {
              name: "/images/icons/submenu/monitoring.svg",
              size: 24
            })
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_MonitoringCompactBlock, { map: _ctx.map }, null, 8, ["map"])
        ]),
        _: 1
      }))
}