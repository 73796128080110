import {
  taskMapContourLabelsLayerDef,
  taskMapContourLayerDef,
  taskMapContourLinesLayerDef,
} from '@/models/map/Layers/defs/TaskMapContourDef';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { FeatureCollection, GeoJsonProperties } from 'geojson';
import { FeatureIdentifier, GeoJSONSource } from 'mapbox-gl';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { TaskMapIndexOverlapDto } from '@/services/api/dto/taskMap/TaskMapIndexOverlapDto';
import { useMonitoring } from '@/composables/useMonitoring';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';

export class MapLayerWorkTaskContourModel extends MapLayerModel {
  get workTask(): FieldTaskMapWorkModel {
    return this._workTask;
  }

  private readonly _workTask: FieldTaskMapWorkModel;

  private _settings: Record<string, any>;

  constructor(mapModel: MapModel, workTask: FieldTaskMapWorkModel, settings: Record<string, any>) {
    super(mapModel, MapLayerTypeEnum.TASK_MAP_WORK_KONTUR, 'task-map-contour', workTask.uuid);
    this._workTask = workTask;
    this._settings = settings;
    this.createLayer();
    this.sourceIds.push(this.sourceId);
    this.layerIds.push(`contour-${this.layerId}`, this.layerId, `labels-${this.layerId}`);

    let hoveredPolygonId: number | undefined;

    this._mapModel?.map?.on('mousemove', this.layerId, (e) => {
      if ((e.features || []).length > 0) {
        if (hoveredPolygonId) {
          this._mapModel?.map?.setFeatureState(
            { source: this.sourceId, id: hoveredPolygonId },
            { hover: false },
          );
        }
        // @ts-ignore
        hoveredPolygonId = Number(e.features[0].id);
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: hoveredPolygonId },
          { hover: true },
        );
      }
    });

    this._mapModel?.map?.on('mouseleave', this.layerId, () => {
      if (hoveredPolygonId !== null) {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: hoveredPolygonId } as FeatureIdentifier,
          { hover: false },
        );
      }
      hoveredPolygonId = undefined;
    });
  }

  formatProperties = (properties: GeoJsonProperties, id: number, overlap: TaskMapIndexOverlapDto | undefined) => {
    const labels: string[] = [];
    if (overlap && overlap.index_label) {
      const fp = overlap.properties.find((prop) => prop.feature_id === id);
      if (fp) {
        labels.push(`${overlap.index_label ? `${overlap.index_label}: ` : ''}${Math.round(fp.avg * 100) / 100}`);
      }
    }

    if (useMonitoring().selectedWorkTask.value && useMonitoring().products.value) {
      const feature = useMonitoring().selectedWorkTask.value?.geojson?.features.find((f) => f.properties?.id === id);
      if (feature) {
        useMonitoring().products.value.forEach((prod) => {
          if (prod.show) {
            const pf = feature.properties?.prod.find((v: any) => v.name === prod.name);
            if (pf) {
              labels.push(this._settings.showLabels ? `${pf.name}: ${pf.rate}` : `${pf.rate}`);
            }
          }
        });
      }
    }

    return {
      label: labels.join('\n'),
      zone: properties?.zone.toString(),
      color: properties?.color,
      id,
    } as GeoJsonProperties;
  };

  data = (overlap: TaskMapIndexOverlapDto | undefined = undefined): FeatureCollection => {
    if (this._workTask.geojson) {
      return {
        type: this._workTask.geojson?.type,
        bbox: this._workTask.geojson?.bbox,
        features: this._workTask.geojson?.features.map((feature) => ({
          type: 'Feature',
          geometry: feature.geometry,
          id: Number(feature.properties?.id || 0),
          properties: this.formatProperties(feature.properties, Number(feature.properties?.id || 0), overlap),
        })),
      } as FeatureCollection;
    }
    return {
      type: 'FeatureCollection',
      features: [],
    } as FeatureCollection;
  };

  setSettings = (settings: Record<string, any>, overlap: TaskMapIndexOverlapDto | undefined) => {
    this._settings = settings;
    if (this._workTask.geojson && settings.fill === 1) {
      this._workTask.geojson.features.forEach((f) => {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: Number(f.properties?.id) },
          { inactive: false, active: false },
        );
      });
    }
    if (this._workTask.geojson && settings.fill === 0) {
      this._workTask.geojson.features.forEach((f) => {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: Number(f.properties?.id) },
          { inactive: true, active: false },
        );
      });
    }
    if (this._workTask.geojson && settings.fill === 2) {
      this._workTask.geojson.features.forEach((f) => {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: Number(f.properties?.id) },
          { inactive: false, active: true },
        );
      });
    }

    this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-opacity', ['case',
      ['boolean', ['feature-state', 'inactive'], false], 0,
      ['boolean', ['feature-state', 'active'], false], settings.opacity / 100,
      ['boolean', ['feature-state', 'hover'], false], settings.opacity / 100,
      0,
    ]);

    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource).setData(this.data(overlap));
  }

  createLayer = () => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this.data(),
    });
    this._mapModel?.map?.addLayer(taskMapContourLayerDef(this.layerId, this.sourceId));
    this._mapModel?.map?.addLayer(taskMapContourLinesLayerDef(`contour-${this.layerId}`, this.sourceId));
    this._mapModel?.map?.addLayer(taskMapContourLabelsLayerDef(`labels-${this.layerId}`, this.sourceId));

    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.TASK_MAP_CONTOUR);
    this._mapModel?.map?.moveLayer(`contour-${this.layerId}`, MapAnchorEnum.TASK_MAP_CONTOUR);
    this._mapModel?.map?.moveLayer(`labels-${this.layerId}`, MapAnchorEnum.TASK_MAP_CONTOUR);
  }

  remove = () => {
    this._mapModel?.workTaskContourLayers.splice(this._mapModel?.workTaskContourLayers.indexOf(this), 1);
  }
}
