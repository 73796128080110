<template lang="pug" src="./ScaleControlBlock.pug"/>
<style lang="scss" src="./ScaleControlBlock.scss"/>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, PropType, ref, UnwrapRef, watch,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { MapModel } from '@/models/map/MapModel';
import mapboxgl from 'mapbox-gl';

export default defineComponent({
  name: 'ScaleControlBlock',
  components: {},
  props: {
    map: {
      type: Object as PropType<UnwrapRef<MapModel>>,
      required: true,
    },
  },
  setup(props) {
    const { user } = useUser();
    const root = ref<HTMLElement>();
    const width = ref();

    const block = ref();
    const scaleBlock = ref();
    const text = ref('');
    onMounted(() => {
      props.map.map?.addControl(new mapboxgl.ScaleControl());

      props.map.map?.on('render', () => {
        block.value = document.getElementById(props.map.container);
        if (block.value) {
          scaleBlock.value = block.value.getElementsByClassName('mapboxgl-ctrl-scale');
          if (scaleBlock.value && scaleBlock.value[0] && scaleBlock.value[0].textContent) {
            text.value = scaleBlock.value[0].textContent;
            width.value = scaleBlock.value[0].style.width;
          }
        }
      });
    });

    return {
      user,
      text,
      width,
      root,
    };
  },
});
</script>
