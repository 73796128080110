import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import type { RasterModel } from '@/models/RasterModel';

export class MapLayerRasterModel extends MapLayerModel implements IMapLayerModel {
  public data: RasterModel

  readonly treeKey: string;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType, options: { treeKey: string }) {
    super(mapModel, type, 'raster', `${input.uuid}-${options.treeKey}`);
    this.data = input as RasterModel;
    this.treeKey = options.treeKey;
    this.createSourceRaster();
    this.sourceIds.push(this.sourceId);
    this.layerIds.push(this.layerId);
  }

  createSourceRaster = () => {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'raster',
      tiles: [`${process.env.VUE_APP_API_URL}/gis/rtile/${this.data.source_number}/{z}/{x}/{y}.png?solt=${this.data.update_date}`],
      tileSize: 256,
    });
    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'raster',
      source: this.sourceId,
    });
    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.RASTER);
  }
}
