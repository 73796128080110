<template lang="pug" src="./MapDropDownMenu.pug"/>
<style lang="scss" src="./MapDropDownMenu.scss"/>

<script lang="ts">
import {
  computed, defineComponent, PropType, ref, toRefs,
} from 'vue';
import { useUser } from '@/composables/useUser';
import { ArrowDown } from '@element-plus/icons-vue';
import { DropDownContentType } from '@/constants/types/DropDownContentType';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import LoggerService from '@/services/logger/LoggerService';

enum TypeSelectEnum{
  CHECKBOX= 'checkbox',
  RADIO = 'radio',
}

export default defineComponent({
  name: 'MapDropDownMenu',
  props: {
    titleImg: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    titleCount: {
      type: Number,
    },
    type: {
      type: String as PropType<TypeSelectEnum>,
      required: true,
    },
    dragOn: {
      type: Boolean,
      required: false,
    },
    /** принимает атрибут id лемента  */
    toolNameId: {
      type: String,
      required: true,
    },
    contentMenu: {
      type: Array as PropType<DropDownContentType[]>,
      required: true,
    },
    contentSubMenu: {
      type: Array as PropType<DropDownContentType[]>,
    },
    /** по умолчанию равен useMapLayout() paddings left  */
    paddingLeft: {
      type: Number,
      default: 60,
    },
    teleportTo: {
      type: String,
      default: 'PopupZone',
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    ArrowDown,
    UiIcon,
  },
  emits: ['update:contentMenu', 'select', 'hoverItem'],
  setup(props, { emit }) {
    const { user } = useUser();
    const element = ref<HTMLElement>();
    const hoverItemId = ref(0);
    const submenuCordY = ref(0);
    const draggingItemOrder = ref(-1);
    const { contentMenu } = toRefs(props);

    const subMenu = computed(() => (id: number) => props.contentMenu.filter((s) => s.parentId === id) || []);

    const clickItem = (item: DropDownContentType) => {
      const check = props.contentMenu.some((s) => s.parentId === item.id);
      if (!check) {
        if (props.type === TypeSelectEnum.RADIO) {
          const arr = props.contentMenu.map((x) => ({ ...x, active: x.id === item.id }));
          emit('update:contentMenu', arr);
        }
        if (props.type === TypeSelectEnum.CHECKBOX) {
          const arr = props.contentMenu.map((x) => ({ ...x, active: x.id === item.id ? !x.active : x.active }));
          emit('update:contentMenu', arr);
        }
      }
    };

    const clickSubItem = (item: DropDownContentType) => {
      if (props.type === TypeSelectEnum.RADIO) {
        const arr = props.contentMenu.map((x) => ({ ...x, active: x.id === item.id || x.id === item.parentId }));
        emit('update:contentMenu', arr);
      }
      if (props.type === TypeSelectEnum.CHECKBOX) {
        const arr = props.contentMenu.map((x) => ({ ...x, active: (x.id === item.id ? !x.active : x.active) || x.id === item.parentId }));
        emit('update:contentMenu', arr);
      }
    };

    const overMove = (e: MouseEvent) => {
      // @ts-ignore
      const rect = e.target?.getBoundingClientRect();

      submenuCordY.value = rect.top - 110;
    };

    const hoveredItem = (id: number) => {
      hoverItemId.value = id;
    };

    const teleportToEl = computed(() => {
      if (props.teleportTo) {
        const elements = document.getElementsByClassName(props.teleportTo);
        if (elements.length > 1) {
          LoggerService.error('To many popup zones.');
        } else if (elements.length === 1) {
          return elements[0];
        } else {
          LoggerService.error('Popup zone is not found.');
        }
      }
      return undefined;
    });

    const moveItem = (fromIdx: number, toIdx: number) => {
      if (fromIdx === toIdx) return;
      if (toIdx > contentMenu.value.length) toIdx = contentMenu.value.length - 1;
      contentMenu.value.splice(toIdx, 0, contentMenu.value.splice(fromIdx, 1)[0]);
    };

    const dragStart = (index: number) => {
      draggingItemOrder.value = index;
    };

    const dragOver = (evt: DragEvent) => {
      const toIdx = Number((evt.target as HTMLElement).dataset?.idx);
      if (!Number.isNaN(toIdx) && draggingItemOrder.value !== toIdx) {
        moveItem(draggingItemOrder.value, toIdx);
        draggingItemOrder.value = toIdx;
      }
      evt.preventDefault();
    };

    const dragEnd = () => {
      draggingItemOrder.value = -1;
      let i = 1;
      // eslint-disable-next-line no-return-assign
      const arr = contentMenu.value.map((c) => ({ ...c, order: c?.order && (c.order = i++) }));
      emit('update:contentMenu', arr);
    };

    return {
      user,
      element,
      teleportToEl,
      clickItem,
      subMenu,
      submenuCordY,
      hoverItemId,
      clickSubItem,
      dragStart,
      dragOver,
      dragEnd,
      draggingItemOrder,
      overMove,
      hoveredItem,
    };
  },
});
</script>
