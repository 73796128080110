import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ImageListGroup" }
const _hoisted_2 = ["data-palette", "data-key", "data-obj"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "ImageListGroup-cog" }
const _hoisted_7 = { style: {"padding":"5px 15px 10px 15px"} }
const _hoisted_8 = { class: "ImageListGroup-cog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiLoading = _resolveComponent("UiLoading")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_GroupPalettePanel = _resolveComponent("GroupPalettePanel")!
  const _component_ui_drawer = _resolveComponent("ui-drawer")!
  const _component_VectorPalettePanel = _resolveComponent("VectorPalettePanel")!
  const _component_RasterPalettePanel = _resolveComponent("RasterPalettePanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_UiLoading, { key: 0 }))
        : (_openBlock(), _createBlock(_component_el_tree, {
            key: 1,
            ref: "treeRef",
            style: {"max-width":"100%"},
            "allow-drop": _ctx.allowDrop,
            data: _ctx.data,
            draggable: "",
            "expand-on-click-node": true,
            "node-key": "key",
            onNodeDrop: _ctx.handleDrop
          }, {
            default: _withCtx(({ node, data }) => [
              _createVNode(_component_el_tooltip, {
                trigger: "hover",
                content: _ctx.$t('file-for-tree-node-is-not-found', { ns: 'side-panel' }),
                disabled: !(data.type !== 'group' && !data.model),
                placement: "left",
                effect: "error"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["ImageListGroup-row", { inactive: data.type !== 'group' & (!data.active || !_ctx.isVisible(data)) }]),
                    key: data.key,
                    "data-palette": data.palette,
                    "data-key": data.key,
                    "data-obj": data.obj
                  }, [
                    _createElementVNode("div", {
                      class: "ImageListGroup-checkbox",
                      onClick: ($event: any) => (_ctx.handleCheckbox($event, data))
                    }, [
                      _createVNode(_component_el_checkbox, {
                        "model-value": data.active,
                        disabled: data.type !== 'group' && !data.model
                      }, null, 8, ["model-value", "disabled"])
                    ], 8, _hoisted_3),
                    _createElementVNode("div", {
                      class: "ImageListGroup-icon",
                      onClick: ($event: any) => (_ctx.handleClick(data))
                    }, [
                      _createVNode(_component_ui_icon, {
                        name: _ctx.icon(data),
                        color: _ctx.iconColor(data)
                      }, null, 8, ["name", "color"])
                    ], 8, _hoisted_4),
                    _createElementVNode("div", {
                      class: "ImageListGroup-label",
                      onClick: ($event: any) => (_ctx.handleClick(data))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(data.label), 1)
                    ], 8, _hoisted_5),
                    _createElementVNode("div", _hoisted_6, [
                      (data.type !== 'group')
                        ? (_openBlock(), _createBlock(_component_el_popover, {
                            key: 0,
                            width: 300,
                            trigger: "click"
                          }, {
                            reference: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                size: "small",
                                circle: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ui_icon, {
                                    name: "mdiOpacity",
                                    color: "currentColor"
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", null, _toDisplayString(_ctx.$t('opacity')), 1),
                                _createVNode(_component_el_slider, {
                                  modelValue: data.opacity,
                                  "onUpdate:modelValue": ($event: any) => ((data.opacity) = $event),
                                  step: 5,
                                  min: 0,
                                  max: 100,
                                  onChange: ($event: any) => (_ctx.setOpacity(data)),
                                  marks: _ctx.SliderMarks0to100
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "marks"])
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_el_button, {
                        circle: "",
                        size: "small",
                        onClick: ($event: any) => (_ctx.openSettings($event, data))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ui_icon, {
                            name: "mdiCogOutline",
                            color: "currentColor"
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ], 10, _hoisted_2))
                ]),
                _: 2
              }, 1032, ["content", "disabled"])
            ]),
            _: 1
          }, 8, ["allow-drop", "data", "onNodeDrop"]))
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_drawer, {
        position: "right",
        show: _ctx.settings?.type === 'group' || false,
        title: _ctx.$t('group-palette-title', 'Настройка группы'),
        onClose: _cache[3] || (_cache[3] = ($event: any) => {_ctx.settings = undefined;})
      }, {
        default: _withCtx(() => [
          (_ctx.settings?.type === 'group')
            ? (_openBlock(), _createBlock(_component_GroupPalettePanel, {
                key: 0,
                node: _ctx.settings,
                "map-container": _ctx.mapContainer,
                onClose: _cache[0] || (_cache[0] = ($event: any) => {_ctx.settings = undefined;}),
                onSaveName: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveName($event))),
                onDeletePalette: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deletePalette($event)))
              }, null, 8, ["node", "map-container"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show", "title"]),
      _createVNode(_component_ui_drawer, {
        position: "right",
        show: _ctx.settings?.type === 'vector' || false,
        title: _ctx.$t('vector-palette-title', 'Настройка вектора'),
        onClose: _cache[7] || (_cache[7] = ($event: any) => {_ctx.settings = undefined;})
      }, {
        default: _withCtx(() => [
          (_ctx.settings?.type === 'vector')
            ? (_openBlock(), _createBlock(_component_VectorPalettePanel, {
                key: 0,
                node: _ctx.settings,
                "map-container": _ctx.mapContainer,
                onClose: _cache[4] || (_cache[4] = ($event: any) => {_ctx.settings = undefined;}),
                onSaveName: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveName($event))),
                onDeletePalette: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deletePalette($event)))
              }, null, 8, ["node", "map-container"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show", "title"]),
      _createVNode(_component_ui_drawer, {
        position: "right",
        show: _ctx.settings?.type === 'raster' || false,
        title: _ctx.$t('raster-palette-title', 'Настройка растра'),
        onClose: _cache[11] || (_cache[11] = ($event: any) => {_ctx.settings = undefined;})
      }, {
        default: _withCtx(() => [
          (_ctx.settings?.type === 'raster')
            ? (_openBlock(), _createBlock(_component_RasterPalettePanel, {
                key: 0,
                node: _ctx.settings,
                "map-container": _ctx.mapContainer,
                onClose: _cache[8] || (_cache[8] = ($event: any) => {_ctx.settings = undefined;}),
                onSaveName: _cache[9] || (_cache[9] = ($event: any) => (_ctx.saveName($event))),
                onDeletePalette: _cache[10] || (_cache[10] = ($event: any) => (_ctx.deletePalette($event)))
              }, null, 8, ["node", "map-container"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show", "title"])
    ]))
  ], 64))
}