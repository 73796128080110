import { useMonitoring } from '@/composables/useMonitoring';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { Model } from '@/models/Model';
import ApiService from '@/services/api/ApiService';
import { TaskMapIndexOverlapDto } from '@/services/api/dto/taskMap/TaskMapIndexOverlapDto';
import { FeatureCollection, GeoJsonProperties } from 'geojson';

export class MonitoringTaskMapContourModel extends Model {
  get overlap(): TaskMapIndexOverlapDto | undefined {
    return this._overlap;
  }

  get indexFile(): FieldIndexMonitoringModel | undefined {
    return this._indexFile;
  }

  get taskMap(): FieldTaskMapModel | undefined {
    return this._taskMap;
  }

  private _taskMap: FieldTaskMapModel | undefined;

  private _indexFile: FieldIndexMonitoringModel | undefined;

  private _overlap: TaskMapIndexOverlapDto | undefined;

  public showLabels = false;

  private async fetchOverlap() {
    if (this._taskMap && this._indexFile) {
      await (this._taskMap as FieldTaskMapBaseModel | FieldTaskMapWorkModel).fetchData();
      const taskType = this._taskMap instanceof FieldTaskMapBaseModel ? 'base' : 'work';
      const { data } = await ApiService.taskMap.getOverlap(this._taskMap?.id, taskType, this._indexFile.id);
      this._overlap = data;
    } else {
      this._overlap = undefined;
    }
  }

  private formatProperties(properties: GeoJsonProperties, id: number, overlap: TaskMapIndexOverlapDto | undefined) {
    const labels: string[] = [];

    if (overlap && overlap.index_label) {
      const fp = overlap.properties.find((prop) => prop.feature_id === id);
      if (fp) {
        labels.push(`${overlap.index_label ? `${overlap.index_label}: ` : ''}${Math.round(fp.avg * 100) / 100}`);
      }
    }

    /* if (useMonitoring().selectedWorkTask.value && useMonitoring().products.value) {
      const feature = useMonitoring().selectedWorkTask.value?.geojson?.features.find((f) => f.properties?.id === id);
      if (feature) {
        useMonitoring().products.value.forEach((prod) => {
          if (prod.show) {
            const pf = feature.properties?.prod.find((v: any) => v.name === prod.name);
            if (pf) {
              labels.push(this.showLabels ? `${pf.name}: ${pf.rate}` : `${pf.rate}`);
            }
          }
        });
      }
    } */

    return {
      label: labels.join('\n'),
      zone: properties?.zone.toString(),
      color: properties?.color,
      id,
    } as GeoJsonProperties;
  }

  async setTaskMap(value: FieldTaskMapModel | undefined): Promise<void> {
    this._taskMap = value;
    await this.fetchOverlap();
  }

  async setIndexes(value: FieldIndexMonitoringModel | undefined): Promise<void> {
    this._indexFile = value;
    await this.fetchOverlap();
  }

  featuresCollection(): FeatureCollection {
    if (this._taskMap?.geojson) {
      return {
        type: this._taskMap.geojson?.type,
        bbox: this._taskMap.geojson?.bbox,
        features: this._taskMap.geojson?.features.map((feature) => ({
          type: 'Feature',
          geometry: feature.geometry,
          id: Number(feature.properties?.id || 0),
          properties: this.formatProperties(feature.properties, Number(feature.properties?.id || 0), this._overlap),
        })),
      } as FeatureCollection;
    }
    return {
      type: 'FeatureCollection',
      features: [],
    } as FeatureCollection;
  }
}
