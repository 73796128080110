import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { TaskCollection } from '@/collection/TaskCollection';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

export class MapLayerTaskMapCollectionModel extends MapLayerModel implements IMapLayerModel {
  readonly data: TaskCollection;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'task-map-collection', input.uuid);
    this.data = input as TaskCollection;
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
    this.createSourceLayer();
  }

  createSourceLayer = async () => {
    let wait = 0;
    this.data.collection.forEach((task) => {
      if (task.geojson === undefined) {
        wait++;
        task.fetchData().finally(() => wait--);
      }
    });

    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (wait === 0) {
          clearInterval(interval);
          resolve(true);
        }
      }, 100);
    });

    if (this.data.collection) {
      this._mapModel?.map?.addSource(this.sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: (this.data.collection.flatMap(((task) => task.geojson?.features ?? [])) as Feature<Geometry, GeoJsonProperties>[]),
        },
      });
      this._mapModel?.map?.addLayer({
        id: this.layerId,
        type: 'fill',
        source: this.sourceId,
        layout: {},
        metadata: { type: 'task-map-collection' },
        paint: {
          'fill-color':
            ['case',
              ['==', ['get', 'zone'], '1.1'], '#BE0000',
              ['==', ['get', 'zone'], '1.2'], '#E51F02',
              ['==', ['get', 'zone'], '1.3'], '#FD5502',
              ['==', ['get', 'zone'], '2.1'], '#FCE502',
              ['==', ['get', 'zone'], '2.2'], '#FBFC02',
              ['==', ['get', 'zone'], '2.3'], '#E2FB03',
              ['==', ['get', 'zone'], '3.1'], '#65B424',
              ['==', ['get', 'zone'], '3.2'], '#2A7F01',
              ['==', ['get', 'zone'], '3.3'], '#236400',
              ['==', ['get', 'zone'], 1.1], '#BE0000',
              ['==', ['get', 'zone'], 1.2], '#E51F02',
              ['==', ['get', 'zone'], 1.3], '#FD5502',
              ['==', ['get', 'zone'], 2.1], '#FCE502',
              ['==', ['get', 'zone'], 2.2], '#FBFC02',
              ['==', ['get', 'zone'], 2.3], '#E2FB03',
              ['==', ['get', 'zone'], 3.1], '#65B424',
              ['==', ['get', 'zone'], 3.2], '#2A7F01',
              ['==', ['get', 'zone'], 3.3], '#236400',
              ['get', 'color'],
            ],
          'fill-opacity': 1,
        },
      });
      this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.TASK_MAP);
    }
  }
}
