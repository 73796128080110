import { useMapContainers } from '@/composables/useMapContainers';
import tiffPacker from '@/lib/tiff/tiffPacker';
import { TaskCandidateModel } from '@/models/taskMap/TaskCandidateModel';
import { TaskCandidatesList } from '@/models/taskMap/TaskCandidatesList';
import FieldsList from '@/modules/fields/FieldsList';
import ApiService from '@/services/api/ApiService';
import { CreateBaseMapDto } from '@/services/api/dto/taskMap/CreateBaseMapDto';
import { computed, ref } from 'vue';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';

const candidates = ref<TaskCandidatesList>(new TaskCandidatesList());

const activeCandidate = ref<TaskCandidateModel>();

const createdCandidatesList = ref<number[]>([]);

const canvasPreview = ref<HTMLCanvasElement>();
const canvasPreviewCtx = ref<CanvasRenderingContext2D | null>(null);

const canvasEditor = ref<HTMLCanvasElement>();
const canvasEditorCtx = ref<CanvasRenderingContext2D | null>(null);

const canvasSnap = ref<HTMLCanvasElement>();
const canvasSnapCtx = ref<CanvasRenderingContext2D | null>(null);

const canvasCursorSquare = ref<HTMLCanvasElement>();
const canvasCursorSquareCtx = ref<CanvasRenderingContext2D | null >(null);

const generalizationSize = ref(20);

const _activeMode = ref<Record<number, 'selector' | 'form' | 'edit'>>({});

const activeGrid = ref(false);

const previewLayer = ref<MapLayerCanvasModel>();
const editorLayer = ref<MapLayerCanvasModel>();
const snapLayer = ref<MapLayerCanvasModel>();
const cursorLayer = ref<MapLayerCanvasModel>();

export const useCreateBaseMapModal = () => {
  /** Сформировать и отправить запрос на создание шаблона карты */
  const postBaseMap = () => {
    if (activeCandidate.value?.taskImage) {
      const image = activeCandidate.value?.taskImage;
      const data: CreateBaseMapDto = {
        bbox: image.bbox,
        candidate: activeCandidate.value?.id,
        field: activeCandidate.value?.fieldId,
        zones: image.formattedZones,
        name: activeCandidate.value?.inputName,
        payload: tiffPacker(Array.from(image.imageDataZoned)),
        height: image.scaledHeight,
        width: image.scaledWidth,
        folder: 0,
      };
      image.alreadyCreated = true;

      if (activeCandidate.value) {
        activeCandidate.value.isCreated = true;
        BaseMapEvents.emitCreateTaskMap(activeCandidate.value.fieldId);
      }
      ApiService.taskMap.createBaseMap(activeCandidate.value?.farmunit, data).then(() => {
        FieldsList.getFieldModel(activeCandidate.value?.fieldId)?.fetchTaskMaps(true);
      });
    }
  };

  const activeMode = computed(() => _activeMode.value[useMapContainers().activeField.value?.id || 0] || 'selector');

  const setActiveMode = (value: 'selector' | 'form' | 'edit') => {
    _activeMode.value[useMapContainers().activeField.value?.id || 0] = value;
    BaseMapEvents.emitCandidateModeChange();
  };

  const clearState = () => {
    candidates.value.clearState();
    createdCandidatesList.value = [];
    _activeMode.value = {};
    activeCandidate.value = undefined;
    generalizationSize.value = 20;
  };

  return {
    candidates,
    activeCandidate,
    createdCandidatesList,
    postBaseMap,
    canvasPreview,
    canvasPreviewCtx,
    canvasEditor,
    canvasEditorCtx,
    canvasSnap,
    canvasSnapCtx,
    canvasCursorSquare,
    canvasCursorSquareCtx,
    generalizationSize,
    clearState,
    activeMode,
    setActiveMode,
    activeGrid,
    previewLayer,
    editorLayer,
    snapLayer,
    cursorLayer,
  };
};
