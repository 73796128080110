import {
  Map,
} from 'mapbox-gl';
import { computed, ref } from 'vue';

const _map = ref<Map>();

export const useCreateBaseMapPreview = () => {
  const map = computed({
    get: () => _map.value,
    set: (v) => {
      _map.value = v;
    },
  });

  return {
    map,
  };
};
