import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import Coordinates from '@/constants/types/mapbox/Coordinates';
import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { ImageSource } from 'mapbox-gl';

export class MapLayerIndexModel extends MapLayerModel implements IMapLayerModel {
  get data(): FieldIndexMonitoringModel | FieldAverageIndexModel {
    return this._data;
  }

  private _data: FieldIndexMonitoringModel | FieldAverageIndexModel;

  private _contrast;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'index', input.uuid);
    this._data = input as (FieldIndexMonitoringModel | FieldAverageIndexModel);
    this._contrast = this._data.showContrast || false;
    this.createSourceIndexFile();
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  updateImage = (file: FieldIndexMonitoringModel | FieldAverageIndexModel): void => {
    const source = this._mapModel?.map?.getSource(this.sourceId) as ImageSource;
    source.updateImage({
      url: file.showContrast ? file.imgContrastFile || '' : file.imgColoredFile || '',
      coordinates: file.geomCoordinates,
    });
  }

  setContrast = (value: boolean) => {
    if (value !== this._contrast) {
      const source = this._mapModel?.map?.getSource(this.sourceId) as ImageSource;
      if (value) {
        source.updateImage({
          url: this._data.imgContrastFile || '',
          coordinates: this._data.geomCoordinates,
        });
      } else {
        source.updateImage({
          url: this._data.imgColoredFile || '',
          coordinates: this._data.geomCoordinates,
        });
      }
      this._contrast = value;
    }
  }

  createSourceIndexFile = () => {
    if (!this._mapModel?.map?.getSource(this.sourceId)) {
      this._mapModel?.map?.addSource(this.sourceId, {
        type: 'image',
        url: this._contrast ? this._data.imgContrastFile : this._data.imgColoredFile,
        coordinates: this._data.geomCoordinates as Coordinates,
      });
      this._mapModel?.map?.addLayer({
        id: this.layerId,
        source: this.sourceId,
        type: 'raster',
      });
      this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.INDEX_FILE);
    }
  }
}
