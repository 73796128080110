import { TaskIndexDto } from '@/services/api/dto/taskMap/TaskIndexDto';
import tiffUnpacker from '@/lib/tiff/tiffUnpacker';
import tiffResize from '@/lib/tiff/tiffResize';
import { ASFSourceZones } from '@/assets/data/ASFSourceZones';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { ASFSourceZoneType } from '@/constants/types/ASFSourceZoneType';
import { TaskImageZoneType } from '@/constants/types/TaskImageZoneType';
import BaseMapEvents from '@/pages/task-map/create/base-map/BaseMapEvents';

const IMAGE_SCALE_SIZE = 10;

export class TaskImageModel {
  get sourceZones(): ASFSourceZoneType[] {
    return this._sourceZones;
  }

  get alreadyCreated(): boolean {
    return this._alreadyCreated;
  }

  set alreadyCreated(value: boolean) {
    this._alreadyCreated = value;
  }

  set imageDataZoned(value: Uint8ClampedArray) {
    this._imageDataZoned = value;
  }

  get imageDataZoned(): Uint8ClampedArray {
    return this._imageDataZoned;
  }

  get scaledData(): Uint8ClampedArray {
    return this._scaledData;
  }

  set scaledData(value: Uint8ClampedArray) {
    this._scaledData = value;
  }

  get bbox(): [number, number, number, number] {
    return this._bbox;
  }

  get bounds(): LngLatBounds {
    return this._bounds;
  }

  get zones(): TaskImageZoneType[] {
    return this._zones;
  }

  set zones(value: TaskImageZoneType[]) {
    this._zones = value;
    this.computeImageDataZoned();
  }

  get sourceData(): Uint8ClampedArray {
    return this._sourceData;
  }

  get sourceHeight(): number {
    return this._sourceHeight;
  }

  get sourceWidth(): number {
    return this._sourceWidth;
  }

  get scaledWidth(): number {
    return this._sourceWidth * IMAGE_SCALE_SIZE;
  }

  get scaledHeight(): number {
    return this._sourceHeight * IMAGE_SCALE_SIZE;
  }

  // eslint-disable-next-line camelcase
  get formattedZones(): { value: number, zone: string, proc: number, proc_seed: number}[] {
    return this._zones.map((z) => ({
      value: z.indexColor,
      zone: (this._sourceZones.find((tz) => tz.value === z.indexColor) || { zone: '0' }).zone,
      proc: z.proc,
      proc_seed: z.procSeed,
    }));
  }

  private readonly _bbox: [number, number, number, number] = [0, 0, 0, 0];

  private readonly _bounds: LngLatBounds;

  private readonly _sourceWidth: number = 0;

  private readonly _sourceHeight: number = 0;

  private _scaledData: Uint8ClampedArray = new Uint8ClampedArray();

  private readonly _sourceData: Uint8ClampedArray = new Uint8ClampedArray();

  private _imageDataZoned: Uint8ClampedArray = new Uint8ClampedArray();

  private _alreadyCreated = false;

  private _zones: TaskImageZoneType[] = [
    {
      value: 3, min: 1, indexColor: 1, proc: 90, procSeed: 90,
    },
    {
      value: 6, min: 4, indexColor: 5, proc: 100, procSeed: 100,
    },
    {
      value: 9, min: 7, indexColor: 9, proc: 110, procSeed: 100,
    },
  ];

  private _sourceZones: ASFSourceZoneType[] = [];

  constructor(dto: TaskIndexDto) {
    this._sourceZones = JSON.parse(JSON.stringify(ASFSourceZones));

    this._bbox = dto.bbox;
    this._sourceData = new Uint8ClampedArray(tiffUnpacker(dto.payload));
    this._sourceWidth = dto.width;
    this._sourceHeight = dto.height;
    this._scaledData = tiffResize(this._sourceData, this._sourceWidth, IMAGE_SCALE_SIZE);
    this.computeImageDataZoned();

    const sw = new LngLat(this.bbox[0], this.bbox[3]);
    const ne = new LngLat(this.bbox[2], this.bbox[1]);
    this._bounds = new LngLatBounds(sw, ne);
  }

  public getZoneByValue(value: number) {
    return this._zones.find((z) => z.min <= value && z.value >= value);
  }

  private modifyDataByZones(imageData: Uint8ClampedArray) {
    const data = Array.from(imageData);
    const sz = new Array(10).fill(0);
    data.forEach((v, i) => {
      if (v) {
        if (!sz[v]) {
          const zone = this.getZoneByValue(v);
          if (zone) {
            sz[v] = zone.indexColor;
          }
        }
        data[i] = sz[v];
      }
    });
    return data;
  }

  public computeImageDataZoned(): void {
    const data = this.modifyDataByZones(this._scaledData);
    this._imageDataZoned = new Uint8ClampedArray(data);
  }

  private _generalization(data: Uint8ClampedArray, w: number, generalizationSize = 20): Uint8ClampedArray {
    type FigureType = {
      zone: number,
      positions: number[],
      adjoin: Map<number, number[]>,
    }

    const leftPixel = (pos: number) => (pos % w === 0 ? undefined : pos - 1);
    const topPixel = (pos: number) => (pos < w ? undefined : pos - w);
    const rightPixel = (pos: number) => ((pos + 1) % w === 0 ? undefined : pos + 1);
    const bottomPixel = (pos: number) => (pos > data.length - w ? undefined : pos + w);

    const figures: FigureType[] = [];
    const isChecked = (pos: number) => figures.some((f) => f.positions.some((p) => p === pos));

    const stack: Set<number> = new Set();

    const getNearMatches = (pos: number, figure: FigureType) => {
      [leftPixel(pos), topPixel(pos), rightPixel(pos), bottomPixel(pos)].forEach((_pos) => {
        if (_pos !== undefined && !isChecked(_pos) && !figure.positions.some((p) => p === _pos)) {
          const z = data[_pos];
          if (z === figure.zone) {
            figure.positions.push(_pos);
            stack.add(_pos);
          }
        }
        if (_pos !== undefined && data[_pos] !== 0 && data[_pos] !== figure.zone) {
          const z = data[_pos];
          const values = figure.adjoin.get(z) || [];
          if (!values.includes(_pos)) {
            values.push(_pos);
          }
          figure.adjoin.set(z, values);
        }
      });
    };

    for (let i = 0; i < data.length; i++) {
      if (data[i] && !isChecked(i)) {
        const figure: FigureType = {
          zone: data[i],
          positions: [i],
          adjoin: new Map<number, number[]>(),
        };
        stack.clear();
        stack.add(i);

        do {
          Array.from(stack.values()).forEach((pos) => {
            getNearMatches(pos, figure);
            stack.delete(pos);
          });
        } while (stack.size > 0);

        figures.push(figure);
      }
    }

    figures.forEach((figure: FigureType) => {
      if (figure.positions.length <= generalizationSize && figure.adjoin.size > 0) {
        let zone = 0;
        let count = 0;
        figure.adjoin.forEach((c, z) => {
          let adjCount = c.length;

          c.forEach((p) => {
            const adjFigure = figures.find((f) => f.positions.includes(p));
            if ((adjFigure?.positions || []).length <= generalizationSize) {
              adjCount -= 1;
            }
          });

          if (adjCount > count) {
            zone = z;
            count = c.length;
          }
        });
        if (zone) {
          figure.positions.forEach((pos) => {
            data[pos] = zone;
          });
        }
      }
    });
    return data;
  }

  /**
   * Удаляет мелкие фигуры с поля указанного размера
   * @param generalizationSize - размеры фигур для удаления в сотках
   */
  public generalization(generalizationSize = 20): void {
    const sourceData = new Uint8ClampedArray(this.modifyDataByZones(this._sourceData));
    const dataStep1 = this._generalization(sourceData, this._sourceWidth, generalizationSize);
    const dataStep2 = this._generalization(dataStep1, this._sourceWidth, generalizationSize);
    const dataStep3 = this._generalization(dataStep2, this._sourceWidth, generalizationSize);

    this._imageDataZoned = tiffResize(new Uint8ClampedArray(dataStep3), this._sourceWidth, IMAGE_SCALE_SIZE);
    BaseMapEvents.emitZoneChanged();
  }

  public pasteZones(data: string | null) {
    if (data) {
      this._zones = [];
      JSON.parse(data).forEach((row: TaskImageZoneType) => {
        this._zones.push({
          value: row.value,
          min: row.min,
          indexColor: row.indexColor,
          proc: row.proc,
          procSeed: row.procSeed,
        });
      });
      this.computeImageDataZoned();
    }
  }

  public pasteSourceZones(data: string | null) {
    if (data) {
      JSON.parse(data).forEach((row: ASFSourceZoneType) => {
        const sz = this._sourceZones.find((v) => v.value === row.value);
        if (sz) {
          sz.proc = row.proc;
          sz.proc_seed = row.proc_seed;
        }
      });
    }
  }
}
