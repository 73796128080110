<template src="./NavigationStructUnits.pug" lang="pug"/>
<style src="./NavigationStructUnits.scss" lang="scss"/>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useBilling } from '@/composables/useBilling';
import StructList from '@/modules/struct/StructList';
import { StructModel } from '@/models/struct/StructModel';
import { useTolgee } from '@tolgee/vue';

export default defineComponent({
  name: 'NavigationStructUnits',

  setup() {
    const { contract } = useBilling();

    const searchStruct = ref('');

    const computedStruct = computed(() => StructList.structs.value.filter((s) => s.name.toLowerCase().includes(searchStruct.value.toLowerCase())));

    const selectUnit = async (unit: StructModel) => {
      if (unit.isSelectable) {
        window.location.href = `/app/struct/${unit.id}`;
      }
    };

    const title = computed(() => {
      if (StructList.activeStruct.value) {
        return `<span>${StructList.structPath(StructList.activeStruct.value).join('</span><span>')}</span>`;
      }
      return useTolgee().value?.t('struct-is-not-selected', { ns: 'navigation' });
    });

    return {
      computedStruct,
      searchStruct,
      StructList,
      selectUnit,
      title,
      contract,
    };
  },
});
</script>
