import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { ImageFileType } from '@/constants/types/ImageFileType';
import { fetcher } from '@/lib/tools/fetcher';
import { RasterModel } from '@/models/RasterModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import ApiService from '@/services/api/ApiService';
import {
  reactive, Ref, ref, UnwrapRef,
} from 'vue';

class StructFilesList {
  get groups(): Ref<UnwrapRef<ImageFileType[]>> {
    return this._groups;
  }

  get vectors(): Array<UnwrapRef<UnifiedVectorModel>> {
    return this._vectors;
  }

  get rasters(): Array<UnwrapRef<RasterModel>> {
    return this._rasters;
  }

  private _vectors = reactive<UnifiedVectorModel[]>([]);

  private _rasters = reactive<RasterModel[]>([]);

  private _groups = ref<ImageFileType[]>([])

  async fetchGroups() {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return fetcher(LoadingNamesEnum.STRUCT_FILES_GROUPS, unit.toString(), false, async () => {
      const { data } = await ApiService.gis.getFilesGroups();
      this._groups.value = data;
    });
  }

  fetchVectors(force = false) {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return fetcher(LoadingNamesEnum.STRUCT_FILES_VECTORS, unit.toString(), force, async () => {
      const { data } = await ApiService.gis.getUnifiedVectors();
      this._vectors = data.map((v) => new UnifiedVectorModel(v));
    });
  }

  fetchRasters(force = false) {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return fetcher(LoadingNamesEnum.STRUCT_FILES_RASTERS, unit.toString(), force, async () => {
      const { data } = await ApiService.gis.getRasters();
      this._rasters = data.map((v) => new RasterModel(v));
    });
  }
}

export default new StructFilesList();
