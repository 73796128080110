import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { ChartDataDto } from '@/services/api/dto/plot/ChartDataDto';

export const endpoints = {
  chartData: (farmunitId: string | 0, fieldId: number): string => `/api/v1/plot/${farmunitId}/date-plot/${fieldId}/`,
};

export class ApiPlot extends ApiHandler {
  public async fetchData(id: number): Promise<ApiResponse<ChartDataDto[]>> {
    const structId = window.localStorage.getItem('structId') || 0;
    return await
    this.request({ auth: true }).get<ChartDataDto[]>(endpoints.chartData(structId, id));
  }
}
