<template src="./NoActiveField.pug" lang="pug"/>
<style src="./NoActiveField.scss" lang="scss"/>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({

  props: {
    message: {
      type: String,
      default: 'Для продолжения, выберите поле в списке контуров полей или на карте.',
    },
  },
  name: 'NoActiveField',
});
</script>
