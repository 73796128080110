import type { MapModel } from '@/models/map/MapModel';
import type { MapLayerPoiModel } from '@/models/map/Layers/MapLayerPoiModel';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { nextTick } from 'vue';
import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import PoiEvents from '@/modules/poi/PoiEvents';

export class MapPoiEvents {
  constructor(layer: MapLayerPoiModel, mapModel: MapModel) {
    EventBus.$on(EventsEnum.PoiToggleActivePoi, () => {
      layer.data.collection.forEach((poi) => {
        if (mapModel.map?.getSource(layer.sourceId)) {
            mapModel.map?.setFeatureState(
              { source: layer.sourceId, id: poi.id },
              { active: poi.isActive },
            );
        }
      });
    });

    function onPOIMove(e: MapMouseEvent | MapTouchEvent) {
      const coords = e.lngLat;
      const poi = layer.data.collection.find((p) => p.moveMode);
      if (poi) {
        poi.move([coords.lng, coords.lat]);
        layer.redraw([...layer.data.collection]);
      }
    }

    function onPOIUp() {
      mapModel.map?.off('mousemove', onPOIMove);
      mapModel.map?.off('touchmove', onPOIMove);
    }

    mapModel.map?.on('mousedown', layer.layerId, (e: MapMouseEvent) => {
      e.preventDefault();
        mapModel.map?.on('mousemove', onPOIMove);
        mapModel.map?.once('mouseup', onPOIUp);
    });

    mapModel.events.onClick((e, options) => {
      if (options?.layerType === 'poi') {
        const features = mapModel.map?.queryRenderedFeatures(e.point);

        const poiFeature = features?.find((f) => f.layer?.id === layer.layerId);

        if (poiFeature?.id) {
          nextTick(() => {
            PoiEvents.emitClick(poiFeature, mapModel.container);
          });
        }
      }
    });

    PoiEvents.onUpdatePoiLabel(() => {
      layer.calculateLabelPoi();
    });

    mapModel.map?.on('mouseover', layer.layerId, () => {
      if (mapModel.map) {
        mapModel.map.getCanvas().style.cursor = 'pointer';
      }
    });
    mapModel.map?.on('mouseleave', layer.layerId, () => {
      if (mapModel.map) {
        mapModel.map.getCanvas().style.cursor = '';
      }
    });
  }
}
