import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { isNumber } from '@/utils/isNumber';
import { pythonRound } from '@/utils/pythonRound';
import TiffReader from '@/utils/TiffReader';

class CanvasNDVIImgService {
  public zoneCount = [0, 0, 0, 0];

  public showCanvas = false

  public payload: number[] = []

  public canvasModel: MapCanvasModel

  constructor(canvasModel: MapCanvasModel) {
    this.canvasModel = canvasModel;
    if (TiffReader.data.value && this.canvasModel.canvas) {
      this.canvasModel.canvas.width = TiffReader.data.value.width;
      this.canvasModel.canvas.height = TiffReader.data.value.height;
    }
  }

  setShowCanvas = (value: boolean) => {
    this.showCanvas = value;
  }

  processMatrix = (arr: number[], w: number) => {
    const newArray = [...arr];
    let rn = 0;
    for (let i = 0; i < arr.length; i++) {
      if (!isNumber(i) || !isNumber(arr[i])) {
        continue;
      }
      const squareArr = [
        (i - w) - 1, (i - w), (i - w) + 1,
        (i) - 1, i, (i) + 1,
        (i + w) - 1, (i + w), (i + w) + 1,
      ].filter((v) => isNumber(v) && isNumber(arr[v]));

      if (!Number.isNaN(arr[i])) {
        const ratio = squareArr.reduce((acc, v) => {
          acc += pythonRound(arr[v] < 0 || Object.is(arr[v], -0) ? 0 : arr[v], 2);
          return acc;
        }, 0) / squareArr.length;
        if (!Number.isNaN(ratio)) {
          newArray[i] = pythonRound(ratio, 2);
        } else {
          rn++;
        }
      }
    }
    return newArray;
  }

  getViewArray(srcArr: number[][], i: number, j: number, boxsize: number, width: number, height: number) {
    const viewArr: number[] = [];
    for (let ii = i - boxsize; ii < i + boxsize + 1; ii++) {
      if ((ii < 0) || (ii >= height)) {
        continue;
      }
      for (let jj = j - boxsize; jj < j + boxsize + 1; jj++) {
        if ((jj < 0) || (jj >= width)) {
          continue;
        }
        if (!isNumber(srcArr[ii][jj])) {
          continue;
        }
        viewArr.push(srcArr[ii][jj]);
      }
    }
    return viewArr;
  }

  nanMean(viewArr: number[]) {
    if (viewArr.length < 1) {
      return 0;
    }
    let sum = 0;
    for (let i = 0; i < viewArr.length; i++) {
      if (isNumber(viewArr[i])) {
        sum += viewArr[i];
      }
    }
    return sum / viewArr.length;
  }

  SlidingWindowAlgorithm(srcArr: number[][], height: number, width: number, boxsize = 1) {
    const resultArr: number[][] = JSON.parse(JSON.stringify(srcArr));
    for (let i = 0; i < height; i++) {
      for (let j = 0; j < width; j++) {
        if (!isNumber(srcArr[i][j])) {
          continue;
        }
        const viewArr = this.getViewArray(srcArr, i, j, boxsize, width, height);
        resultArr[i][j] = Math.round(this.nanMean(viewArr) * 100) / 100;
      }
    }
    return resultArr;
  }

  make2DArray(arr: number[], height: number, width: number) {
    const newArr: number[][] = [];
    for (let i = 0; i < height; i++) {
      newArr.push(arr.slice(i * width, (i + 1) * width));
    }
    return newArr;
  }

  make1DArray(arr: number[][]) {
    return arr.flat(1);
  }

  NDVItoMatrix = () => {
    // прогоняем в новом массиве, округление 2 знака, прогоняем 2 раза
    // @ts-ignore
    const tiffWidth = TiffReader.data.value.width;
    // @ts-ignore
    const tiffHeight = TiffReader.data.value.height;
    // @ts-ignore
    const data: number[][] = this.make2DArray([...TiffReader.data.value[0]], tiffHeight, tiffWidth);

    const step1 = this.SlidingWindowAlgorithm(data, tiffHeight, tiffWidth);
    const step2 = this.SlidingWindowAlgorithm(step1, tiffHeight, tiffWidth);
    // const step3 = this.SlidingWindowAlgorithm(step2, tiffHeight, tiffWidth);
    return this.make1DArray(step2);
  }

  PaintMatrixNDVI = (border: number[]) => {
    if (TiffReader.data.value) {
      const data = this.NDVItoMatrix();
      this.zoneCount = [0, 0, 0, 0];
      this.payload = [];

      const img = new ImageData(TiffReader.data.value.width, TiffReader.data.value.height);
      const setColor = (i: number, r: number, g: number, b: number, a: number) => {
        img.data[(i * 4)] = r;
        img.data[(i * 4) + 1] = g;
        img.data[(i * 4) + 2] = b;
        img.data[(i * 4) + 3] = this.showCanvas ? a : 0;
      };
      let cn = 0;
      for (let i = 0; i < data.length; i++) {
        setColor(i, 0, 0, 0, 255);
        if (!isNumber(data[i])) {
          setColor(i, 0, 0, 0, 0);
          cn++;
          this.payload.push(0);
        } else if (border.length === 2) {
          if (data[i] < border[0] * 0.01) {
            setColor(i, 165, 0, 0, 255);
            this.zoneCount[0] += 1;
            this.payload.push(1);
          } else if (data[i] <= border[1] * 0.01) {
            setColor(i, 240, 247, 0, 255);
            this.zoneCount[1] += 1;
            this.payload.push(2);
          } else if (data[i] > border[1] * 0.01) {
            setColor(i, 96, 165, 0, 255);
            this.zoneCount[2] += 1;
            this.payload.push(3);
          }
        } else if (border.length === 3) {
          if (data[i] < border[0] * 0.01) {
            setColor(i, 165, 0, 0, 255);
            this.zoneCount[0] += 1;
            this.payload.push(1);
          } else if (data[i] < border[1] * 0.01) {
            setColor(i, 232, 165, 0, 255);
            this.zoneCount[1] += 1;
            this.payload.push(2);
          } else if (data[i] <= border[2] * 0.01) {
            setColor(i, 240, 247, 0, 255);
            this.zoneCount[2] += 1;
            this.payload.push(3);
          } else if (data[i] > border[2] * 0.01) {
            setColor(i, 96, 165, 0, 255);
            this.zoneCount[3] += 1;
            this.payload.push(4);
          }
        }
      }
      this.canvasModel.ctx?.putImageData(img, 0, 0);
    }
  }
}

export default CanvasNDVIImgService;
