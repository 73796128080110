<template lang='pug' src='./ImagesPanel.pug'/>
<style lang='scss' src='./ImagesPanel.scss'/>

<script lang="ts">
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { ImageFileType } from '@/constants/types/ImageFileType';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import ImagesContainer
  from '@/modules/map/container/SidePanel/ImagesPanel/ImagesContainer/ImagesContainer.vue';
import ImagesTree from '@/modules/map/container/SidePanel/ImagesPanel/ImagesTree.vue';
import StructFilesList from '@/modules/struct/StructFilesList';
import ApiService from '@/services/api/ApiService';
import { Brush } from '@element-plus/icons-vue';
import {
  computed,
  defineComponent, PropType, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'ImagesPanel',
  components: {
    UiDialog,
    ImagesContainer,
    SidePanelContent,
    ImagesTree,
    UiIcon,
  },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const showCreateFolder = ref(false);

    const showImagesContainer = ref(false);

    const createFolderForm = ref({
      name: '',
      parent: 0,
    });

    watch(showCreateFolder, (a) => {
      if (!a) {
        createFolderForm.value = {
          name: '',
          parent: 0,
        };
      }
    });

    const createFolder = () => {
      if (createFolderForm.value.name.length >= 2) {
        ApiService.gis
          .createFolder({
            label: createFolderForm.value.name,
            target: {
              obj: 0,
              type: 'group',
            },
            position: {
              item: {
                obj: createFolderForm.value.parent,
                type: 'group',
              },
              drop_type: 'inner',
            },
          })
          .then(() => {
            StructFilesList.fetchGroups();
            showCreateFolder.value = false;
          });
      }
    };

    const dragging = ref<UnifiedVectorModel | undefined>();

    const dragStart = (event: MouseEvent, item: UnifiedVectorModel) => {
      dragging.value = item;
      event.preventDefault();
    };

    const onItemOver = (item: UnifiedVectorModel) => {
      if (dragging.value && dragging.value.id !== item.id) {
        const dragOrder = dragging.value.order;
        dragging.value.order = item.order;
        item.order = dragOrder;
      }
    };

    window.addEventListener('mouseup', () => {
      dragging.value = undefined;
    });

    const groupsTree = computed(() => {
      const mapGroups = (node: ImageFileType) => ({
        label: node.label,
        value: node.id,
        children: node.children?.filter((v) => v.type === 'group').map((v) => mapGroups(v)),
      });
      return [
        { label: 'Корневая группа', value: 0 },
        ...StructFilesList.groups.value.filter((v) => v.type === 'group').map((v) => mapGroups(v)),
      ];
    });

    return {
      showCreateFolder,
      showImagesContainer,
      createFolderForm,
      createFolder,
      StructFilesList,
      dragStart,
      dragging,
      onItemOver,
      Brush,
      groupsTree,
    };
  },
});
</script>
