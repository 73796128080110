import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ImagesContainer-file" }
const _hoisted_2 = { class: "ImagesContainer-file-icon" }
const _hoisted_3 = { class: "ImagesContainer-file-info" }
const _hoisted_4 = { class: "ImagesContainer-file-label" }
const _hoisted_5 = { class: "ImagesContainer-file-date" }
const _hoisted_6 = { class: "ImagesContainer-file-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_icon, {
        name: "mdiFileImageOutline",
        color: "#C66300",
        size: 32
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.file.alias), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatRuDate(_ctx.file.date)), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.file.scope === 'user' || (_ctx.file.scope === 'expert' && _ctx.isStaff))
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            content: _ctx.$t('delete-file', { ns: 'side-panel' }),
            "show-after": 500
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                circle: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete'))),
                type: "danger"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ui_icon, { name: "mdiDeleteOutline" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true),
      (_ctx.file.scope === 'user' || (_ctx.file.scope === 'expert' && _ctx.isStaff))
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            content: _ctx.$t('download-file', { ns: 'side-panel' }),
            "show-after": 500
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                circle: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('download'))),
                type: "info"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ui_icon, { name: "mdiDownloadCircleOutline" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('add-file-to-layers-list', { ns: 'side-panel' }),
        "show-after": 500
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            circle: "",
            onClick: _ctx.emitAddLayer,
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ui_icon, { name: "mdiLayersPlus" })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["content"])
    ])
  ]))
}