import ApiService from '@/services/api/ApiService';
import { useStatus } from '@/composables/useStatus';
import { useApp } from '@/composables/useApp';

class BackendService {
  private serverTimeInterval: ReturnType<typeof setInterval> | undefined;

  private serverDown = false;

  constructor() {
    setInterval(() => {
      this.pingPong();
    }, 10000);
  }

  pingPong() {
    if (this.serverTimeInterval) {
      clearInterval(this.serverTimeInterval);
    }
    ApiService.etc.systemStatus()
      .then((response) => {
        useStatus().serverTime.value = new Date(response.data.server_time);
        useStatus().serverVersion.value = response.data.version;
      }).catch((e) => {
        if (!this.serverDown) {
          this.serverDown = true;
          useApp().setLoadingScreen('server-down', 'Сервер True Fields недоступен\n\nВозможные причины:\n1. Потеряно интернет соединение.\n2. Сервер отключен на тех. обслуживание.');
        }
      });

    this.serverTimeInterval = setInterval(() => {
      if (useStatus().serverTime.value) {
        const time = useStatus().serverTime.value?.getTime() || Date.now() + 1000;
        useStatus().serverTime.value = new Date(time);
      }
    }, 1000);
  }
}

export default new BackendService();
