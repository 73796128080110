import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "NavigationAccount" }
const _hoisted_2 = { class: "NavigationAccount_Button" }
const _hoisted_3 = {
  key: 0,
  class: "NavigationAccount_menu-item",
  onClick: console
}

export function render(_ctx, _cache) {
  const _component_el_popover = _resolveComponent("el-popover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, {
      width: "300px",
      "show-after": 300
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.user.email), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "NavigationAccount_menu-item",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.profile && _ctx.profile(...args)))
        }, "Профиль"),
        (_ctx.isDevMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Настройка консоли"))
          : _createCommentVNode("", true),
        (_ctx.isDevMode)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "NavigationAccount_menu-item",
              onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.apiKeys && _ctx.apiKeys(...args)))
            }, "API Keys"))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "NavigationAccount_menu-item",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.logout && _ctx.logout(...args)))
        }, "Завершить сеанс")
      ]),
      _: 1
    })
  ]))
}